import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllProvinces = 'api/provinces',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetAllProvincesRequestParams = {
  languageId: number
  regionId?: number | null
}

export type GetAllProvincesResponse = {
  provinces: {
    id: number
    keyword: string
    pageId: number
    published: boolean | null
    parentId: number | null
    pageTypeId: number | null
    translation: {
      name: string
    }[]
  }[]
}

enum GetAllProvincesResponseError {
}

export async function getAllProvinces(params: GetAllProvincesRequestParams, output?: RequestOutput<GetAllProvincesResponse, GetAllProvincesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProvinces,
    params: params,
    output: output,
  })
}
