import { makeStyles } from '@material-ui/core';
import { Field, FieldGroup, FieldTranslation } from 'app/entities/types';
import { isFieldGroup } from 'app/entities/methods';
import { fieldGroupIsOfType } from 'app/entities/methods';
import { sortBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { FieldInput, FieldGroupInputSelect } from './../';
import { FieldGroupTypeValue, FieldGroupTypeSelectOptionKeywords } from 'app/values';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  input: {
    width: '100%',
    height: '5rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '6px !important'
  },
}))


type FieldGroupInputProps = {
  fieldGroup: FieldGroup
  updateTranslationDataCallback: (data: FieldTranslation) => void
  checkSaveStatusCallback: (field: Field | null, hasError: boolean) => void
}
type FieldGroupChild = Field | FieldGroup

const FieldGroupInput = ({ fieldGroup, updateTranslationDataCallback, checkSaveStatusCallback }: FieldGroupInputProps) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  useEffect(() => {
    updateTranslationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateValue = (value: string, hasError: boolean) => {
    setValue(value)
    checkSaveStatusCallback(null, false)
  }

  const updateTranslationData = () => {
    function updateSelectTranslations(fields: FieldGroup[], value: boolean) {

      fields.map(fg => {
        let fieldsToPush = fg.fields.filter((f) => (f.keyword === FieldGroupTypeSelectOptionKeywords.Selected))
        fieldsToPush.map(s => {
          const data: FieldTranslation = {
            id: s.translation?.id ?? 0,
            value: value === true ? '1' : '0'
          }
          updateTranslationDataCallback(data)
        })
      })


    }
    //Push selected field.
    if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Select)) {
      const selections = fieldGroup.childs.filter((f) => (f.name === value))
      updateSelectTranslations(selections, true)

      const deselections = fieldGroup.childs.filter((f) => (f.name !== value))
      updateSelectTranslations(deselections, false)
    }
  }

  function handleInputBlur(event: any) {
    updateTranslationData()
  }

  const commonFieldProps = () => {
    return {
      className: classes.input,
      fieldGroup: fieldGroup,
      value: value,
      updateValueCallback: updateValue,
      onBlur: handleInputBlur
    }
  }

  const renderContent = () => {

    if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Select)) {
      return <FieldGroupInputSelect {...commonFieldProps()} />
    }

    return <>
      {
        sortChilds().map((child, i) => (
          renderChild(child, i)
        ))}
    </>

    function sortChilds() {
      const fields = fieldGroup.fields
      const fieldGroups = fieldGroup.childs

      if (fields == undefined && fieldGroups == undefined) return []
      if (fieldGroups == undefined) return fields
      if (fields == undefined) return fieldGroups

      let list: FieldGroupChild[] = [...fields, ...fieldGroups]
      list = sortBy(list, [function (i) { return i.itemOrder }])
      return list
    }

    function renderChild(item: FieldGroupChild, index: number) {
      if (isFieldGroup(item)) {
        const fieldGroup = item as unknown as FieldGroup
        if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Select)) return "AAAAA"
        return <FieldGroupInput key={index} fieldGroup={fieldGroup} updateTranslationDataCallback={updateTranslationDataCallback} checkSaveStatusCallback={checkSaveStatusCallback} />
      }
      else {
        const field = item as unknown as Field
        return <FieldInput showName key={index} field={field} updateTranslationDataCallback={updateTranslationDataCallback} checkSaveStatusCallback={checkSaveStatusCallback} />
      }
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

export default FieldGroupInput
