import { Color, FontWeight } from 'theme/style';

export default {
  root: {
    borderRadius: '1rem',
    fontWeight: FontWeight.Bold,
    justifyContent: 'center',
    maxWidth: '20rem',

    '&[class*="variantSuccess"]': {
      backgroundColor: Color.Success
    },
    '&[class*="variantWarning"]': {
      backgroundColor: Color.Warning
    },
    '&[class*="variantError"]': {
      backgroundColor: Color.Error
    },
    '&[class*="variantInfo"]': {
      backgroundColor: Color.Info
    },
  }
};
