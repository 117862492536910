import { Input, InputProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  useEffect(() => {
    updateValueCallback(value, hasError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    const value = target.value

    const newValue = parseInt(value)
    let formattedValue = newValue.toString()
    if (formattedValue === 'NaN') formattedValue = '0'

    updateValueCallback(formattedValue, hasError(formattedValue))
  }

  return (
    <Input disableUnderline className={clsx(className, classes.input)}
      value={value}
      type="number"
      placeholder="Inserisci un valore..."
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    />
  )
}

export default FieldInput
