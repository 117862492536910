import { Input, InputProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { RichTextField, RichTextFieldToolbarType } from 'components'

const useStyles = makeStyles((theme) => ({
  input: {
    minHeight: '300px',
    height: '300px'
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string
  name?: string
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, name, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  useEffect(() => {
    updateValueCallback(value, hasError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(value: string) {
    updateValueCallback(value, hasError(value))
  }

  return (
    <RichTextField className={clsx(classes.input)}
      placeholder="Inserisci un testo..."
      name={name ?? ''}
      value={value}
      onChangeCallback={handleInputChange}
      onBlurCallback={onBlur} />
  )
}

export default FieldInput
