import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, GridList, GridListTile, GridListTileBar, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Spinner } from 'components';
import clone from 'lodash/clone';
import { MediaLibraryFile } from 'app/entities/types';
import React, { useState, useEffect, useRef } from 'react';
import { Color } from 'theme/style';
import { MediaLibraryFolderValue } from 'app/values';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { config } from 'app/config'
import {
  uploadMediaLibraryFile, UploadMediaLibraryFileRequestParams,
  getMediaLibraryFolder, GetMediaLibraryFolderRequestParams, GetMediaLibraryFolderResponse
} from 'app/api';
import { toast, convertFileToBase64, getBaseName, getFileName } from 'app/utils';
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { getMediaLibraryFolderFromValue } from 'app/entities/methods'
import { getFormattedMediaLibraryFilename } from 'app/entities/methods'

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    width: 'calc(100% - 60px)',
    maxWidth: 'calc(100% - 60px)',
    height: 'calc(100% - 60px)',
    maxHeight: 'calc(100% - 60px)',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  fileContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  uploadMessage: {
    marginRight: theme.spacing(2),
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridItem: {
    border: '4px solid transparent',
    cursor: 'pointer'
  },
  gridItemSelected: {
    borderColor: Color.Primary
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  ctaCancel: {
    color: Color.TextSecondary
  },
  ctaSuccess: {
    backgroundColor: Color.Success,
    color: Color.White
  }
}))


interface FilePickerProps {
  open: boolean
  items?: MediaLibraryFile[]
  selectedItem?: MediaLibraryFile | null
  title?: string
  description?: string
  mediaLibraryFolder?: MediaLibraryFolderValue
  enableUpload?: boolean
  onClose: () => void
  onSelectCallback: (selectedItem: MediaLibraryFile | null) => void
  onConfirmCallback: (selectedItem: MediaLibraryFile | null) => void
}

const FilePicker = ({ open, items, selectedItem, title, description, mediaLibraryFolder, enableUpload, onClose, onSelectCallback, onConfirmCallback }: FilePickerProps) => {
  const classes = useStyles()
  const session = useSelector((state: RootState) => state.session)

  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)

  const [files, setFiles] = useState<MediaLibraryFile[] | null>(null)
  const [selection, setSelection] = useState<MediaLibraryFile | null>(null)

  const inputFile = useRef<HTMLInputElement>(null)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const [isUploading, setIsUploading] = useState(false)

  if (title == null) title = 'Seleziona un file'
  if (description == null) description = 'Seleziona un file dalla lista.'
  if (enableUpload == null) enableUpload = false

  useEffect(() => {
    if (open === false) return

    const initialItems = clone(items)
    setFileToUpload(null)

    if (initialItems == null) {
      fetchLibraryItems()
    }
    else {
      setFiles(validateItems(initialItems))
      setSelection(selectedItem ?? null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    const initialized = files != null
      && isFetching === false
    setIsLoading(!initialized)
  }, [open, files, isFetching])

  function fetchLibraryItems() {
    if (isFetching === true) return

    if (mediaLibraryFolder == null) {
      console.error("Media Library folder not specified: unable to fetch items.")
      setFiles([])
    }

    switch (mediaLibraryFolder) {
      case MediaLibraryFolderValue.Avatar: fetchAvatars(); break;
      default: fetchItems()
    }

    function fetchAvatars() {
      setIsFetching(true)
      let avatarsList: MediaLibraryFile[] = []

      let list = []
      const numberOfAvatars = 15
      for (let i = 1; i <= numberOfAvatars; i++) {
        const item = config.app.dir.images.avatars + 'avatar' + i + '.jpg';
        list.push(item)
      }

      list.forEach((a, i) => {
        const basename = getBaseName(a)
        const filename = getFileName(basename)
        const format = basename.substr(basename.lastIndexOf('.') + 1);
        const file = {
          id: i,
          name: filename,
          path: a,
          format: format
        }
        avatarsList.push(file)

        if (selectedItem?.path === a) {
          setSelection(selectedItem ?? null)
        }
      })

      setFiles(validateItems(avatarsList))
      setIsFetching(false)
    }

    function fetchItems() {
      const encode = (): GetMediaLibraryFolderRequestParams => {
        const folder = getMediaLibraryFolderFromValue(session, mediaLibraryFolder!)
        return {
          id: folder?.id ?? 0
        }
      }

      const decode = (data: GetMediaLibraryFolderResponse): MediaLibraryFile[] => {
        let object: MediaLibraryFile[] = []
        data.folder.files?.map((f, i) => {
          object.push({
            id: f.id,
            name: f.filename,
            format: f.type,
            path: f.basepath + '/' + f.filename,
          })
        })
        return object
      }

      getMediaLibraryFolder(encode(), {
        response(data) {
          const filesList = decode(data)
          setFiles(validateItems(filesList))
          setSelection(selectedItem ?? null)
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }

  function validateItems(list?: MediaLibraryFile[]): MediaLibraryFile[] {
    if (list == null) return []

    list.forEach(i => {
      validateItem(i)
    })

    return clone(list)

    function validateItem(item: MediaLibraryFile) {
      const filename = getFileName(item.name)
      item.name = getFormattedMediaLibraryFilename(filename)
      if (item.format == null) item.format = item.name.substr(item.name.lastIndexOf('.') + 1);
    }
  }



  //Actions.

  function selectItem(item: MediaLibraryFile) {
    let itemToSelect: MediaLibraryFile | null = item
    if (itemToSelect.id === selection?.id) itemToSelect = null
    setSelection(itemToSelect)
    onSelectCallback(itemToSelect)
  }

  function confirmSelection() {
    onConfirmCallback(selection)
    onClose()
  }



  //Upload.

  //Shows the browser dialog to select a file.
  function promptFileToUpload() {
    if (enableUpload === false) return
    if (inputFile == null) return
    inputFile.current?.click()
  }

  //Triggered when the file is selected from the picker.
  function selectedFileToUpload(event: any) {
    if (enableUpload === false) return

    const target = event.target as HTMLInputElement
    const files = target.files
    if (files == null) return
    const value = files[0]
    setFileToUpload(value)
  }

  //Automatically uploads the file once it has been selected.
  useEffect(() => {
    if (enableUpload === false) return
    if (fileToUpload == null) return
    if (isUploading === true) return

    uploadFile()
  }, [fileToUpload])

  //Uploads the file to the server.
  async function uploadFile() {
    if (enableUpload === false) return
    if (fileToUpload == null) return

    //Converts the file to base64.
    const base64File = await convertFileToBase64(fileToUpload as any)
    uploadFileToMediaLibrary(fileToUpload, base64File)

    //Perform the actual file upload.
    function uploadFileToMediaLibrary(originalFile: File, fileData: any) {
      setIsUploading(true)
      const encode = (): UploadMediaLibraryFileRequestParams => {
        const folder = getMediaLibraryFolderFromValue(session, mediaLibraryFolder!)
        return {
          filename: getFileName(originalFile.name) ?? '',
          fileData: fileData,
          folderId: folder?.id ?? 0,
        }
      }

      uploadMediaLibraryFile(encode(), {
        response(data) {
          toast.success('File caricato con successo')
          fetchLibraryItems()
          setIsUploading(false)
          setFileToUpload(null)
        },
        error(error, message) {
          toast.error(message)
          setIsUploading(false)
          setFileToUpload(null)
        }
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <div className={classes.dialogHeader}>
        <div className={classes.titleContainer}>
          <Typography component="h2" variant="h2">{title}</Typography>
          <Typography component="p" variant="body1">{description}</Typography>
        </div>

        {enableUpload === true && (
          <div className={classes.fileContainer}>
            <input type="file" ref={inputFile} style={{ visibility: 'hidden', display: 'none' }} onChange={selectedFileToUpload} />
            {fileToUpload != null && (
              <>
                {/* <Typography className={classes.uploadMessage}>Selezionato: {fileToUpload.name}</Typography> */}
                {isUploading === false && (<Button variant="text" color="secondary" endIcon={<Spinner type={'small'} />} onClick={uploadFile}>Carica file</Button>)}
                {isUploading === true && (<Button variant="text" color="secondary" endIcon={<Spinner type={'small'} />}>Carico...</Button>)}
              </>
            )}
            {fileToUpload == null && (
              <Button variant="text" color="secondary" endIcon={<AddIcon />} onClick={promptFileToUpload}>Nuovo file</Button>
            )}
          </div>
        )}
      </div>

      <DialogContent>
        {isLoading === true && (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        )}

        {(isLoading === false && files != null && files.length === 0) && (
          <div className={classes.loadingContainer}>
            <Typography className={classes.uploadMessage}>Nessun file trovato</Typography>
          </div>
        )}

        {(isLoading === false && files != null && files.length > 0) && (
          <GridList className={classes.gridList} cols={8}>
            {files?.map((item, i) => (
              <GridListTile className={item.id === selection?.id ? clsx(classes.gridItem, classes.gridItemSelected) : clsx(classes.gridItem)} key={i} cols={1} onClick={e => selectItem(item)}>
                <img src={item.path} alt={item.name} />
                <GridListTileBar title={item.name} subtitle={item.format} />
              </GridListTile>
            ))}
          </GridList>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="text" className={classes.ctaCancel} onClick={onClose}>Chiudi</Button>
        <Button variant="contained" className={classes.ctaSuccess} onClick={confirmSelection}>Conferma</Button>
      </DialogActions>
    </Dialog >
  )
}

export default FilePicker
