import React, { useState, useEffect, ReactNode } from 'react'
import { Card, TableSortLabel, Badge, IconButton, TextField, InputAdornment, Typography, Menu, MenuItem, ListItemIcon, makeStyles } from '@material-ui/core'
import { CardListingFilterDialog, CardListingFooter } from './'
import { APIPaginatable, APISortable, APISearchable } from 'app/api/types'
import { CardHeader, CardContent } from 'components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Color, FontSize, FontWeight } from 'theme/style'
import { useRouter } from 'app/utils'
import { RootState } from 'app/session/store'
import { config } from 'app/config'
import { useSelector, useDispatch } from 'react-redux'
import { updatePersistedListParameters } from 'app/session/actions'
import _ from 'lodash'
import clsx from 'clsx'
import { TableResultsOption } from 'app/values'
import FilterListIcon from '@material-ui/icons/FilterList'
import { jsonToCSV } from 'react-papaparse'
import { saveAs } from 'file-saver'
import DownloadIcon from '@material-ui/icons/GetApp'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import SortingArrowIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: '100%',
    height: '3.6rem',
    backgroundColor: Color.Background,
    borderRadius: '1.8rem',
    minWidth: '300px',
  },
  searchBarIcon: {
    width: '20px',
    height: '20px',
    position: 'relative',
    left: '8px',
    color: Color.TextSecondary
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    height: '3.6rem',
    width: '3.6rem',
    backgroundColor: Color.Background,
  },
  filterButtonIcon: {
    width: '20px',
    height: '20px',
    color: Color.TextSecondary
  },
  filtersBadge: {
    backgroundColor: Color.Notification,
    color: Color.White,
    fontSize: FontSize.Body2
  },
  noResults: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    width: '100%',
  },

  sortingIcon: {
    '& >svg': {
      position: 'absolute',
      right: '-22px'
    },
  },

  tableHead: {
    alignItems: 'center',
    display: 'flex',
    height: '50px',
    padding: theme.spacing(1, 0),
    width: '100%',
    borderBottom: '2px solid transparent',
    borderBottomColor: Color.Background,
  },

  tableBody: {
    width: '100%',
  },

  tableRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    margin: 0,
    padding: theme.spacing(1),
    width: '100%',
  },
  tableHeadRow: {
  },
  tableBodyRow: {
    borderBottom: '1px solid transparent',
    borderBottomColor: Color.Background,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Color.Background
    }
  },

  tableCell: {
    ...theme.typography.body1,
    position: 'relative',
    flex: 1
  },
  tableHeadCell: {
    padding: theme.spacing(0, 1) + ' !important'
  },
  tableBodyCell: {
    padding: theme.spacing(0, 1) + ' !important'
  },
  tableCellTruncated: {
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
  },

  highlightText: {
    backgroundColor: Color.TextHighlight,
  }
}))


//TYPES

//List data.
export type ListHeaderProps = {
  name: string
  key: string
  sortable?: boolean
  searchable?: boolean
  className?: string
}[]
type ListRowItem = {
  key: string,
  value: string | ReactNode
  rawValue?: string
  className?: string
  clip?: boolean
}
export type ListRowProps = {
  url?: {
    path: string,
    external?: boolean
  }
  className?: string
  data: ListRowItem[]
}
export type ListDataSource = ListRowProps[]

//Sorting.
export type ListSorting = {
  order?: string
  direction?: ListSortingDirection
}
export type ListSortingDirection = 'asc' | 'desc'

//Filters.
type ListFilters = { [key: string]: any }
export type CardListingFilterProps = {
  name: string
  className?: string
  onFilterCallback?: (key: string, value: any) => void
}

//Request.
export interface FetchRequestParameters extends APIPaginatable, APISortable, APISearchable {
}
export interface FetchAllRequestParameters extends APIPaginatable, APISortable, APISearchable {
  filters?: ListFilters
}



//PROPS

type CardListingProps = {

  //Identifier used to store list parameters in session for persistence between screens.
  id: string

  //Sets a loading layout for the component.
  isLoading?: boolean

  //Required attributes.
  title: string
  items: any[] | null

  //The headers of the list.
  headers: ListHeaderProps

  //The function used to build each list's row.
  //Each item in the "item" property will be passed to this function as an argument.
  dataConstructor: (item: any) => ListRowProps

  //Optional parameters used to handle pagination. Should be used when the list is handled by API requests.
  count?: number
  page?: number
  handleChangePageCallback?: (page: number) => void
  rowsPerPage?: number
  handleChangeRowsPerPageCallback?: (rows: number) => void

  //Optional sorting parameters.
  sorting?: ListSorting
  handleChangeSortingCallback?: (sorting: ListSorting) => void

  //Enable export.
  enableExport?: boolean

  //Enable search field.
  enableSearch?: boolean

  //Custom filters added to the card.
  filtersNodes?: ReactNode[]

  //Defines if the list changes should be handled by API requests instead of proceddes client-side when sorting, paginating and filtering.
  async?: boolean

  //Function that triggers the API request to fetch the items.
  fetchRequestCallback?: (requestParams: FetchRequestParameters, allParams: FetchAllRequestParameters) => void
}



//COMPONENT

const CardListing = ({ ...props }: CardListingProps) => {

  //PARAMETERS

  const classes = useStyles()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)
  const router = useRouter()
  const [dataValue, setDataValue] = useState<ListDataSource | null>(null)

  const [filtersDialogShowed, setFiltersDialogShowed] = useState(false)

  //Persisted parameters.
  let storedParameters: typeof session.persistedListParameters | null = null
  if (session.persistedListParameters.listId === props.id) storedParameters = session.persistedListParameters

  //Async.
  const defaultAsyncValue = false
  const asyncValue = props.async ?? defaultAsyncValue

  //Page.
  const defaultPageValue = 0
  const [pageValue, setPageValue] = useState<number>(() => {
    if (storedParameters != null) return storedParameters.page
    if (props.page != null) return props.page
    return defaultPageValue
  })

  //Rows per page.
  const defaultRowsPerPageValue = TableResultsOption.m
  const [rowsPerPageValue, setRowsPerPageValue] = useState<number>(() => {
    if (storedParameters != null) return storedParameters.rowsPerPage
    if (props.rowsPerPage != null) return props.rowsPerPage
    return defaultRowsPerPageValue
  })

  //Count.
  const defaultCountValue = 0
  const [countValue, setCountValue] = useState<number>(props.count ?? defaultCountValue)

  //Sorting.
  const defaultSortingValue = {}
  const [sortingValue, setSortingValue] = useState<ListSorting>(() => {
    if (storedParameters != null) return storedParameters.sorting
    if (props.sorting != null) return props.sorting
    return defaultSortingValue
  })

  //Search.
  const defaultEnableSearchValue = true
  const enableSearchValue = props.enableSearch ?? defaultEnableSearchValue

  const defaultSearchValue: string = ''
  const [searchValue, setSearchValue] = useState<string>(() => {
    if (storedParameters != null) return storedParameters.query ?? ''
    return defaultSearchValue
  })
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    if (storedParameters != null) return storedParameters.query ?? ''
    return defaultSearchValue
  })

  //Filters.
  const defaultFiltersValue: ListFilters = {}
  const [filtersValue, setFiltersValue] = useState(() => {
    if (storedParameters != null) return storedParameters.filters
    return defaultFiltersValue
  })
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(() => {
    if (storedParameters != null) return Object.keys(storedParameters).length ?? 0
    return 0
  })



  //FETCHING

  const fetchItems = () => {
    if (asyncValue === false) return
    if (props.fetchRequestCallback == null) return

    const offset = pageValue * rowsPerPageValue
    const limit = rowsPerPageValue

    //Compose the parameters object used in the API request.
    let requestParams: FetchRequestParameters = {}
    if (offset != null) requestParams.offset = offset
    if (limit != null) requestParams.limit = limit
    if (searchQuery != null && searchQuery !== '') requestParams.querySearch = searchQuery
    if (sortingValue != null) {
      requestParams.orderColumn = sortingValue.order
      requestParams.orderType = sortingValue.direction
    }

    //Compose the parameters object with all the parameters handled by the list component.
    let allParams: FetchAllRequestParameters = _.clone(requestParams)
    if (filtersValue != null && Object.entries(filtersValue).length !== 0) allParams.filters = filtersValue

    props.fetchRequestCallback(requestParams, allParams)
  }



  //SESSION

  function updateSession() {
    //Stores all the current list parameters in session to persist later.
    dispatch(updatePersistedListParameters({
      listId: props.id,
      page: pageValue,
      rowsPerPage: rowsPerPageValue,
      query: searchQuery,
      sorting: {
        order: sortingValue.order,
        direction: sortingValue.direction
      },
      filters: filtersValue
    }))
  }



  //PAGINATION

  function handleChangePage(page: number) {
    setPageValue(page)
    if (props.handleChangePageCallback != null) props.handleChangePageCallback(page)
  }

  function handleChangeRowsPerPage(rows: number) {
    setRowsPerPageValue(rows)
    if (props.handleChangeRowsPerPageCallback != null) props.handleChangeRowsPerPageCallback(rows)
  }



  //COUNT

  useEffect(() => {
    //Used only if the list is handled by API requests, since in the opposite case the count value is automatically calculated based on the entire data length.
    if (asyncValue === false) return
    if (props.count == null) return
    setCountValue(props.count)
  }, [props.count])



  //SORTING

  function handleChangeSorting(key: string) {
    //Sets/inverts the sorting order based on the current sorting status.
    let direction: ListSortingDirection = 'asc'
    if (key === sortingValue.order) direction = sortingValue.direction === 'asc' ? 'desc' : 'asc'
    const newSorting = { order: key, direction: direction }
    setSortingValue(newSorting)

    if (props.handleChangeSortingCallback != null) props.handleChangeSortingCallback(newSorting)
  }



  //SEARCH

  var timer: NodeJS.Timeout

  function handleChangeSearch(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value
    setSearchValue(value)
  }

  function handleConfirmSearch() {
    if (timer) clearTimeout(timer)
    setSearchQuery(searchValue)
  }

  //Automatically triggers the search confirm after a delay while typing.
  useEffect(() => {
    if (enableSearchValue === false) return

    timer = setTimeout(() => {
      handleConfirmSearch()
    }, config.automation.autosearch.delay)
    return () => clearTimeout(timer)
  }, [searchValue])



  //FILTERS

  function handleChangeFilter(key: string, value: any) {
    if (filtersValue == null) return

    let temp = _.clone(filtersValue)
    if (value === null) delete temp[key]
    else temp[key] = value

    setFiltersValue(temp)
  }

  useEffect(() => {
    let amount = 0
    if (filtersValue != null) amount = Object.keys(filtersValue).length
    setActiveFiltersCount(amount)
  }, [filtersValue])

  const openFiltersDialog = () => {
    setFiltersDialogShowed(true)
  }

  const closeFiltersDialog = () => {
    setFiltersDialogShowed(false)
  }



  //DATA

  useEffect(() => {
    if (props.items == null) return

    let data: ListDataSource = []
    props.items.map(i => data.push(props.dataConstructor(i)))
    setDataValue(data)

    //Calculates the data length, only if the list is not handled by API requests.
    if (asyncValue === false) setCountValue(data.length ?? 0)
  }, [props.items])

  useEffect(() => {
    //This funciton is called everytime a change is applied to the list.
    //It automatically refresh the items and updates the session status to persist data between screens.
    fetchItems()
    updateSession()
  }, [pageValue, rowsPerPageValue, sortingValue, filtersValue, searchQuery])

  function prepareDataForDisplay(): ListDataSource {
    if (dataValue == null || dataValue.length === 0) return []
    if (pageValue == null) return []
    if (rowsPerPageValue == null) return []

    //If the list is handled by API requests just return the data provided.
    if (asyncValue === true) return dataValue

    //Sorts the list based on sorting options, only if the list is not handled by API requests, since in that case the order is automatically handled server-side ans should not be processed client-side.
    let newData: ListDataSource = dataValue
    newData = _.sortBy(dataValue, function (row) {
      const elem = row.data.filter(f => f.key === sortingValue.order)
      if (elem.length >= 1) return elem[0].value
      return undefined
    })
    if (sortingValue.direction === 'desc') newData = _.reverse(newData)

    //Filters the list based on pagination options.
    const startIndex = 0 + (pageValue * rowsPerPageValue)
    const endIndex = startIndex + rowsPerPageValue

    return newData.slice(startIndex, endIndex)
  }

  function formatItemValue(item: ListRowItem) {
    if (searchQuery === '') return item.value

    const isSearchable = props.headers.find(h => h.searchable === true && h.key === item.key)?.searchable === true

    if (isSearchable === true) {
      const reactStringReplace = require('react-string-replace')
      return reactStringReplace(item.value, searchQuery, (match: any, i: number) => (
        <span className={classes.highlightText}>{match}</span>
      ))
    }

    return item.value
  }



  //FORWARDING

  function goToLink(row: ListRowProps) {
    if (row.url == null) return
    router.history.push(row.url.path)

    //Internal link.
    // {...((row.url?.path && row.url?.external === false) && {
    //   component: RouterLink, to: row.url.path
    // })}
    //External link.
    // {...((row.url?.path && row.url?.external === true) && {
    //   component: Link, to: row.url.path, target: '_blank'
    // })}
  }



  //OPTIONS
  const [anchorOptionEl, setAnchorOptionEl] = useState<null | HTMLElement>(null)
  const optionsAreOpen = Boolean(anchorOptionEl)

  const enableOptions = props.enableExport === true

  const openOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOptionEl(event.currentTarget)
  }

  const closeOptions = () => {
    setAnchorOptionEl(null)
  }



  //EXPORT

  function exportData() {
    let dataToExport: any = prepareForExport()

    const csv = jsonToCSV(dataToExport)
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const filename = props.title + '.csv'

    saveAs(csvData, filename)

    function prepareForExport() {
      const temp: any = []
      const d = prepareDataForDisplay()
      d.forEach(row => {
        let rowData: any = {}
        row.data.forEach(item => {
          const key = item.key
          const value = item.rawValue ?? item.value
          rowData[key] = value
        })
        temp.push(rowData)
      })
      return temp
    }
  }



  //RENDER

  return (
    <>
      <Card>
        <CardHeader title={props.title} action={
          <>
            {enableSearchValue === true && (
              <TextField className={classes.searchBar} name={'searchBar'} value={searchValue} placeholder="Cerca..."
                InputProps={{ startAdornment: (<InputAdornment className={classes.searchBarIcon} position="start"><SearchIcon className={classes.searchBarIcon} /></InputAdornment>) }}
                onChange={e => handleChangeSearch(e)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    handleConfirmSearch()
                  }
                }}
              />
            )}

            {props.filtersNodes != null && (
              <>
                <Badge overlap="circle" classes={{ badge: classes.filtersBadge }} badgeContent={activeFiltersCount} invisible={activeFiltersCount === 0}>
                  <IconButton className={classes.filterButton} onClick={openFiltersDialog}><FilterListIcon className={classes.filterButtonIcon} /></IconButton>
                </Badge>
              </>
            )}

            {enableOptions === true &&
              <>
                <Badge overlap="circle" classes={{ badge: classes.filtersBadge }} badgeContent={activeFiltersCount} invisible={activeFiltersCount === 0}>
                  <IconButton className={classes.filterButton} onClick={openOptions}><MoreHorizIcon className={classes.filterButtonIcon} /></IconButton>
                </Badge>
                <Menu anchorEl={anchorOptionEl} open={optionsAreOpen} onClose={closeOptions}>
                  {<MenuItem onClick={exportData}><ListItemIcon><DownloadIcon /></ListItemIcon>Esporta CSV</MenuItem>}
                </Menu>
              </>
            }
          </>
        } />
        <CardContent isLoading={props.isLoading}>
          <PerfectScrollbar>
            <div>
              {props.items?.length === 0 && (
                <div className={classes.noResults}>
                  <Typography>{'Nessun risultato trovato'}</Typography>
                </div>
              )}

              {props.items != null && props.items.length > 0 && (
                <div className={classes.table}>
                  <div className={classes.tableHead}>
                    <div className={clsx(classes.tableRow, classes.tableHeadRow)}>
                      {/* <TableCell key={-1} className={classes.itemIndex}></TableCell> */}
                      {props.headers.map((item, index) => (
                        <div key={index} style={{ fontWeight: FontWeight.Medium }} className={clsx(classes.tableCell, classes.tableHeadCell, item.className)}>
                          {item.sortable === true ? (
                            <TableSortLabel className={classes.sortingIcon} active={sortingValue?.order === item.key} direction={sortingValue?.direction !== 'asc' ? 'desc' : 'asc'} onClick={e => handleChangeSorting(item.key)} IconComponent={SortingArrowIcon}>
                              {item.name}
                            </TableSortLabel>
                          ) : (item.name)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={classes.tableBody}>
                    {prepareDataForDisplay().map((row, index) => (
                      <div className={clsx(classes.tableRow, classes.tableBodyRow, row.className)} key={index} onClick={() => goToLink(row)}>
                        {row.data.map((item, i) => (
                          <div key={index + '_' + i} className={
                            item.clip === true ? clsx(classes.tableCell, classes.tableBodyCell, classes.tableCellTruncated, item.className) : clsx(classes.tableCell, classes.tableBodyCell, item.className)
                          }>
                            {formatItemValue(item)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardListingFooter
          count={countValue}
          page={pageValue} handleChangePageCallback={handleChangePage}
          rowsPerPage={rowsPerPageValue} handleChangeRowsPerPageCallback={handleChangeRowsPerPage}
        />
      </Card >

      <CardListingFilterDialog open={filtersDialogShowed} onClose={closeFiltersDialog} filters={props.filtersNodes} onFilterCallback={handleChangeFilter} />
    </>
  )
}

export default CardListing
