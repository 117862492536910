import { MediaLibraryFolder } from 'app/entities/types'
import { SessionState } from 'app/session/reducers'
import { MediaLibraryFolderValue } from 'app/values'

export function mediaLibraryFolderIdentifier(f: MediaLibraryFolder | null): string {
  if (f == null) {
    console.warn('MediaLibraryFolder: inesistente')
    return ''
  }

  return f.keyword
}

export const getMediaLibraryFolderFromValue = (session: SessionState, v: MediaLibraryFolderValue): MediaLibraryFolder | null => {
  const obj = session.app.mediaLibraryFolders.filter(f => mediaLibraryFolderIdentifier(f) === v)
  return obj.length > 0 ? obj[0] : null
}

