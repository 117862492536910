import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllPageTypes = 'api/pageTypes',
  GetPageTypeById = 'api/pageTypes/getById',
  CreatePageType = 'api/pageTypes/create',
  UpdatePageType = 'api/pageTypes/update',
  DeletePageType = 'api/pageTypes/delete',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetPageTypesResponse = {
  pageTypes: {
    id: number
    keyword: string | null
    name: string
  }[]
}

enum GetPageTypesResponseError {
}

export async function getPageTypes(output?: RequestOutput<GetPageTypesResponse, GetPageTypesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllPageTypes,
    output: output,
  })
}



export type GetPageTypeRequestParams = {
  id: number
}
export type GetPageTypeResponse = {
  pageType: {
    id: number,
    keyword: string | null
    name: string
  }
}

enum GetPageTypeResponseError {
}

export async function getPageType(params: GetPageTypeRequestParams, output?: RequestOutput<GetPageTypeResponse, GetPageTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPageTypeById,
    params: params,
    output: output,
  })
}



export type CreatePageTypeRequestParams = {
  name: string
  keyword: string | null
}

type CreatePageTypeResponse = {
  pageType: {
    id: number
  }
}

enum CreatePageTypeResponseError {
}

export async function createPageType(params: CreatePageTypeRequestParams, output?: RequestOutput<CreatePageTypeResponse, CreatePageTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreatePageType,
    params: params,
    output: output,
  })
}



export type UpdatePageTypeRequestParams = {
  id: number
  keyword: string | null
  name: string
}

type UpdatePageTypeResponse = {
}

enum UpdatePageTypeResponseError {
}

export async function updatePageType(params: UpdatePageTypeRequestParams, output?: RequestOutput<UpdatePageTypeResponse, UpdatePageTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePageType,
    params: params,
    output: output,
  })
}



export type DeletePageTypeRequestParams = {
  id: number
}

type DeletePageTypeResponse = {
}

enum DeletePageTypeResponseError {
}

export async function deletePageType(params: DeletePageTypeRequestParams, output?: RequestOutput<DeletePageTypeResponse, DeletePageTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeletePageType,
    params: params,
    output: output,
  })
}
