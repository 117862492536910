import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Badge, IconButton, Toolbar, Hidden, Avatar } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import useRouter from 'app/utils/useRouter';
import { logout } from 'app/session/actions';
import { getInitials, deleteToken } from 'app/utils'
import { FontWeight } from 'theme/style'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  input: {
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    width: '2.4rem',
    height: '2.4rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  notificationsButton: {
    color: theme.palette.text.primary,
    fontSize: '3rem',
  },
  notificationsIcon: {
    fontSize: '2rem',
  },
  notificationsBadge: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '1.1rem',
    fontWeight: FontWeight.Bold,
    fontFamily: 'Circular, sans-serif'
  },
  avatar: {
    height: 42,
    width: 42,
    backgroundColor: theme.palette.secondary.main,
    border: '3px solid #ffffff',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.12)',
    marginLeft: theme.spacing(2)
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const user = session.user;

  const handleLogout = () => {
    deleteToken()
    dispatch(logout())
    history.push('/auth/login')
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  useEffect(() => {
    // let mounted = true;

    const fetchNotifications = () => {
      setNotifications([]);
      // axios.get('/api/account/notifications').then(response => {
      //   if (mounted) {
      //     setNotifications(response.data.notifications);
      //   }
      // });
    };

    fetchNotifications();

    return () => {
      // mounted = false;
    };
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} color="primary" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden mdDown>
          {/* <Input disableUnderline className={classes.input} placeholder={t('common.search')} variant="standard" startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          } /> */}
          <div className={classes.actions}>
            <IconButton className={classes.notificationsButton} onClick={handleNotificationsOpen} ref={notificationsRef}>
              <Badge badgeContent={notifications.length} max={99} classes={{ badge: classes.notificationsBadge }}>
                <NotificationsIcon className={classes.notificationsIcon} />
              </Badge>
            </IconButton>
            <IconButton className={classes.notificationsButton} onClick={handleLogout}>
              <LogoutIcon className={classes.notificationsIcon} />
            </IconButton>
            <Avatar className={classes.avatar} src={'/images/avatars/' + user.avatar + '.png'}>
              {getInitials(user.firstname + ' ' + user.lastname)}
            </Avatar>
          </div>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
