import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllUserRoles = 'api/roles',
  GetUserRoleById = 'api/roles/getById',
  CreateUserRole = 'api/roles/create',
  UpdateUserRole = 'api/roles/update',
  DeleteUserRole = 'api/roles/delete',
  AddUserPermissionToUserRole = 'api/roles/addPermission',
  RemoveUserPermissionToUserRole = 'api/roles/removePermission',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetUserRolesResponse = {
  roles: {
    id: number
    keyword: string | null
    name: string
    permissions: any
  }[]
}

enum GetUserRolesResponseError {
}

export async function getUserRoles(output?: RequestOutput<GetUserRolesResponse, GetUserRolesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllUserRoles,
    output: output,
  })
}



export type GetUserRoleRequestParams = {
  id: number
}
export type GetUserRoleResponse = {
  role: {
    id: number,
    keyword: string | null
    name: string,
    permissions: {
      id: number,
      name: string
      keyword: string
    }[]
  }
}

enum GetUserRoleResponseError {
}

export async function getUserRole(params: GetUserRoleRequestParams, output?: RequestOutput<GetUserRoleResponse, GetUserRoleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetUserRoleById,
    params: params,
    output: output,
  })
}



export type CreateUserRoleRequestParams = {
  name: string
  keyword: string | null
}

type CreateUserRoleResponse = {
  role: {
    id: number
  }
}

enum CreateUserRoleResponseError {
}

export async function createUserRole(params: CreateUserRoleRequestParams, output?: RequestOutput<CreateUserRoleResponse, CreateUserRoleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateUserRole,
    params: params,
    output: output,
  })
}



export type UpdateUserRoleRequestParams = {
  id: number
  keyword: string | null
  name: string
}

type UpdateUserRoleResponse = {
}

enum UpdateUserRoleResponseError {
}

export async function updateUserRole(params: UpdateUserRoleRequestParams, output?: RequestOutput<UpdateUserRoleResponse, UpdateUserRoleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateUserRole,
    params: params,
    output: output,
  })
}



export type DeleteUserRoleRequestParams = {
  id: number
}

type DeleteUserRoleResponse = {
}

enum DeleteUserRoleResponseError {
}

export async function deleteUserRole(params: DeleteUserRoleRequestParams, output?: RequestOutput<DeleteUserRoleResponse, DeleteUserRoleResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteUserRole,
    params: params,
    output: output,
  })
}



export type AddUserPermissionToUserRoleRequestParams = {
  roleId: number
  permissionId: number
}

type AddUserPermissionToUserRoleResponse = {
  // tag: {
  //   id: number
  //   name: string
  // }
}

enum AddUserPermissionToUserRoleReponseError {
}

export async function addUserPermissionToUserRole(params: AddUserPermissionToUserRoleRequestParams, output?: RequestOutput<AddUserPermissionToUserRoleResponse, AddUserPermissionToUserRoleReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddUserPermissionToUserRole,
    params: params,
    output: output,
  })
}



export type RemoveUserPermissionToUserRoleRequestParams = {
  roleId: number
  permissionId: number
}

type RemoveUserPermissionToUserRoleResponse = {
}

enum RemoveUserPermissionToUserRoleReponseError {
}

export async function removeUserPermissionToUserRole(params: RemoveUserPermissionToUserRoleRequestParams, output?: RequestOutput<RemoveUserPermissionToUserRoleResponse, RemoveUserPermissionToUserRoleReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveUserPermissionToUserRole,
    params: params,
    output: output,
  })
}