import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { configureStore } from 'app/session/store'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from 'app/routes'
import { createBrowserHistory } from 'history'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ScrollReset } from 'components'
import 'assets/stylesheets/main.scss'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'theme/config'
import { HelmetProvider } from 'react-helmet-async'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'app/utils'
import { config } from 'app/config'
import { LocalizationProvider } from 'components'

if (process.env.NODE_ENV !== 'development') {
  function empty() { }
  console.log = empty;
  console.warn = empty;
  console.error = empty;
}

const history = createBrowserHistory()
const store = configureStore()

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <LocalizationProvider defaultLanguage={config.app.defaultLanguageCode}>
              <HelmetProvider>
                <ScrollReset />
                {renderRoutes(routes)}
                <SnackbarProvider maxSnack={1} hideIconVariant anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transitionDuration={{ enter: 150, exit: 150 }}>
                  <SnackbarUtilsConfigurator />
                </SnackbarProvider>
              </HelmetProvider>
            </LocalizationProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider >
  )
}

export default App
