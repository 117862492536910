import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllStores = 'api/stores',
  GetStoreById = 'api/stores/getById',
  CreateStore = 'api/stores/create',
  UpdateStore = 'api/stores/update',
  DeleteStore = 'api/stores/delete',
  UpdateStoreStatus = 'api/stores/updatePublished',
  GetStoreTranslation = 'api/stores/getTranslation',
  UpdateStoreTranslation = 'api/stores/updateTranslation',
  GetStoreOpenings = 'api/stores/getOpenings',
  UpdateStoreOpenings = 'api/stores/updateOpenings',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetAllStoresRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
  brandId?: number | null
}

export interface GetAllStoresResponse extends APIPaginatableResponse {
  stores: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    address: string
    phone: string
    zip: string
    latitude: number
    longitude: number
    isActive: boolean
    nielsenArea: string | null
    cityId: number | null
    brandId: number | null
    translation: {
      name: string
      description: string
      content: string
    }[],
  }[]
}

enum GetAllStoresResponseError {
}

export async function getAllStores(params: GetAllStoresRequestParams, output?: RequestOutput<GetAllStoresResponse, GetAllStoresResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllStores,
    params: params,
    output: output,
  })
}



export type GetStoreRequestParams = {
  id: number
}
export type GetStoreResponse = {
  store: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    address: string
    phone: string
    zip: string
    latitude: number
    longitude: number
    isActive: boolean
    nielsenArea: string | null
    regionId: number | null
    provinceId: number | null
    cityId: number | null
    brandId: number | null
    translation: {
      name: string
      description: string
      content: string
    }[],
  }
}

enum GetStoreResponseError {
}

export async function getStore(params: GetStoreRequestParams, output?: RequestOutput<GetStoreResponse, GetStoreResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetStoreById,
    params: params,
    output: output,
  })
}



export type CreateStoreRequestParams = {
  parentId: number | null
}

type CreateStoreResponse = {
  store: {
    id: number
    name: string
  }
}

enum CreateStoreReponseError {
}

export async function createStore(params: CreateStoreRequestParams, output?: RequestOutput<CreateStoreResponse, CreateStoreReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateStore,
    params: params,
    output: output,
  })
}



export type UpdateStoreRequestParams = {
  id: number
  keyword?: string
  parentId?: number | null
  address?: string
  phone?: string
  zip?: string
  latitude?: number
  longitude?: number
  isActive?: boolean
  nielsenArea?: string | null
  cityId?: number | null
  brandId?: number | null
}

type UpdateStoreResponse = {
}

enum UpdateStoreReponseError {
}

export async function updateStore(params: UpdateStoreRequestParams, output?: RequestOutput<UpdateStoreResponse, UpdateStoreReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateStore,
    params: params,
    output: output,
  })
}



export type DeleteStoreRequestParams = {
  id: number
}

type DeleteStoreResponse = {
}

enum DeleteStoreReponseError {
}

export async function deleteStore(params: DeleteStoreRequestParams, output?: RequestOutput<DeleteStoreResponse, DeleteStoreReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteStore,
    params: params,
    output: output,
  })
}



export type UpdateStoreStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateStoreStatusResponse = {
}

enum UpdateStoreStatusReponseError {
}

export async function updateStoreStatus(params: UpdateStoreStatusRequestParams, output?: RequestOutput<UpdateStoreStatusResponse, UpdateStoreStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateStoreStatus,
    params: params,
    output: output,
  })
}



export type GetStoreTranslationRequestParams = {
  storeId: number
  languageId: number
}

export type GetStoreTranslationResponse = {
  storeTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    ingredients: string,
    benefits: string,
    deepening: string,
    languageId: number
  }
}

enum GetStoreTranslationResponseError {
}

export async function getStoreTranslation(params: GetStoreTranslationRequestParams, output?: RequestOutput<GetStoreTranslationResponse, GetStoreTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetStoreTranslation,
    params: params,
    output: output,
  })
}



export type UpdateStoreTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  ingredients?: string | null,
  benefits?: string | null,
  deepening?: string | null,
}

type UpdateStoreTranslationResponse = {
}

enum UpdateStoreTranslationReponseError {
}

export async function updateStoreTranslation(params: UpdateStoreTranslationRequestParams, output?: RequestOutput<UpdateStoreTranslationResponse, UpdateStoreTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateStoreTranslation,
    params: params,
    output: output,
  })
}




export type GetStoreOpeningsRequestParams = {
  id: number
}

export type GetStoreOpeningsResponse = {
  store: {
    id: number
    openings: {
      openMon: string,
      openTue: string,
      openWed: string,
      openThu: string,
      openFri: string,
      openSat: string,
      openSun: string,
    }
  }
}

enum GetStoreOpeningsResponseError {
}

export async function getStoreOpenings(params: GetStoreOpeningsRequestParams, output?: RequestOutput<GetStoreOpeningsResponse, GetStoreOpeningsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetStoreOpenings,
    params: params,
    output: output,
  })
}



export type UpdateStoreOpeningsRequestParams = {
  id: number,
  openMon: string,
  openTue: string,
  openWed: string,
  openThu: string,
  openFri: string,
  openSat: string,
  openSun: string,
}

type UpdateStoreOpeningsResponse = {
}

enum UpdateStoreOpeningsReponseError {
}

export async function updateStoreOpenings(params: UpdateStoreOpeningsRequestParams, output?: RequestOutput<UpdateStoreOpeningsResponse, UpdateStoreOpeningsReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateStoreOpenings,
    params: params,
    output: output,
  })
}