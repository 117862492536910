import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllRegions = 'api/regions',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetAllRegionsRequestParams = {
  languageId: number
}

export type GetAllRegionsResponse = {
  regions: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageTypeId: number | null
    pageId: number
    translation: {
      name: string
    }[]
  }[]
}

enum GetAllRegionsResponseError {
}

export async function getAllRegions(params: GetAllRegionsRequestParams, output?: RequestOutput<GetAllRegionsResponse, GetAllRegionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllRegions,
    params: params,
    output: output,
  })
}
