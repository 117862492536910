
//Defines all the Field Types available in the app.
//IMPORTANT: Must be mapped to each specific field type keyword to work properly.
export enum FieldTypeValue {
  Text = 'text',
  RichText = 'rich-text',
  Boolean = 'boolean',
  Integer = 'integer',
  Decimal = 'decimal',
  Email = 'email',
  Url = 'url',
  Date = 'date',
  LibraryItem = 'library-item',
}