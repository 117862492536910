import { Card, Chip, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  addTaxonomyToPage, AddTaxonomyToPageRequestParams,
  removeTaxonomyFromPage, RemoveTaxonomyFromPageRequestParams
} from 'app/api';
import { useLocalization } from 'components';
import { TaxonomablePage, Taxonomy } from 'app/entities/types';
import { toast } from 'app/utils';
import clsx from 'clsx';
import { CardContent, CardHeader, PageEntityDetailsRefreshableProps } from 'components';
import difference from 'lodash/difference';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  taxonomiesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  taxonomy: {
    margin: theme.spacing(0.5),
  },
}))

enum InputType {
  Taxonomy,
}

enum UpdateTaxonomyAction {
  None = '',
  Add = 'add',
  Remove = 'remove'
}

interface TaxonomiesProps extends PageEntityDetailsRefreshableProps {
  className?: string,
  object: TaxonomablePage,
  taxonomiesList: Taxonomy[] | null,
  isLoading: boolean
}

const CardTaxonomies = ({ ...props }: TaxonomiesProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [activeTaxonomies, setActiveTaxonomies] = useState<Taxonomy[]>([])

  useEffect(() => {
    if (props.object.taxonomies == null) return
    if (props.taxonomiesList == null) return

    let items: Taxonomy[] = []
    props.taxonomiesList.map(t => {
      if (props.object.taxonomies?.find(tax => t.id === tax.id) != null) {
        items.push(t)
      }
    })
    setActiveTaxonomies(items)
  }, [props.taxonomiesList])

  const handleInputChange = (inputType: InputType, value: Taxonomy[], event: any) => {
    if (activeTaxonomies == null) return

    const initialCount = activeTaxonomies.length
    const nextCount = value.length
    let taxonomiesDiff: Taxonomy[] = []
    let action: UpdateTaxonomyAction = UpdateTaxonomyAction.None

    if (initialCount > nextCount) {
      action = UpdateTaxonomyAction.Remove
      taxonomiesDiff = difference(activeTaxonomies, value)
    }
    else if (initialCount < nextCount) {
      action = UpdateTaxonomyAction.Add
      taxonomiesDiff = difference(value, activeTaxonomies)
    }

    if (taxonomiesDiff.length > 1) return
    const taxonomy = taxonomiesDiff[0]

    switch (inputType) {
      case InputType.Taxonomy: {
        setActiveTaxonomies(value)
        updateTaxonomies(taxonomy, action)
        break
      }
    }
  }

  function updateTaxonomies(taxonomy: Taxonomy, action: UpdateTaxonomyAction) {
    if (props.object == null) return

    const addTaxonomy = (taxonomy: Taxonomy) => {
      const params: AddTaxonomyToPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      addTaxonomyToPage(params, {
        response(data) {
          toast.success('Categoria aggiunta')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTaxonomy = (taxonomy: Taxonomy) => {
      const params: RemoveTaxonomyFromPageRequestParams = {
        pageId: props.object.pageId,
        taxonomyId: taxonomy.id
      }

      removeTaxonomyFromPage(params, {
        response(data) {
          toast.success('Categoria rimossa')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case UpdateTaxonomyAction.Add:
        addTaxonomy(taxonomy)
        break

      case UpdateTaxonomyAction.Remove:
        removeTaxonomy(taxonomy)
        break

      default:
        break
    }
  }

  return (
    <Card className={clsx(classes.root, props.className)}>
      <CardHeader title={'Categorie'} iconName="tags" isLoading={props.isLoading} />
      <CardContent isLoading={props.isLoading}>
        <Autocomplete multiple filterSelectedOptions options={props.taxonomiesList ?? []} value={activeTaxonomies} getOptionLabel={(option) => option.translation[0].name ?? ''}
          renderTags={(taxonomyValue, getTaxonomyProps) => taxonomyValue.map((option, index) => (<Chip label={option.translation[0].name ?? ''} {...getTaxonomyProps({ index })} />))}
          renderInput={(params) => <TextField {...params} placeholder={t('screen.pageDetails.taxonomies.input.placeholder')} />}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(e, value) => handleInputChange(InputType.Taxonomy, value, e)}
        />
      </CardContent>
    </Card>
  )
}

export default CardTaxonomies