import React from 'react'
import { makeStyles, CircularProgress, CircularProgressProps } from '@material-ui/core'
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[200],
    position: 'absolute',
    left: 0,
  },
  top: {
    color: Color.Primary,
    animationDuration: '600ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}))

interface SpinnerProps extends CircularProgressProps {
  type?: 'default' | 'small'
}

const Spinner = ({ ...props }: SpinnerProps) => {
  const classes = useStyles()

  let sizeValue = 40
  if (props.type === 'small') sizeValue = 20
  if (props.type === 'default') sizeValue = 40

  let thickness = 4
  if (props.type === 'small') thickness = 6
  if (props.type === 'default') thickness = 4

  return (
    <div className={classes.root} style={{ width: sizeValue, height: sizeValue }}>
      <CircularProgress variant="determinate" className={classes.bottom} size={sizeValue} thickness={thickness} value={100} {...props} />
      <CircularProgress variant="indeterminate" disableShrink className={classes.top} size={sizeValue} thickness={thickness} classes={{
        circle: classes.circle,
      }} {...props}
      />
    </div>
  )
}

export default Spinner
