import { CardActions, TablePagination, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useLocalization } from 'components'
import React, { useState, useEffect } from 'react';
import { TableResultsOption } from 'app/values'

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '60px',
    minHeight: '40px'
  }
}))

interface CardListingFooterProps {
  page: number,
  rowsPerPage: number
  count: number
  handleChangePageCallback?: (page: number) => void
  handleChangeRowsPerPageCallback?: (rows: number) => void
}

const CardListingFooter = ({ count, page, rowsPerPage, handleChangePageCallback, handleChangeRowsPerPageCallback }: CardListingFooterProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const resultsOptions: any[] = [
    TableResultsOption.xs,
    TableResultsOption.s,
    TableResultsOption.m,
    TableResultsOption.l,
    TableResultsOption.xl,
  ]

  const [lastPage, setLastPage] = useState(1)

  useEffect(() => {
    let last = Math.ceil(count / rowsPerPage)
    if (last <= 0) last = 1
    setLastPage(last)
  }, [rowsPerPage, count])

  const handleChangePage = (page: number) => {
    if (handleChangePageCallback != null) handleChangePageCallback(page)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    if (handleChangeRowsPerPageCallback != null) handleChangeRowsPerPageCallback(rows)
    if (page + 1 > lastPage) handleChangePage(lastPage)
  }

  return (
    <CardActions className={classes.actions}>
      {/* <Pagination color="primary"
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={(e, p) => handleChangePage(p)}
      ></Pagination> */}

      <TablePagination component="div" count={count} page={page} rowsPerPage={rowsPerPage}
        onChangePage={(e, p) => handleChangePage(p)} onChangeRowsPerPage={e => handleChangeRowsPerPage(parseInt(e.target.value))}
        rowsPerPageOptions={resultsOptions} labelRowsPerPage={'Risultati:'} labelDisplayedRows={({ from, to, count }) => {
          return t('common.table.results.overview', {
            tags: { FROM: from, TO: to, COUNT: count, CURRENTPAGE: page + 1, TOTALPAGES: lastPage }
          })
        }} />
    </CardActions>
  )
}

export default CardListingFooter
