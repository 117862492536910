import palette from 'theme/config/palette';

export default {
  root: {
    backgroundColor: palette.white,
    padingTop: 0,
    borderBottom: `2px solid ${palette.divider}`,

  }
};
