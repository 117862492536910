import { Color } from 'theme/style'

export default {
  common: {
    black: Color.Black,
    white: Color.White,
  },
  background: {
    default: Color.Background,
    paper: Color.White,
  },
  primary: {
    main: Color.Primary,
  },
  secondary: {
    main: Color.Secondary,
  },
  error: {
    main: Color.Error,
  },
  warning: {
    main: Color.Warning,
  },
  success: {
    main: Color.Success,
  },
  info: {
    main: Color.Info,
  },
  text: {
    primary: Color.TextPrimary,
    secondary: Color.TextSecondary,
    disabled: Color.TextDisabled,
  },
  divider: Color.Divider,
}