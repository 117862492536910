import CodeIcon from '@material-ui/icons/Code';

export default [
  {
    title: 'sidebar.dev',
    pages: [
      {
        title: 'sidebar.dev.pages',
        icon: CodeIcon,
        children: [
          {
            title: 'sidebar.dev.pages.regions',
            href: '/dev/pages/regions',
          },
          {
            title: 'sidebar.dev.pages.provinces',
            href: '/dev/pages/provinces',
          },
          {
            title: 'sidebar.dev.pages.cities',
            href: '/dev/pages/cities',
          },
        ]
      },
      {
        title: 'sidebar.dev.mediaLibrary',
        icon: CodeIcon,
        children: [
          {
            title: 'sidebar.dev.mediaLibrary.folders',
            href: '/dev/media-library/folders',
          },
        ]
      },
      {
        title: 'sidebar.dev.products',
        icon: CodeIcon,
        children: [
          {
            title: 'sidebar.dev.products.nutritional-values',
            href: '/dev/products/nutritional-values',
          },
        ]
      },
      {
        title: 'sidebar.dev.types',
        icon: CodeIcon,
        children: [
          {
            title: 'sidebar.dev.types.page',
            href: '/dev/types/page',
          },
          {
            title: 'sidebar.dev.types.fieldGroup',
            href: '/dev/types/field-group',
          },
          {
            title: 'sidebar.dev.types.field',
            href: '/dev/types/field',
          },
        ]
      },
      {
        title: 'sidebar.dev.users',
        icon: CodeIcon,
        children: [
          {
            title: 'sidebar.dev.users.roles',
            href: '/dev/users/roles',
          },
          {
            title: 'sidebar.dev.users.permissions',
            href: '/dev/users/permissions',
          },
        ]
      },
      {
        title: 'sidebar.dev.tags',
        icon: CodeIcon,
        href: '/dev/tags',
      },
    ]
  },
]