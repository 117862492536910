import React, { useEffect } from 'react'
import { Input, InputBaseProps, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  input: {
    '& input': {
      textAlign: 'left',
      padding: theme.spacing(0, 1.5),
      height: '45px',
      backgroundColor: Color.Background,
      borderRadius: Value.BorderRadius_Field,
    },
  },
  adornmentText: {
    color: Color.Primary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    position: 'absolute',
    right: '15px'
  }
}))

interface CardInputFieldProps extends InputBaseProps {
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const TextField = ({ ...props }: CardInputFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }



  //RENDER

  return (
    <Input {...props} className={classes.input} onChange={updateField}></Input>
  )
}

export default TextField



type TextFieldAdornmentProps = {
  text: string
}
export const TextFieldAdornment = ({ text }: TextFieldAdornmentProps) => {
  const classes = useStyles()
  return (
    <Typography className={classes.adornmentText}>{text}</Typography>
  )
}
