
//Defines all the Media Library Folders available in the app.
//IMPORTANT: Must be mapped to each specific media library folder keyword to work properly.
export enum MediaLibraryFolderValue {
  General = 'general',
  Product = 'products',
  ProductTaxonomies = 'products-taxonomies',
  Article = 'articles',
  Documents = 'documents',
  Company = 'company',
  Brands = 'brands',
  Store = 'store',
  Flyers = 'flyers',
  Avatar = 'avatars',
}
