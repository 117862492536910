
import { UserPermissionValue } from 'app/values'

type AuthParameters = {
  permission: UserPermissionValue
  availableRoutes: string[]
}

export const auth: AuthParameters[] = [
  {
    permission: UserPermissionValue.AdminFeatures,
    availableRoutes: [
      '/admin'
    ]
  },
  {
    permission: UserPermissionValue.DeveloperFeatures,
    availableRoutes: [
      '/dev'
    ]
  },
  {
    permission: UserPermissionValue.WebsiteContentManagement,
    availableRoutes: [
      '/website/pages',
      '/website/dictionary',
    ]
  },
  {
    permission: UserPermissionValue.MediaManagement,
    availableRoutes: [
      '/website/media-library',
    ]
  },
  {
    permission: UserPermissionValue.ProductsManagement,
    availableRoutes: [
      '/website/products',
    ]
  },
  {
    permission: UserPermissionValue.ArticlesManagement,
    availableRoutes: [
      '/website/articles',
    ]
  },
  {
    permission: UserPermissionValue.StoresManagement,
    availableRoutes: [
      '/website/companies',
      '/website/brands',
      '/website/stores',
    ]
  },
]