import palette from 'theme/config/palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    }
  },
};
