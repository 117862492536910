import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable } from 'app/api/types'

enum APIEndpoint {
  GetAllCompanies = 'api/companies',
  GetCompanyById = 'api/companies/getById',
  CreateCompany = 'api/companies/create',
  UpdateCompany = 'api/companies/update',
  DeleteCompany = 'api/companies/delete',
  UpdateCompanyStatus = 'api/companies/updatePublished',
  GetCompanyTranslation = 'api/companies/getTranslation',
  UpdateCompanyTranslation = 'api/companies/updateTranslation',
  UpdateCompanyLogo = 'api/companies/updateLogo',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetAllCompaniesRequestParams extends APIPaginatable, APISortable {
  languageId: number
}

export interface GetAllCompaniesResponse extends APIPaginatableResponse {
  companies: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    address: string
    phone: string
    website: string
    translation: {
      name: string
      description: string
      content: string
    }[],
  }[]
}

enum GetAllCompaniesResponseError {
}

export async function getAllCompanies(params: GetAllCompaniesRequestParams, output?: RequestOutput<GetAllCompaniesResponse, GetAllCompaniesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllCompanies,
    params: params,
    output: output,
  })
}



export type GetCompanyRequestParams = {
  id: number
}
export type GetCompanyResponse = {
  company: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageId: number
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    logo: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    }
    address: string
    phone: string
    website: string
    translation: {
      name: string
      description: string
      content: string
    }[],
  }
}

enum GetCompanyResponseError {
}

export async function getCompany(params: GetCompanyRequestParams, output?: RequestOutput<GetCompanyResponse, GetCompanyResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetCompanyById,
    params: params,
    output: output,
  })
}



export type CreateCompanyRequestParams = {
  parentId: number | null
}

type CreateCompanyResponse = {
  company: {
    id: number
    name: string
  }
}

enum CreateCompanyReponseError {
}

export async function createCompany(params: CreateCompanyRequestParams, output?: RequestOutput<CreateCompanyResponse, CreateCompanyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateCompany,
    params: params,
    output: output,
  })
}



export type UpdateCompanyRequestParams = {
  id: number
  keyword: string
  parentId: number | null
  address: string
  phone: string
  website: string
}

type UpdateCompanyResponse = {
}

enum UpdateCompanyReponseError {
}

export async function updateCompany(params: UpdateCompanyRequestParams, output?: RequestOutput<UpdateCompanyResponse, UpdateCompanyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateCompany,
    params: params,
    output: output,
  })
}



export type DeleteCompanyRequestParams = {
  id: number
}

type DeleteCompanyResponse = {
}

enum DeleteCompanyReponseError {
}

export async function deleteCompany(params: DeleteCompanyRequestParams, output?: RequestOutput<DeleteCompanyResponse, DeleteCompanyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteCompany,
    params: params,
    output: output,
  })
}



export type UpdateCompanyStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateCompanyStatusResponse = {
}

enum UpdateCompanyStatusReponseError {
}

export async function updateCompanyStatus(params: UpdateCompanyStatusRequestParams, output?: RequestOutput<UpdateCompanyStatusResponse, UpdateCompanyStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateCompanyStatus,
    params: params,
    output: output,
  })
}



export type GetCompanyTranslationRequestParams = {
  companyId: number
  languageId: number
}

export type GetCompanyTranslationResponse = {
  companyTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    ingredients: string,
    benefits: string,
    deepening: string,
    languageId: number
  }
}

enum GetCompanyTranslationResponseError {
}

export async function getCompanyTranslation(params: GetCompanyTranslationRequestParams, output?: RequestOutput<GetCompanyTranslationResponse, GetCompanyTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetCompanyTranslation,
    params: params,
    output: output,
  })
}



export type UpdateCompanyTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  ingredients?: string | null,
  benefits?: string | null,
  deepening?: string | null,
}

type UpdateCompanyTranslationResponse = {
}

enum UpdateCompanyTranslationReponseError {
}

export async function updateCompanyTranslation(params: UpdateCompanyTranslationRequestParams, output?: RequestOutput<UpdateCompanyTranslationResponse, UpdateCompanyTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateCompanyTranslation,
    params: params,
    output: output,
  })
}


export type UpdateCompanyLogoRequestParams = {
  id: number
  mediaFileId: number | null
}

type UpdateCompanyLogoResponse = {
}

enum UpdateCompanyLogoReponseError {
}

export async function updateCompanyLogo(params: UpdateCompanyLogoRequestParams, output?: RequestOutput<UpdateCompanyLogoResponse, UpdateCompanyLogoReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateCompanyLogo,
    params: params,
    output: output,
  })
}