import React, { useState, useEffect, ReactNode } from 'react'
import clone from 'lodash/cloneDeep';
import { Card } from '@material-ui/core'
import { CardField, CardHeader, CardContent, FieldsListErrors, PageEntityDetailsRefreshableProps } from 'components'
import { Page, PageType, PageTranslation, Language } from 'app/entities/types'
import { toast } from 'app/utils'
import { default as slugify } from 'slug'
import {
  updatePage, UpdatePageRequestParams,
  getPageTranslation, GetPageTranslationRequestParams, GetPageTranslationResponse,
  updatePageTranslation, UpdatePageTranslationRequestParams,
} from 'app/api'
import { processFieldsErrors } from 'components/_CardPartials/CardField/methods'
import { prepareForSave, shouldSave } from 'components/_CardPartials/CardContent/methods'


enum FieldName {
  Name = 'translationName',
  Keyword = 'pageKeyword',
  Slug = 'translationSlug',
  ParentId = 'pageParentId',
}

type DataProps = {
  pageId: number
  pageType: PageType | null
  translationId: number
  pageParentId: number | null
  translationName: string | null
  pageKeyword: string | null
  translationSlug: string | null
  translationLanguage?: Language | null
}

interface PageDataProps extends PageEntityDetailsRefreshableProps {
  object: Page,
  language: Language,
  pageId: number
  parents?: Page[] | null
  action?: ReactNode
}
const PageData = ({ ...props }: PageDataProps) => {
  const [isLoading, setIsLoading] = useState(true)

  const [translation, setTranslation] = useState<PageTranslation | null>()
  const [options, setOptions] = useState<{ key: string, value: string | number, name: string }[]>([])



  //OBJECT

  const [data, setData] = useState<DataProps>({
    pageId: props.pageId,
    pageType: props.object.pageType ?? null,
    translationId: 0,
    [FieldName.ParentId]: props.object.parentId ?? null,
    [FieldName.Name]: '',
    [FieldName.Keyword]: props.object.keyword,
    [FieldName.Slug]: '',
    translationLanguage: null
  })
  const [currentData, setCurrentData] = useState(data)
  const [isUpdatingCurrentData, setIsUpdatingCurrentData] = useState(false)

  function updateData(data: DataProps) {
    setIsUpdatingCurrentData(true)
    setData(data)
    setCurrentData(data)
  }

  useEffect(() => {
    setIsUpdatingCurrentData(false)
  }, [currentData])

  useEffect(() => {
    let initialized = props.object != null
      && translation != null

    //Check if parents are enabled.
    if (props.parents != undefined) {
      initialized = props.parents != null
    }

    setIsLoading(!initialized)
  }, [props.object, translation, props.parents])



  //ERROR HANDLING

  const errorsConditions: FieldsListErrors = {
    [FieldName.Name]: [
      { error: data.translationName === '', errorMessage: 'Il nome non può essere vuoto' },
    ],
    [FieldName.Keyword]: [
      { error: data.pageKeyword === '', errorMessage: 'La keyword non può essere vuota' },
      { error: data.pageKeyword == null, errorMessage: 'La keyword deve essere definita' },
    ],
    [FieldName.Slug]: [
      { error: data.translationSlug === '', errorMessage: 'Lo slug non può essere vuoto' },
      { error: data.translationSlug == null, errorMessage: 'Lo slug deve essere definito' },
    ],
  }

  const [errors, setErrors] = useState<FieldsListErrors>({})

  useEffect(() => {
    setErrors(processFieldsErrors(FieldName, errorsConditions))
  }, [data])



  //FIELD UPDATES

  function updateFieldValue(name: string, value: any) {
    const temp = clone(data)

    switch (name) {
      case FieldName.Name: temp.translationName = value; break
      case FieldName.Keyword: temp.pageKeyword = slugify(value); break
      case FieldName.Slug: temp.translationSlug = slugify(value); break
      case FieldName.ParentId: temp.pageParentId = parseInt(value); break
      default: break
    }

    setData(temp)
  }

  function confirmFieldValue(name: string, value: any) {
    save(name as FieldName)
  }



  //SAVE

  function save(name?: FieldName) {
    if (shouldSave({ isUpdatingData: isUpdatingCurrentData, fieldName: name, fieldParameters: errorsConditions, data: data, currentData: currentData }) === false) return
    prepareForSave({ fieldName: name, data: data, currentData: currentData, updateDataCallback: setCurrentData })

    let saveFunction: any = null
    switch (name) {
      case FieldName.Name: saveFunction = savePageTranslation; break
      case FieldName.Slug: saveFunction = savePageTranslation; break
      case FieldName.Keyword: saveFunction = savePageObject; break
      case FieldName.ParentId: saveFunction = savePageObject; break
      default: break
    }

    saveFunction!()

    function savePageObject() {
      const encode = (): UpdatePageRequestParams => {
        return {
          id: props.pageId,
          keyword: data.pageKeyword ?? '',
          parentId: data.pageParentId,
          tags: []
        }
      }

      updatePage(encode(), {
        response(data) {
          toast.success('Pagina aggiornata')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    function savePageTranslation() {
      const encode = (): UpdatePageTranslationRequestParams => {
        return {
          id: data.translationId,
          name: data.translationName,
          slug: data.translationSlug,
        }
      }

      updatePageTranslation(encode(), {
        response(data) {
          toast.success('Pagina aggiornata')
          if (props.refreshObject != null) props.refreshObject()
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }
  }



  //PARENTS

  useEffect(() => {
    if (props.parents == null) return
    let opt: any = []
    props.parents.forEach((p, i) => {
      opt.push({ key: i, value: p.id, name: p.translation[0].name })
    })
    setOptions(opt)
  }, [props.parents])



  //TRANSLATION

  useEffect(() => {
    const fetchPageTranslation = () => {
      if (props.object == null) return
      if (props.language == null) return

      const encode = (): GetPageTranslationRequestParams => {
        return {
          pageId: props.pageId,
          languageId: props.language.id,
        }
      }
      const decode = (data: GetPageTranslationResponse): PageTranslation => {
        return {
          id: data.pageTranslation.id,
          name: data.pageTranslation.name,
          slug: data.pageTranslation.slug,
          language: props.language
        }
      }

      setIsLoading(true)
      const params = encode()
      getPageTranslation(params, {
        response(data) {
          const translation = decode(data)
          setTranslation(translation)
          setIsLoading(false)
        },
        error(error, message) {
          toast.error(message)
          setIsLoading(false)
        }
      })
    }

    fetchPageTranslation()
  }, [props.language])

  useEffect(() => {
    if (translation == null) return

    const temp = clone(data)
    temp.translationId = translation.id ?? 0
    temp.translationName = translation.name ?? ''
    temp.translationSlug = translation.slug ?? ''
    temp.translationLanguage = translation.language

    updateData(temp)
  }, [translation])



  //RENDER

  return (
    <>
      <Card>
        <CardHeader title="Dati" />
        <CardContent isLoading={isLoading} >

          <CardField type={'text'} name={FieldName.Name}
            label={'Nome'} placeholder={'Inserisci un nome...'}
            value={data.translationName} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          <CardField type={'text'} name={FieldName.Keyword}
            label={'Keyword'} placeholder={'Inserisci una keyword...'}
            value={data.pageKeyword} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          <CardField type={'text'} name={FieldName.Slug}
            label={'Slug'} placeholder={'Inserisci uno slug...'}
            value={data.translationSlug} errors={errors}
            onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
          />

          {props.parents != undefined &&
            <CardField type={'select'} name={FieldName.ParentId}
              label={'Pagina genitore'} placeholder={'Seleziona una pagina...'}
              value={data.pageParentId ?? ''} errors={errors}
              options={options}
              onUpdate={updateFieldValue} onConfirm={confirmFieldValue}
            />
          }

          {props.action != null && props.action}

        </CardContent>
      </Card>
    </>
  )
}

export default PageData