import { Store, combineReducers, createStore, applyMiddleware } from 'redux'
import { sessionReducer } from 'app/session/reducers'
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
  session: sessionReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default function configureStore(): Store<RootState> {
  return createStore(rootReducer, applyMiddleware(thunk))
}

// import { createStore, Store } from 'redux';
// import { sessionReducer } from 'app/session/reducers';
// import { combineReducers } from 'redux'

// const rootReducer = combineReducers({
//   session: sessionReducer
// })

// export type RootState = ReturnType<typeof rootReducer>

// export default function configureStore(): Store<RootState> {
//   return createStore(rootReducer)
// }


// import { applyMiddleware, createStore, compose } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import rootReducer from 'app/session/reducers';

// export type RootState = ReturnType<typeof rootReducer>

// export default function configureStore(preloadedState = {}) {
//   const middlewares = [thunkMiddleware];
//   const middlewareEnhancer = composeWithDevTools(
//     applyMiddleware(...middlewares)
//   );

//   const enhancers = [middlewareEnhancer];
//   const composedEnhancers = compose(...enhancers);

//   const store = createStore(rootReducer, preloadedState, composedEnhancers);

//   return store;
// }
