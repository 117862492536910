import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from 'views/layouts/Auth'
import ErrorLayout from 'views/layouts/Error'
import MainLayout from 'views/layouts/Main'
import MainWebsiteLayout from 'views/layouts/MainWebsite'
import MainAdminLayout from 'views/layouts/MainAdmin'
import MainDevLayout from 'views/layouts/MainDev'
import InitialView from 'views/screens/core/Dashboard'

const routes = [

  //ROOT

  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />
  },


  //AUTHENTICATION

  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/screens/core/Login'))
      },
      // {
      //   path: '/auth/signup',
      //   exact: true,
      //   component: lazy(() => import('views/screens/Register'))
      // },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('views/screens/core/ResetPassword'))
      },
      {
        path: '/auth/logout',
        exact: true,
        component: lazy(() => import('views/screens/core/Logout'))
      },
      {
        component: () => <Redirect to='/error/404' />
      }
    ]
  },



  //ERRORS

  {
    path: '/error',
    component: ErrorLayout,
    routes: [
      {
        path: '/error/401',
        exact: true,
        component: lazy(() => import('views/screens/errors/Error401'))
      },
      {
        path: '/error/403',
        exact: true,
        component: lazy(() => import('views/screens/errors/Error403'))
      },
      {
        path: '/error/404',
        exact: true,
        component: lazy(() => import('views/screens/errors/Error404'))
      },
      {
        path: '/error/500',
        exact: true,
        component: lazy(() => import('views/screens/errors/Error500'))
      },
      {
        component: () => <Redirect to='/error/404' />
      }
    ]
  },



  //VIEWS

  {
    route: '*',
    component: MainLayout,
    routes: [
      {
        path: '/dashboard',
        exact: true,
        component: InitialView,
      },

      //Website.
      {
        path: '/website',
        component: MainWebsiteLayout,
        routes: [

          //Pages.
          {
            path: '/website/pages',
            exact: true,
            component: lazy(() => import('views/screens/core/Pages')),
          },
          {
            path: '/website/pages/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/PageDetails'))
          },

          //Dictionary.
          {
            path: '/website/dictionary',
            exact: true,
            component: lazy(() => import('views/screens/core/Dictionary'))
          },
          {
            path: '/website/dictionary/:id',
            exact: true,
            component: lazy(() => import('views/screens/core/DictionaryDetails'))
          },

          //Products.
          {
            path: '/website/products',
            exact: true,
            component: lazy(() => import('views/screens/core/Products')),
          },
          {
            path: '/website/products/categories',
            exact: true,
            component: lazy(() => import('views/screens/core/ProductsCategories')),
          },
          {
            path: '/website/products/categories/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ProductsCategoryDetails'))
          },
          {
            path: '/website/products/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ProductDetails'))
          },

          //Stores.
          {
            path: '/website/stores',
            exact: true,
            component: lazy(() => import('views/screens/project/Stores')),
          },
          {
            path: '/website/stores/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/project/StoreDetails'))
          },

          //Brands.
          {
            path: '/website/brands',
            exact: true,
            component: lazy(() => import('views/screens/project/Brands')),
          },
          {
            path: '/website/brands/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/project/BrandDetails'))
          },

          //Companies.
          {
            path: '/website/companies',
            exact: true,
            component: lazy(() => import('views/screens/project/Companies')),
          },
          {
            path: '/website/companies/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/project/CompanyDetails'))
          },

          //Blog.
          {
            path: '/website/blog',
            exact: true,
            component: lazy(() => import('views/screens/core/Articles')),
          },
          {
            path: '/website/blog/categories',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategories')),
          },
          {
            path: '/website/blog/categories/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategoryDetails'))
          },
          {
            path: '/website/blog/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticleDetails'))
          },

          //#iosonovege.
          {
            path: '/website/iosonovege',
            exact: true,
            component: lazy(() => import('views/screens/core/Articles')),
          },
          {
            path: '/website/iosonovege/categories',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategories')),
          },
          {
            path: '/website/iosonovege/categories/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategoryDetails'))
          },
          {
            path: '/website/iosonovege/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticleDetails'))
          },

          //Comunicati stampa.
          {
            path: '/website/press-releases',
            exact: true,
            component: lazy(() => import('views/screens/core/Articles')),
          },
          {
            path: '/website/press-releases/categories',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategories')),
          },
          {
            path: '/website/press-releases/categories/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticlesCategoryDetails'))
          },
          {
            path: '/website/press-releases/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/ArticleDetails'))
          },

          //Documents.
          {
            path: '/website/documents',
            exact: true,
            component: lazy(() => import('views/screens/project/Documents'))
          },

          {
            component: () => <Redirect to='/' />
          }
        ]
      },

      //Admin.
      {
        path: '/admin',
        component: MainAdminLayout,
        routes: [
          {
            path: '/admin/users',
            exact: true,
            component: lazy(() => import('views/screens/core/Users'))
          },
          {
            path: '/admin/users/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/core/UsersDetails'))
          },
          {
            component: () => <Redirect to='/' />
          }
        ]
      },

      //Dev area.
      {
        path: '/dev',
        component: MainDevLayout,
        routes: [
          //Pages content management.
          {
            path: '/dev/pages/:id/manage',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevPageSectionsManager'))
          },

          //Regions.
          {
            path: '/dev/pages/regions',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevRegions'))
          },
          {
            path: '/dev/pages/regions/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevRegionDetails'))
          },

          //Provinces.
          {
            path: '/dev/pages/provinces',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevProvinces'))
          },
          {
            path: '/dev/pages/provinces/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevProvinceDetails'))
          },

          //Cities.
          {
            path: '/dev/pages/cities',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevCities'))
          },
          {
            path: '/dev/pages/cities/:id/:tab?',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevCityDetails'))
          },

          //Medial library folders.
          {
            path: '/dev/media-library/folders',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFolders'))
          },
          {
            path: '/dev/media-library/folders/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFolderDetails'))
          },

          //Nutritional values.
          {
            path: '/dev/products/nutritional-values',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevNutritionalValues'))
          },
          {
            path: '/dev/products/nutritional-values/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevNutritionalValueDetails'))
          },

          //Page types.
          {
            path: '/dev/types/page',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevPageTypes'))
          },
          {
            path: '/dev/types/page/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevPageTypesDetails'))
          },

          //Field Groups.
          {
            path: '/dev/types/field-group',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFieldGroupTypes'))
          },
          {
            path: '/dev/types/field-group/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFieldGroupTypesDetails'))
          },

          //Fields.
          {
            path: '/dev/types/field',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFieldTypes'))
          },
          {
            path: '/dev/types/field/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevFieldTypesDetails'))
          },

          //Tags.
          {
            path: '/dev/tags',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevTags'))
          },
          {
            path: '/dev/tags/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevTagsDetails'))
          },

          //User permissions.
          {
            path: '/dev/users/roles',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevRoles'))
          },
          {
            path: '/dev/users/roles/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevRolesDetails'))
          },

          //User permissions.
          {
            path: '/dev/users/permissions',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevPermissions'))
          },
          {
            path: '/dev/users/permissions/:id',
            exact: true,
            component: lazy(() => import('views/screens/dev/DevPermissionsDetails'))
          },

          {
            component: () => <Redirect to='/' />
          }
        ]
      },


      {
        component: () => <Redirect to='/error/404' />
      }
    ]
  },
]

export default routes
