import spacing from 'theme/config/spacing';
import { Color, FontWeight } from 'theme/style';
import typography from 'theme/config/typography'

export default {
  'root': {
    ...typography.h6,
    height: '36px',
    minHeight: '36px',
    minWidth: '0 !important',
    marginRight: spacing(2),
    padding: spacing(1),
    position: 'relative',
    color: Color.TextSecondary,
    fontWeight: FontWeight.SemiBold,
    borderRadius: '18px'
  },
  'textColorInherit': {
    '&:hover': {
      backgroundColor: '#edeff3',
    },
    '&$selected': {
      color: Color.White,
      backgroundColor: Color.Primary,
      '&:hover': {
        backgroundColor: Color.Primary,
      },
    }
  }
};
