import spacing from 'theme/config/spacing'
import { Value } from 'theme/style'

export default {
  paper: {
    padding: spacing(2),
    borderRadius: Value.BorderRadius,
  },

};
