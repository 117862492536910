import Cookies from 'js-cookie'
import { config } from 'app/config'

const tokenKey = 'token'

export const getToken = () => {
  var token = Cookies.get(tokenKey)
  return token
}

export const setToken = (token: string) => {
  if (token == null || token === '') console.error('Unable to set Token: token doesn\'t exist')

  const expiryDays = config.auth.token.expiryDays

  Cookies.set(tokenKey, token, {
    expires: expiryDays,
    // secure: true,
    sameSite: 'strict'
  })

  console.log('User token set, expires in ' + expiryDays + ' days')
}

export const deleteToken = () => {
  Cookies.remove(tokenKey)
  console.log('User token deleted')
}

export const tokenIsAvailable = () => {
  var token = getToken()
  return token != null
}