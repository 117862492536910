import { UserRoleValue, UserPermissionValue } from 'app/values'
import { User, UserRole } from 'app/entities/types'
import { userPermissionIdentifier } from 'app/entities/methods'

export function userRoleIdentifier(r: UserRole | null): string {
  if (r == null) {
    console.warn('UserRole: inesistente')
    return ''
  }

  if (r.keyword == null) {
    console.warn('UserRole: keyword esistente')
    return ''
  }

  return r.keyword
}

export function userRoleIs(role: UserRole, r: UserRoleValue): boolean {
  return userRoleIdentifier(role) === r
}

export function userRoleHasPermission(r: UserRole, p: UserPermissionValue): boolean {
  if (r.permissions == null) return false
  const permission = r.permissions.filter(permission => (userPermissionIdentifier(permission) === p))
  return permission.length > 0
}

export function userRoleIsDev(r: UserRole | null): boolean {
  if (r == null) return false
  return userRoleIdentifier(r) === UserRoleValue.Dev
}
