import { SessionState } from 'app/session/reducers'
import { PageType } from 'app/entities/types'
import { PageTypeValue } from 'app/values'

export function pageTypeIdentifier(p: PageType | null): string {
  if (p == null) {
    console.warn('PageType: inesistente')
    return ''
  }

  if (p.keyword == null) {
    console.warn('PageType: keyword esistente')
    return ''
  }

  return p.keyword
}

export function pageTypeIsOfType(p: PageType | null, t: PageTypeValue): boolean {
  if (p == null) return false
  return pageTypeIdentifier(p) === t
}

export const getPageTypeFromId = (session: SessionState, id: number | null): PageType | null => {
  if (id == null) return null
  const obj = session.app.pageTypes.filter(t => t.id === id)
  return obj.length > 0 ? obj[0] : null
}

export const getPageTypeFromValue = (session: SessionState, value: PageTypeValue): PageType | null => {
  const obj = session.app.pageTypes.filter(t => pageTypeIdentifier(t) === value)
  return obj.length > 0 ? obj[0] : null
}