import _ from 'lodash'
import { FieldsListErrors } from 'components/_CardPartials/CardField'

//Returns all the corresponding errors in a key-value object.
//Used when verifying the fields status in Cards across the application.
export function processFieldsErrors<T>(types: T, conditions: FieldsListErrors) {
  let obj: FieldsListErrors = {}
  Object.values(types).forEach((type: string) => {
    let errors = conditions[type]
    obj[type] = errors
  })
  return obj
}

//Check if a field has errors.
export function fieldHasError(type: string, conditions: FieldsListErrors): boolean {
  const err = conditions[type]
  const result = _.find(err, 'error')
  return result ? true : false
}

//Check if a list of fields have an error.
export function fieldsHaveError(conditions: FieldsListErrors, names?: string[]): boolean {
  var err = false
  let list = Object.keys(conditions)
  if (names != null) list = names

  list.forEach(n => {
    const result = fieldHasError(n, conditions)
    if (err === false && result) err = true
  })

  return err
}