import { makeStyles, Typography } from '@material-ui/core';
import { FieldTypeValue } from 'app/values'
import { Field, FieldTranslation } from 'app/entities/types';
import { fieldIsOfType } from 'app/entities/methods';
import React, { useEffect, useState } from 'react';
import { FieldInputBoolean, FieldInputDecimal, FieldInputEmail, FieldInputInteger, FieldInputText, FieldInputRichText, FieldInputUrl, FieldInputLibraryItem } from './../';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  input: {
    width: '100%',
    height: '5rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '6px !important'
  },
}))


type FieldInputProps = {
  field: Field
  showName?: boolean
  updateTranslationDataCallback: (data: FieldTranslation) => void
  checkSaveStatusCallback: (field: Field, hasError: boolean) => void
}

const FieldInput = ({ field, showName, updateTranslationDataCallback, checkSaveStatusCallback }: FieldInputProps) => {
  const classes = useStyles()
  const [value, setValue] = useState(field.translation?.value ?? '')

  useEffect(() => {
    updateTranslationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateValue = (value: string, hasError: boolean) => {
    setValue(value)

    //If the value is empty it does not count as an error.
    if (value === '') hasError = false
    checkSaveStatusCallback(field, hasError)
  }

  function handleInputBlur(event: any) {
    updateTranslationData()
  }

  const updateTranslationData = () => {
    if (field.translation == null) return

    const data: FieldTranslation = {
      id: field.translation.id,
      value: value ?? ''
    }
    updateTranslationDataCallback(data)
  }

  const commonFieldProps = () => {
    return {
      className: classes.input,
      value: value,
      updateValueCallback: updateValue,
      onBlur: handleInputBlur
    }
  }

  return (
    <div className={classes.root}>
      {
        showName === true && (<Typography component="h6" variant="h6">
          {field.name}
        </Typography>)
      }
      {fieldIsOfType(field, FieldTypeValue.Text) && (<FieldInputText  {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.RichText) && (<FieldInputRichText  {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.Boolean) && (<FieldInputBoolean {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.Integer) && (<FieldInputInteger {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.Decimal) && (<FieldInputDecimal {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.Email) && (<FieldInputEmail {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.Url) && (<FieldInputUrl {...commonFieldProps()} />)}
      {fieldIsOfType(field, FieldTypeValue.LibraryItem) && (<FieldInputLibraryItem {...commonFieldProps()} />)}
    </div>
  )
}

export default FieldInput
