import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetNutritionalValues = 'api/nutritionals',
  GetNutritionalValueById = 'api/nutritionals/getById',
  CreateNutritionalValue = 'api/nutritionals/create',
  UpdateNutritionalValue = 'api/nutritionals/update',
  DeleteNutritionalValue = 'api/nutritionals/delete',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetNutritionalValuesRequestParams = {
}
export type GetNutritionalValuesResponse = {
  nutritionals: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }[]
}

enum GetNutritionalValuesResponseError {
}

export async function getNutritionalValues(output?: RequestOutput<GetNutritionalValuesResponse, GetNutritionalValuesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetNutritionalValues,
    output: output,
  })
}



export type GetNutritionalValueRequestParams = {
  id: number
}
export type GetNutritionalValueResponse = {
  nutritional: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }
}

enum GetNutritionalValueResponseError {
}

export async function getNutritionalValue(params: GetNutritionalValueRequestParams, output?: RequestOutput<GetNutritionalValueResponse, GetNutritionalValueResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetNutritionalValueById,
    params: params,
    output: output,
  })
}



export type CreateNutritionalValueRequestParams = {
}

type CreateNutritionalValueResponse = {
  nutritional: {
    id: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
  }
}

enum CreateNutritionalValueReponseError {
}

export async function createNutritionalValue(params: CreateNutritionalValueRequestParams, output?: RequestOutput<CreateNutritionalValueResponse, CreateNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateNutritionalValue,
    params: params,
    output: output,
  })
}



export type UpdateNutritionalValueRequestParams = {
  id: number
  keyword: string
  itemOrder: number | null
  minVal: number | null
  maxVal: number | null
  unit: string | null
}

type UpdateNutritionalValueResponse = {
}

enum UpdateNutritionalValueReponseError {
}

export async function updateNutritionalValue(params: UpdateNutritionalValueRequestParams, output?: RequestOutput<UpdateNutritionalValueResponse, UpdateNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateNutritionalValue,
    params: params,
    output: output,
  })
}



export type DeleteNutritionalValueRequestParams = {
  id: number
}

type DeleteNutritionalValueResponse = {
}

enum DeleteNutritionalValueReponseError {
}

export async function deleteNutritionalValue(params: DeleteNutritionalValueRequestParams, output?: RequestOutput<DeleteNutritionalValueResponse, DeleteNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteNutritionalValue,
    params: params,
    output: output,
  })
}
