
export enum Color {

  //Main palette.
  Black = '#000000',
  White = '#FFFFFF',
  Background = '#F4F6F9',
  Primary = '#003592',
  Secondary = '#f83d76',
  Success = '#08b088',
  Warning = '#fdbe1d',
  Error = '#e02813',
  Info = '#2196f3',
  TextPrimary = '#454867',
  TextSecondary = '#707282',
  TextDisabled = '#d4dce4',
  TextHighlight = '#fcdf95',
  Notification = '#ff0000',

  //Elements.
  Sidebar = '#002c6d',
  SidebarDark = '#002762',
  Divider = '#eff1f4'
}

