import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetAllProducts = 'api/products',
  GetAllProductsTaxonomies = 'api/products/getTaxonomies',
  CreateProductTaxonomy = 'api/products/createTaxonomy',
  GetProductById = 'api/products/getById',
  CreateProduct = 'api/products/create',
  UpdateProduct = 'api/products/update',
  DeleteProduct = 'api/products/delete',
  UpdateProductStatus = 'api/products/updatePublished',
  GetProductTranslation = 'api/products/getTranslation',
  UpdateProductTranslation = 'api/products/updateTranslation',
  GetProductPrices = 'api/products/getPrices',
  UpdateProductPrices = 'api/products/updatePrices',
  GetProductStock = 'api/products/getStock',
  UpdateProductStock = 'api/products/updateStock',
  GetProductDimensions = 'api/products/getDimensions',
  UpdateProductDimensions = 'api/products/updateDimensions',
  GetProductNutritionalValues = 'api/products/getNutritionals',
  AddProductNutritionalValue = 'api/products/addNutritional',
  RemoveProductNutritionalValue = 'api/products/removeNutritional',
  UpdateProductNutritionalValue = 'api/products/updateNutritional',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export interface GetAllProductsRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}

export interface GetAllProductsResponse extends APIPaginatableResponse {
  products: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    translation: {
      name: string
      description?: string
    }[]
    taxonomies: {
      id: number
      name: string
      slug: string
    }[]
    sku: string
    prices: {
      price: number
      sellingPrice: number
      unitPrice: number
      wholesalePrice: number
    }
    dimensions: {
      width: number
      height: number
      depth: number
      weight: number
    },
    stock: {
      quantity: number
      outOfStock: boolean
      maximalQuantity: number
      minimalQuantity: number
    }
  }[]
}

enum GetAllProductsResponseError {
}

export async function getAllProducts(params: GetAllProductsRequestParams, output?: RequestOutput<GetAllProductsResponse, GetAllProductsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProducts,
    params: params,
    output: output,
  })
}



export interface GetAllProductsTaxonomiesRequestParams extends APIPaginatable, APISortable, APISearchable {
  languageId: number
}
export interface GetAllProductsTaxonomiesResponse extends APIPaginatableResponse {
  taxonomies: {
    id: number
    keyword: string
    pageId: number
    pageTypeId: number
    parentId: number
    published: boolean
    translation: {
      name: string
    }[]
  }[]
}

enum GetAllProductsTaxonomiesResponseError {
}

export async function getAllProductsTaxonomies(params: GetAllProductsTaxonomiesRequestParams, output?: RequestOutput<GetAllProductsTaxonomiesResponse, GetAllProductsTaxonomiesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllProductsTaxonomies,
    params: params,
    output: output,
  })
}



export type CreateProductTaxonomyRequestParams = {
  parentId: number | null
}

type CreateProductTaxonomyResponse = {
  taxonomy: {
    id: number
    name: string
  }
}

enum CreateProductTaxonomyReponseError {
}

export async function createProductTaxonomy(params: CreateProductTaxonomyRequestParams, output?: RequestOutput<CreateProductTaxonomyResponse, CreateProductTaxonomyReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateProductTaxonomy,
    params: params,
    output: output,
  })
}



export type GetProductRequestParams = {
  id: number
}
export type GetProductResponse = {
  product: {
    id: number
    keyword: string
    published: boolean | null
    pageId: number
    parentId: number | null
    mainImage: {
      id: number
      type: string
      folder: number
      filename: string
      basepath: string
      path: string
    },
    translation: {
      name: string
      description?: string
    }[]
    taxonomies: {
      id: number
      name: string
      slug: string
    }[]
    sku: string
    prices: {
      price: number
      sellingPrice: number
      unitPrice: number
      wholesalePrice: number
    }
    dimensions: {
      width: number
      height: number
      depth: number
      weight: number
    },
    stock: {
      quantity: number
      outOfStock: boolean
      maximalQuantity: number
      minimalQuantity: number
    }
  }
}

enum GetProductResponseError {
}

export async function getProduct(params: GetProductRequestParams, output?: RequestOutput<GetProductResponse, GetProductResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductById,
    params: params,
    output: output,
  })
}



export type CreateProductRequestParams = {
  parentId: number | null
  tags: number[]
}

type CreateProductResponse = {
  product: {
    id: number
    name: string
  }
}

enum CreateProductReponseError {
}

export async function createProduct(params: CreateProductRequestParams, output?: RequestOutput<CreateProductResponse, CreateProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateProduct,
    params: params,
    output: output,
  })
}



export type UpdateProductRequestParams = {
  id: number
  keyword: string
  parentId: number | null
  sku: string
}

type UpdateProductResponse = {
}

enum UpdateProductReponseError {
}

export async function updateProduct(params: UpdateProductRequestParams, output?: RequestOutput<UpdateProductResponse, UpdateProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProduct,
    params: params,
    output: output,
  })
}



export type DeleteProductRequestParams = {
  id: number
}

type DeleteProductResponse = {
}

enum DeleteProductReponseError {
}

export async function deleteProduct(params: DeleteProductRequestParams, output?: RequestOutput<DeleteProductResponse, DeleteProductReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteProduct,
    params: params,
    output: output,
  })
}



export type UpdateProductStatusRequestParams = {
  id: number
  published: boolean,
}

type UpdateProductStatusResponse = {
}

enum UpdateProductStatusReponseError {
}

export async function updateProductStatus(params: UpdateProductStatusRequestParams, output?: RequestOutput<UpdateProductStatusResponse, UpdateProductStatusReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductStatus,
    params: params,
    output: output,
  })
}



export type GetProductTranslationRequestParams = {
  productId: number
  languageId: number
}

export type GetProductTranslationResponse = {
  productTranslation: {
    id: number,
    name: string,
    slug: string,
    description: string,
    ingredients: string,
    benefits: string,
    deepening: string,
    languageId: number
  }
}

enum GetProductTranslationResponseError {
}

export async function getProductTranslation(params: GetProductTranslationRequestParams, output?: RequestOutput<GetProductTranslationResponse, GetProductTranslationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductTranslation,
    params: params,
    output: output,
  })
}



export type UpdateProductTranslationRequestParams = {
  id: number,
  name?: string | null,
  slug?: string | null,
  description?: string | null,
  ingredients?: string | null,
  benefits?: string | null,
  deepening?: string | null,
}

type UpdateProductTranslationResponse = {
}

enum UpdateProductTranslationReponseError {
}

export async function updateProductTranslation(params: UpdateProductTranslationRequestParams, output?: RequestOutput<UpdateProductTranslationResponse, UpdateProductTranslationReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductTranslation,
    params: params,
    output: output,
  })
}



export type GetProductPricesRequestParams = {
  productId: number
}

export type GetProductPricesResponse = {
  productPrices: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductPricesResponseError {
}

export async function getProductPrices(params: GetProductPricesRequestParams, output?: RequestOutput<GetProductPricesResponse, GetProductPricesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductPrices,
    params: params,
    output: output,
  })
}



export type UpdateProductPricesRequestParams = {
  productId: number,
  price: number,
  sellingPrice: number,
  wholesalePrice: number,
  unitPrice: number
  unityCommonStringId: number
}

type UpdateProductPricesResponse = {
}

enum UpdateProductPricesReponseError {
}

export async function updateProductPrices(params: UpdateProductPricesRequestParams, output?: RequestOutput<UpdateProductPricesResponse, UpdateProductPricesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductPrices,
    params: params,
    output: output,
  })
}




export type GetProductStockRequestParams = {
  productId: number
}

export type GetProductStockResponse = {
  productStock: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductStockResponseError {
}

export async function getProductStock(params: GetProductStockRequestParams, output?: RequestOutput<GetProductStockResponse, GetProductStockResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductStock,
    params: params,
    output: output,
  })
}



export type UpdateProductStockRequestParams = {
  productId: number,
  quantity: number,
  minimalQuantity: number,
  maximalQuantity: number,
  outOfStock: boolean
}

type UpdateProductStockResponse = {
}

enum UpdateProductStockReponseError {
}

export async function updateProductStock(params: UpdateProductStockRequestParams, output?: RequestOutput<UpdateProductStockResponse, UpdateProductPricesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductStock,
    params: params,
    output: output,
  })
}




export type GetProductDimensionsRequestParams = {
  productId: number
}

export type GetProductDimensionsResponse = {
  productDimensions: {
    id: number,
    name: string,
    slug: string,
    description: string,
    meta: {
      desktop: {
        metaTitleDesktop: string | null
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    }
    languageId: number
  }
}

enum GetProductDimensionsResponseError {
}

export async function getProductDimensions(params: GetProductDimensionsRequestParams, output?: RequestOutput<GetProductDimensionsResponse, GetProductDimensionsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductDimensions,
    params: params,
    output: output,
  })
}



export type UpdateProductDimensionsRequestParams = {
  productId: number,
  width: number,
  height: number,
  depth: number,
  weight: number
}

type UpdateProductDimensionsResponse = {
}

enum UpdateProductDimensionsReponseError {
}

export async function updateProductDimensions(params: UpdateProductDimensionsRequestParams, output?: RequestOutput<UpdateProductDimensionsResponse, UpdateProductPricesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductDimensions,
    params: params,
    output: output,
  })
}



export type GetProductNutritionalValuesRequestParams = {
  productId: number
}

export type GetProductNutritionalValuesResponse = {
  productNutritional: {
    id: number
    nutritionalId: number
    keyword: string
    itemOrder: number | null
    minVal: number | null
    maxVal: number | null
    unit: string | null
    value: number
  }[]
}

enum GetProductNutritionalValuesReponseError {
}

export async function getProductNutritionalValues(params: GetProductNutritionalValuesRequestParams, output?: RequestOutput<GetProductNutritionalValuesResponse, GetProductNutritionalValuesReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductNutritionalValues,
    params: params,
    output: output,
  })
}




export type AddProductNutritionalValueRequestParams = {
  productId: number
  nutritionalId: number
  value: number
}

type AddProductNutritionalValueResponse = {
  productNutritional: {
    productId: number
    nutritionalId: number
    value: number
  }
}

enum AddProductNutritionalValueReponseError {
}

export async function addProductNutritionalValue(params: AddProductNutritionalValueRequestParams, output?: RequestOutput<AddProductNutritionalValueResponse, AddProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddProductNutritionalValue,
    params: params,
    output: output,
  })
}


//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveProductNutritionalValueRequestParams = {
  productId: number
  nutritionalId: number
}

type RemoveProductNutritionalValueResponse = {
}

enum RemoveProductNutritionalValueReponseError {
}

export async function removeProductNutritionalValue(params: RemoveProductNutritionalValueRequestParams, output?: RequestOutput<RemoveProductNutritionalValueResponse, RemoveProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveProductNutritionalValue,
    params: params,
    output: output,
  })
}



export type UpdateProductNutritionalValueRequestParams = {
  id: number
  value: number
}

type UpdateProductNutritionalValueResponse = {
}

enum UpdateProductNutritionalValueReponseError {
}

export async function updateProductNutritionalValue(params: UpdateProductNutritionalValueRequestParams, output?: RequestOutput<UpdateProductNutritionalValueResponse, UpdateProductNutritionalValueReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductNutritionalValue,
    params: params,
    output: output,
  })
}