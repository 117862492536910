import { UserPermissionValue } from 'app/values'
import UsersIcon from '@material-ui/icons/PeopleAlt';


export default [
  {
    title: 'sidebar.admin',
    pages: [
      {
        title: 'sidebar.admin.users',
        icon: UsersIcon,
        href: '/admin/users',
      },
      // {
      //   title: 'sidebar.admin.settings',
      //   icon: SettingsIcon,
      //   href: '/admin/settings',
      // },
    ]
  },
]