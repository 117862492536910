import React, { useState, useEffect, ReactNode } from 'react'
import { Typography, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  filterDialog: {
    width: '700px',
    maxWidth: '95%'
  },
  filterBox: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}))



//PROPS

type CardListingFilterDialogProps = {
  open: boolean
  onClose: () => void
  filters?: ReactNode[]
  onFilterCallback?: (key: string, value: any) => void
}



//COMPONENT

const CardListingFilterDialog = ({ ...props }: CardListingFilterDialogProps) => {

  //PARAMETERS

  const classes = useStyles()

  const [newFilters, setNewFilters] = useState<ReactNode[]>([])


  useEffect(() => {
    let obj: ReactNode[] = []

    props.filters?.map(f => {
      var clonedElementWithMoreProps = React.cloneElement(
        f as React.ReactElement<any>,
        {
          className: classes.filterBox,
          onFilterCallback: props.onFilterCallback,
        }
      )
      obj.push(clonedElementWithMoreProps)
    })

    setNewFilters(obj)
  }, [props.filters])



  //RENDERING

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} classes={{ paper: classes.filterDialog }}>
        <DialogTitle>
          <Typography component="h2" variant="h2">Applica filtri</Typography>
        </DialogTitle>
        <DialogContent>
          {newFilters.map(f => {
            return f
          })}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CardListingFilterDialog
