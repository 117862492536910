
//Defines all the Field Group Types available in the app.
//IMPORTANT: Must be mapped to each specific field group type keyword to work properly.
export enum FieldGroupTypeValue {
  Button = 'button',
  Image = 'image',
  Select = 'select',
  SelectOption = 'select-option',
  Repeater = 'repeater',
  RepeaterTemplate = 'repeater-template'
}

//Maps the keywords of each child of each Field Group Type available in the app.
//IMPORTANT: Must be mapped to each specific predefined field group type to work properly.
export enum FieldGroupTypeButtonKeywords {
  Text = 'text',
  Target = 'is-blank'
}

export enum FieldGroupTypeSelectOptionKeywords {
  Value = 'value',
  Selected = 'selected'
}