
//Defines all the Field Group Types available in the app.
//IMPORTANT: Must be mapped to each specific field group type keyword to work properly.
export enum PageTypeValue {

  //Default page types.
  Default = 'default',
  Error = 'error',

  //Default utils page types.
  Region = 'region',
  Province = 'province',
  City = 'city',

  //Default entities page types.
  Article = 'article',
  Product = 'product'
}