import React from 'react'
import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core'
import { FontWeight } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.2rem',
    padding: theme.spacing(1),
    fontWeight: FontWeight.Bold,
    borderRadius: '0.6rem'
  },
}))

const CustomTooltip = (props: TooltipProps) => {
  const classes = useStyles()

  return (
    <Tooltip arrow classes={classes} {...props} />
  )
}

export default CustomTooltip
