import { createMuiTheme } from '@material-ui/core'
import palette from './palette'
import typography from './typography'
import spacing from './spacing'
import overrides from 'theme/overrides'
import breakpoints from './breakpoints'
import props from './props'


const theme = createMuiTheme({
  palette,
  typography,
  spacing,
  breakpoints,
  overrides,
  props,
})

export default theme;
