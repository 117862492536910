import React, { ReactNode } from 'react'
import clsx from 'clsx';
import { makeStyles, Typography, Link } from '@material-ui/core'
import { FontWeight, Color } from 'theme/style'
import { Link as RouterLink } from 'react-router-dom'
import { useRouter } from 'app/utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme) => ({
  header: {
    width: 'calc(100% - 280px)',
    backgroundColor: Color.White,
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    boxShadow: '0 8px 12px 0 rgba(0,0,0,0.08)'
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1
  },
  backArrowContainer: {
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    borderRight: '2px solid transparent',
    borderRightColor: '#EDEFF2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backArrow: {
    height: '24px',
    width: '24px',
    color: Color.TextPrimary
  },
  titleContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
  },
  subtitle: {
    fontWeight: FontWeight.Regular,
    color: theme.palette.text.primary
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&>*': {
      marginLeft: theme.spacing(1)
    }
  }
}))


type PageHeaderProps = {
  className?: string
  title: string
  actions?: ReactNode
  previousRoute?: string
}
const PageHeader = ({ className, title, actions, previousRoute }: PageHeaderProps) => {
  const classes = useStyles()
  const router = useRouter()

  return (
    <div className={clsx(className, classes.header)} >
      <div className={classes.leftContent}>
        {previousRoute != undefined &&
          <div className={classes.backArrowContainer} onClick={() => router.history.push(previousRoute)}>
            <ArrowBackIcon className={classes.backArrow} />
          </div>
        }
        <div className={classes.titleContainer}>
          <Typography className={classes.title} component="h1" variant="h3">
            {title}
          </Typography>
        </div>
      </div>

      {actions &&
        <div className={classes.rightContent}>
          {actions}
        </div>
      }
    </div>
  )
}

export default PageHeader
