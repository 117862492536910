
import { LanguageCodeValue } from 'app/values'

export const config = {

  //APP
  app: {
    //The name of the CMS. Used in the page titles and different points in the app.
    name: 'VéGé Backoffice',

    //The default language used for the CMS if a user defined language is not provided.
    defaultLanguageCode: LanguageCodeValue.Italiano,

    //The default date format used.
    defaultDateFormat: 'MMMM Do, YYYY',

    //The default dircetories paths.
    dir: {
      images: {
        avatars: '/images/avatars/'
      }
    },

    //Parameters used to display specific information in the app's status bar.
    statusBar: {
      showName: true,
      showVersionNumber: true,
      showEnvironment: true
    }
  },


  //AUTOMATION
  automation: {
    //Parameters used when triggering automatic saving actions. 
    autosave: {
      delay: 1000
    },
    //Parameters used when triggering automatic search actions. 
    autosearch: {
      delay: 1000
    }
  },


  //API
  api: {
    //The default date format used for transmitting a date object to the backend.
    dateFormat: 'YYYY-MM-DD'
  },



  //AUTH
  auth: {
    token: {
      //The number of days after which the authentication token expires.
      expiryDays: 10
    }
  },



  //PRODUCTS
  products: {
    prices: {
      //The default currency used in the CMS for the products' prices. 
      defaultCurrency: '€'
    },
    dimensions: {
      //The default measurements unit used in the CMS for the products' dimensions.
      defaultWidthUnit: 'cm',
      defaultHeightUnit: 'cm',
      defaultDepthUnit: 'cm',
      defaultWeightUnit: 'kg',
    }
  },


}