import palette from 'theme/config/palette'
import typography from 'theme/config/typography'

export default {
  root: {
    ...typography.h6,
  },
  colorDefault: {
    backgroundColor: palette.primary.main
  }
}
