import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PageMetatags, PageHeader, PageContent, Spinner } from 'components';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { userHasAccessToRoute } from 'app/entities/methods';
import { Redirect, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'fixed',
    top: 0,
    zIndex: 1
  },
  contentDefault: {
    padding: theme.spacing(3),
    marginTop: '50px',
  },
  contentFullScreen: {
    width: '100%',
    padding: 0
  }
}))


export type PageProps = {
  isLoading?: boolean
  className?: string
  title: string
  fullScreenLayout?: boolean
  parameters?: { [key: string]: string }
  previousRoute?: string
  currentRoute?: string
  actions?: ReactNode
  children?: ReactNode
}

const Page = ({ ...props }: PageProps) => {
  const classes = useStyles()
  const session = useSelector((state: RootState) => state.session)
  const location = useLocation();

  const defaultFullScreenLayoutValue = false
  const fullScreenLayoutValue = props.fullScreenLayout ?? defaultFullScreenLayoutValue

  if (userHasAccessToRoute(session.user, location.pathname) === false) {
    return <Redirect to={'/error/403'} />
  }

  if (props.isLoading === true) {
    return (
      <div className={classes.loading} >
        <Spinner />
      </div>
    )
  }

  return (
    <div className={props.className}>
      <PageMetatags title={props.title} parameters={props.parameters} />
      {fullScreenLayoutValue === false && <PageHeader title={props.title} className={classes.header} previousRoute={props.previousRoute} actions={props.actions} />}
      <PageContent className={fullScreenLayoutValue === false
        ? classes.contentDefault
        : classes.contentFullScreen}
      >
        {props.children}
      </PageContent>
    </div>
  )
}

export default Page
