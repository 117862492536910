import palette from 'theme/config/palette';
import typography from 'theme/config/typography';

export default {
  root: {},
  input: {
    ...typography.body1,
    color: palette.text.primary,
    height: '45px',
    lineHeight: '42px',
    padding: '0px 15px',
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary,
    },
  },
  notchedOutline: {
    borderColor: '#e3e4e8',
  },
};
