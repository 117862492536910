import { Card, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Switch, Tab, Tabs, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  getContents, GetContentsRequestParams, GetContentsResponse,
  addFieldGroupRepeaterRow, AddFieldGroupRepeaterRowRequestParams,
  deleteFieldGroup, DeleteFieldGroupRequestParams
} from 'app/api';
import { FieldGroupTypeValue, FieldTypeValue, FieldGroupTypeButtonKeywords, FieldGroupTypeSelectOptionKeywords } from 'app/values'
import { Field, FieldGroup, Language, Section, SectionContent } from 'app/entities/types';
import { toast } from 'app/utils';
import { fieldIsOfType, isFieldGroup } from 'app/entities/methods';
import { fieldGroupIsEditable, fieldGroupIsOfType } from 'app/entities/methods';
import clsx from 'clsx';
import { CardContent, CardHeader, Tooltip } from 'components';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Color } from 'theme/style';
import { EditableItem } from './../../types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Value } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '200px',
    width: '100%'
  },
  sectionRoot: {
    flexGrow: 1,
    display: 'flex',
  },
  contentTabs: {
    borderRight: '2px solid',
    borderRightColor: theme.palette.divider,
    paddingRight: theme.spacing(1.5),
    width: '200px',
    minWidth: '200px',
    maxWidth: '200px',
  },
  contentTabRoot: {
    height: '40px',
    borderRadius: '1.3rem',
    minHeight: '100%',
    marginRight: 0,
    backgroundColor: 'transparent !important',
    color: theme.palette.text.secondary + ' !important',
    '&:after': {
      display: 'none'
    },
  },
  contentTabSelected: {
    color: theme.palette.text.primary + ' !important',
    backgroundColor: 'transparent !important',
  },
  contentTabWrapper: {
    alignItems: 'flex-start'
  },
  resultIcon: {
    ...theme.typography.overline,
    textAlign: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '40%',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  resultTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  sectionBox: {
  },
  contentBox: {
    padding: theme.spacing(0, 2),
    paddingRight: 0,
    width: '100%'
  },
  fieldGroupBox: {
    marginBottom: theme.spacing(1),
    '& >li:hover': {
      borderRadius: Value.BorderRadius,
      backgroundColor: theme.palette.background.default
    }
  },
  fieldGroupBoxIndent: {
    // borderLeft: '2px solid',
    // borderLeftColor: theme.palette.divider,
    marginLeft: theme.spacing(2.4),
    marginBottom: theme.spacing(1)
  },
  fieldBox: {
    marginLeft: theme.spacing(2.4),
    // borderLeft: '2px solid',
    // borderLeftColor: theme.palette.divider,
    '& >li:hover': {
      borderRadius: Value.BorderRadius,
      backgroundColor: theme.palette.background.default
    }
  },
  row: {
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: 0,
    marginTop: 0
  },
  rowActions: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 0,
  },
  rowActionsEdit: {
    marginLeft: theme.spacing(0.3)
  },
  rowDataContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  rowDataTitle: {
    position: 'relative',
    top: 2
  },
  rowDataDetail: {
    position: 'relative',
    top: -2
  }
}))

enum ResultsIconType {
  FieldGroup,
  Field
}
type ResultsIconProps = {
  type: ResultsIconType
}

const ResultIcon = ({ type }: ResultsIconProps) => {
  const classes = useStyles()

  let color = ''
  let text = ''

  switch (type) {
    case ResultsIconType.FieldGroup:
      color = Color.Secondary
      text = 'G'
      break

    case ResultsIconType.Field:
      color = Color.Info
      text = 'F'
      break

    default:
      break
  }
  return (
    <div className={classes.resultIcon} style={{ backgroundColor: color }}>
      {text}
    </div >
  )
}


type SectionBlockProps = {
  section: Section,
  language: Language,
  selectedItemCallback: (item: EditableItem, section: Section) => void
  updateSectionsStatusCallback: (section: Section, isOpen: boolean, trigger: () => void) => void
}

const SectionBlock = ({ section, language, updateSectionsStatusCallback, selectedItemCallback }: SectionBlockProps) => {
  const classes = useStyles()

  const [isFetching, setIsFetching] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const [contentData, setContentData] = useState<SectionContent[] | null>(null)
  const [tabValue, setTabValue] = React.useState(0)

  useEffect(() => {
    updateSectionsStatusCallback(section, collapsed, fetchContents)
  }, [section, collapsed, language])

  useEffect(() => {
    if (collapsed === false) return
    fetchContents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  const fetchContents = () => {
    const encode = (): GetContentsRequestParams => {
      return {
        id: section.id,
        languageId: language.id,
      }
    }

    const decode = (data: GetContentsResponse): SectionContent[] => {
      return data.contents
    }

    setIsFetching(true)
    getContents(encode(), {
      response(data) {
        const contents = decode(data)
        setContentData(contents)
        setIsFetching(false)
        setCollapsed(true)
      },
      error(error, message) {
        toast.error(message)
        setIsFetching(false)
      }
    })
  }

  const handleCollapse = () => {
    if (collapsed === true) {
      setCollapsed(false)
      return
    }

    setTabValue(0)
    fetchContents()
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setTabValue(value)
  }

  let sectionTitle = section.name
  if (section.global === true) {
    sectionTitle = sectionTitle + ' (globale)'
  }
  else if (section.shared === true) {
    sectionTitle = sectionTitle + ' (condivisa)'
  }

  return (
    <Card>
      <CardHeader title={sectionTitle} iconName={section.shared ? 'share' : 'default'}
        enableLoadingSpinner isLoading={isFetching}
        action={
          <>
            <IconButton edge="end" onClick={handleCollapse}> {collapsed ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </>
        } />
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <CardContent className={classes.sectionRoot}>
          {contentData != null && contentData?.length > 1 &&
            <Tabs className={classes.contentTabs} value={tabValue} onChange={handleTabChange} variant="standard" orientation="vertical" scrollButtons="off">
              {contentData?.map((content, i) => (
                <Tab classes={{ root: classes.contentTabRoot, selected: classes.contentTabSelected, wrapper: classes.contentTabWrapper }} key={i} label={content.name} value={i} />
              ))}
            </Tabs>
          }
          {contentData?.map((content, i) => (
            <ContentBlock key={i} section={section} content={content} value={tabValue} index={i} selectedItemCallback={selectedItemCallback} reloadSection={fetchContents} />
          ))}
        </CardContent>
      </Collapse>
    </Card >
  )
}

export default SectionBlock



//CONTENT.

type ContentBlockProps = {
  section: Section,
  content: SectionContent
  index: any;
  value: any;
  selectedItemCallback: (item: EditableItem, section: Section) => void
  reloadSection: () => void
}

const ContentBlock = ({ section, content, index, value, selectedItemCallback, reloadSection }: ContentBlockProps) => {
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState(true)

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  }

  return <div className={classes.contentBox} hidden={value !== index}>
    {value === index && (
      <>
        <List>
          {
            content.fieldGroups?.map((fieldGroup, i) => (
              <FieldGroupBlock key={i} section={section} fieldGroup={fieldGroup} selectedItemCallback={selectedItemCallback} reloadSection={reloadSection} />
            ))
          }
        </List>
      </>
    )}
  </div >
}




//FIELD GROUP.

type FieldGroupBlockProps = {
  section: Section,
  fieldGroup: FieldGroup
  parentFieldGroup?: FieldGroup
  shouldEditDefault?: boolean
  selectedItemCallback: (item: EditableItem, section: Section) => void
  reloadSection: () => void
}
type FieldGroupChild = Field | FieldGroup

const FieldGroupBlock = ({ section, fieldGroup, parentFieldGroup, selectedItemCallback, reloadSection }: FieldGroupBlockProps) => {
  const classes = useStyles()


  //Handle collapse
  const [collapsed, setCollapsed] = useState(true)
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  }


  //Handle repeater rows
  function addNewRepeaterRow(fieldGroup: FieldGroup) {
    const encode = (): AddFieldGroupRepeaterRowRequestParams => {
      return {
        id: fieldGroup.id
      }
    }

    addFieldGroupRepeaterRow(encode(), {
      response(data) {
        toast.success('Valore aggiunto')
        reloadSection()
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }

  function deleteRepeaterRow(fieldGroup: FieldGroup) {
    const encode = (): DeleteFieldGroupRequestParams => {
      return {
        id: fieldGroup.id
      }
    }

    deleteFieldGroup(encode(), {
      response(data) {
        toast.success('Valore eliminato')
        reloadSection()
      },
      error(error, message) {
        toast.error(message)
      }
    })
  }


  const nodeValue = () => {
    if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Button)) {
      let value = ''
      fieldGroup.fields.map(f => {
        if (f.keyword === FieldGroupTypeButtonKeywords.Text) value = f.translation?.value ?? ''
        if (f.keyword === FieldGroupTypeButtonKeywords.Target) value += f.translation?.value === '1' ? ' (external)' : ''
      })
      return <Typography component="p" variant="body1">{value}</Typography>
    }

    if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Select)) {
      let value = ''
      fieldGroup.childs.map(f => {
        const selected = f.fields.filter(f => f.keyword === FieldGroupTypeSelectOptionKeywords.Selected)
        if (selected.length > 0) {
          if (selected[0].translation?.value === '1') value = f.name
        }
      })
      return <Typography component="p" variant="body1">{value}</Typography>
    }

    else if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Image)) {
      let value = ''
      return <Typography component="p" variant="body1">{value}</Typography>
    }

    return <></>
  }

  let fieldGroupInfo = {
    iconType: ResultsIconType.FieldGroup,
    valueNode: nodeValue()
  }

  const sortChilds = () => {
    const fields = fieldGroup.fields
    const fieldGroups = fieldGroup.childs

    if (fields == undefined && fieldGroups == undefined) return []
    if (fieldGroups == undefined) return fields
    if (fields == undefined) return fieldGroups

    let list: FieldGroupChild[] = [...fields, ...fieldGroups]
    list = sortBy(list, [function (i) { return i.itemOrder }])
    return list
  }

  const renderChild = (item: FieldGroupChild, parentItem: FieldGroup, index: number) => {
    if (isFieldGroup(item)) {
      const fieldGroup = item as unknown as FieldGroup
      return <FieldGroupBlock key={index} section={section} fieldGroup={fieldGroup} parentFieldGroup={parentItem} selectedItemCallback={selectedItemCallback} reloadSection={reloadSection} />
    }
    else {
      const field = item as unknown as Field
      return <FieldBlock key={index} section={section} field={field} selectedItemCallback={selectedItemCallback} />
    }
  }

  function shouldAddDeleteButton() {
    if (parentFieldGroup == null) return false
    if (fieldGroupIsOfType(parentFieldGroup, FieldGroupTypeValue.Repeater) === true) {
      return true
    }
    return false
  }

  const className = fieldGroup.parentId == null ? classes.fieldGroupBox : clsx(classes.fieldGroupBox, classes.fieldGroupBoxIndent)

  //Hide repeater template types to users.
  if (fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.RepeaterTemplate)) return <></>

  return <div className={className}>
    <ListItem>
      <ListItemText className={classes.row}
        primary={
          <div className={classes.resultTitleContainer}>
            <ResultIcon type={fieldGroupInfo.iconType}></ResultIcon>
            <div className={classes.rowDataContainer}>
              <Typography component="h5" variant="h5">{fieldGroup.name}</Typography>
            </div>
          </div>
        } />
      <ListItemSecondaryAction className={classes.rowActions}>
        {fieldGroupIsEditable(fieldGroup) === true && (
          <>
            {fieldGroupInfo.valueNode}
            <Tooltip title={'Modifica valore'} placement="top-end">
              <IconButton className={classes.rowActionsEdit} edge="end" onClick={() => selectedItemCallback(fieldGroup, section)}><EditIcon /></IconButton>
            </Tooltip>
          </>
        )}

        {fieldGroupIsOfType(fieldGroup, FieldGroupTypeValue.Repeater) === true && (
          <Tooltip title={'Aggiungi nuovo "' + fieldGroup.childs[0]?.name ?? 'elemento' + '"'} placement="top-end">
            <IconButton onClick={e => addNewRepeaterRow(fieldGroup)}><AddIcon /></IconButton>
          </Tooltip>
        )}

        {shouldAddDeleteButton() === true && (
          <Tooltip title={'Elimina "' + fieldGroup.name + '"'} placement="top-end">
            <IconButton onClick={e => deleteRepeaterRow(fieldGroup)}><RemoveIcon /></IconButton>
          </Tooltip>

        )}

        {fieldGroupIsEditable(fieldGroup) === false && (
          <IconButton edge="end" onClick={handleCollapse}> {collapsed ? <ExpandLess /> : <ExpandMore />}</IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
    {fieldGroupIsEditable(fieldGroup) === false && (
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <List>
          {
            sortChilds().map((child, i) => (
              renderChild(child, fieldGroup, i)
            ))
          }
        </List>
      </Collapse>
    )}
  </div>
}




//FIELD.

type FieldBlockProps = {
  section: Section,
  field: Field
  selectedItemCallback: (item: EditableItem, section: Section) => void
}

const FieldBlock = ({ section, field, selectedItemCallback }: FieldBlockProps) => {
  const classes = useStyles()

  let fieldInfo = {
    iconType: ResultsIconType.Field,
    valueNode: <></>
  }

  const textValueNode = (value?: string) => {
    if (value == null) value = field.translation?.value
    if (value == null) value = ''
    return <Typography component="p" variant="body1">{value}</Typography>
  }

  const richTextValueNode = (value?: string) => {
    if (value == null) value = field.translation?.value
    if (value == null) value = ''

    value = value.replace(/<(.|\n)*?>/g, ' ')

    if (value.length > 50) {
      value = value.substring(0, 50)
      value = value + '...'
    }

    return <Typography component="p" variant="body1">{value}</Typography>
  }

  const switchValueNode = (value?: string) => {
    if (value == null) value = field.translation?.value
    if (value == null) value = '0'
    return <Switch size="small" checked={value === '0' ? false : true} />
  }

  if (fieldIsOfType(field, FieldTypeValue.Text)) fieldInfo.valueNode = textValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.RichText)) fieldInfo.valueNode = richTextValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Boolean)) fieldInfo.valueNode = switchValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Integer)) fieldInfo.valueNode = textValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Decimal)) fieldInfo.valueNode = textValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Email)) fieldInfo.valueNode = textValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Url)) fieldInfo.valueNode = textValueNode()
  else if (fieldIsOfType(field, FieldTypeValue.Date)) fieldInfo.valueNode = textValueNode('Date value')
  else if (fieldIsOfType(field, FieldTypeValue.LibraryItem)) fieldInfo.valueNode = textValueNode()

  return <div className={classes.fieldBox}>
    <ListItem>
      <ListItemText className={classes.row}
        primary={
          <div className={classes.resultTitleContainer}>
            <ResultIcon type={fieldInfo.iconType}></ResultIcon>
            <div className={classes.rowDataContainer}>
              <Typography component="h5" variant="h5">{field.name}</Typography>
            </div>
          </div>
        } />
      <ListItemSecondaryAction className={classes.rowActions}>
        {fieldInfo.valueNode}
        <Tooltip title={'Modifica valore'} placement="top-end">
          <IconButton className={classes.rowActionsEdit} edge="end" onClick={() => selectedItemCallback(field, section)}><EditIcon /></IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  </div >
}