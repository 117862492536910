import React, { useEffect } from 'react'
import { Select, SelectProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  input: {
    textAlign: 'left',
    padding: theme.spacing(0, 1.5),
    height: '45px',
    backgroundColor: Color.Background,
    // border: '2px solid transparent',
    // borderColor: '#E8E8E8',
    borderRadius: Value.BorderRadius_Field,
    '& >div': {
      width: '100% !important',
      paddingLeft: 0
    },
    '& >svg': {
      right: '10px'
    }
  },
  adornmentText: {
    color: Color.Primary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem'
  }
}))

interface SelectFieldProps extends SelectProps {
  align?: string
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const SelectField = ({ ...props }: SelectFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const value = target.value

    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }



  //RENDER

  return (
    <Select {...props} disableUnderline className={classes.input} onChange={updateField}>
      {props.children}
    </Select>
  )
}

export default SelectField
