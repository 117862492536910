import { UserPermissionValue } from 'app/values'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PageIcon from '@material-ui/icons/Description';
import CompanyIcon from '@material-ui/icons/LocationCity';
import PolicyIcon from '@material-ui/icons/Policy';
import CommonTextIcon from '@material-ui/icons/TextFields';
import UsersIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Tune';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CreateIcon from '@material-ui/icons/Create';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CopyrightIcon from '@material-ui/icons/Copyright';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';

export default [
  // {
  //   title: '',
  //   pages: [
  //     {
  //       title: 'sidebar.dashboard',
  //       href: '/dashboard',
  //       icon: DashboardIcon,
  //     },
  //   ]
  // },
  {
    title: 'sidebar.website',
    pages: [
      {
        title: 'sidebar.general.pages',
        icon: PageIcon,
        href: '/website/pages',
      },
      {
        title: 'sidebar.general.content',
        icon: CommonTextIcon,
        href: '/website/dictionary',
      },
      {
        title: 'sidebar.general.products',
        icon: ShoppingBasketIcon,
        href: '/website/products',
        children: [
          {
            title: 'sidebar.general.products.list',
            href: '/website/products',
          },
          {
            title: 'sidebar.general.products.categories',
            href: '/website/products/categories',
          },
        ]
      },
      {
        title: 'sidebar.general.stores',
        icon: StorefrontIcon,
        href: '/website/stores',
      },
      {
        title: 'sidebar.general.brands',
        icon: CopyrightIcon,
        href: '/website/brands',
      },
      {
        title: 'sidebar.general.companies',
        icon: CompanyIcon,
        href: '/website/companies',
      },
      {
        title: 'sidebar.general.blog',
        icon: CreateIcon,
        href: '/website/blog',
        children: [
          {
            title: 'sidebar.general.blog.list',
            href: '/website/blog',
          },
          {
            title: 'sidebar.general.blog.categories',
            href: '/website/blog/categories',
          },
        ]
      },
      {
        title: 'sidebar.general.iosonovege',
        icon: CreateIcon,
        href: '/website/iosonovege',
        children: [
          {
            title: 'sidebar.general.iosonovege.list',
            href: '/website/iosonovege',
          },
          {
            title: 'sidebar.general.iosonovege.categories',
            href: '/website/iosonovege/categories',
          },
        ]
      },
      {
        title: 'sidebar.general.pressReleases',
        icon: CreateIcon,
        href: '/website/press-releases',
        children: [
          {
            title: 'sidebar.general.pressReleases.list',
            href: '/website/press-releases',
          },
          {
            title: 'sidebar.general.pressReleases.categories',
            href: '/website/press-releases/categories',
          },
        ]
      },
      {
        title: 'sidebar.general.documents',
        icon: PageIcon,
        href: '/website/documents',
      },

      // {
      //   title: 'sidebar.general.company',
      //   icon: CompanyIcon,
      //   href: '/website/company',
      // },
      // {
      //   title: 'sidebar.general.legal',
      //   icon: PolicyIcon,
      //   href: '/website/legal',
      // },
    ]
  },


  //               title: 'Content',
  //       icon: ListAltIcon,
  //       children: [
  //         {
  //           title: 'Pages',
  //           href: '/pages',
  //         },
  //         {
  //           title: 'Translations',
  //           href: '/translations',
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Languages',
  //       href: '/languages',
  //       icon: LanguageIcon,
  //     },
  //   ]
  // },


  // {
  //   title: 'Pages',
  //   pages: [
  //     {
  //       title: 'Overview',
  //       href: '/overview',
  //       icon: HomeIcon
  //     },
  //     {
  //       title: 'Dashboards',
  //       href: '/dashboards',
  //       icon: DashboardIcon,
  //       children: [
  //         {
  //           title: 'Default',
  //           href: '/dashboards/default'
  //         },
  //         {
  //           title: 'Analytics',
  //           href: '/dashboards/analytics'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Management',
  //       href: '/management',
  //       icon: BarChartIcon,
  //       children: [
  //         {
  //           title: 'Customers',
  //           href: '/management/customers'
  //         },
  //         {
  //           title: 'Customer Details',
  //           href: '/management/customers/1/summary'
  //         },
  //         {
  //           title: 'Projects',
  //           href: '/management/projects'
  //         },
  //         {
  //           title: 'Orders',
  //           href: '/management/orders'
  //         },
  //         {
  //           title: 'Order Details',
  //           href: '/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Feed',
  //       href: '/social-feed',
  //       icon: PeopleIcon
  //     },
  //     {
  //       title: 'Profile',
  //       href: '/profile',
  //       icon: PersonIcon,
  //       children: [
  //         {
  //           title: 'Timeline',
  //           href: '/profile/1/timeline'
  //         },
  //         {
  //           title: 'Connections',
  //           href: '/profile/1/connections'
  //         },
  //         {
  //           title: 'Projects',
  //           href: '/profile/1/projects'
  //         },
  //         {
  //           title: 'Reviews',
  //           href: '/profile/1/reviews'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Project',
  //       href: '/projects',
  //       icon: FolderIcon,
  //       children: [
  //         {
  //           title: 'Browse',
  //           href: '/projects'
  //         },
  //         {
  //           title: 'Create',
  //           href: '/projects/create'
  //         },
  //         {
  //           title: 'Overview',
  //           href: '/projects/1/overview'
  //         },
  //         {
  //           title: 'Files',
  //           href: '/projects/1/files'
  //         },
  //         {
  //           title: 'Activity',
  //           href: '/projects/1/activity'
  //         },
  //         {
  //           title: 'Subscribers',
  //           href: '/projects/1/subscribers'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoice',
  //       href: '/invoices/1',
  //       icon: ReceiptIcon
  //     },
  //     {
  //       title: 'Kanban Board',
  //       href: '/kanban-board',
  //       icon: ListAltIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/mail',
  //       icon: MailIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           2
  //         </Label>
  //       )
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/chat',
  //       icon: ChatIcon,
  //       label: () => (
  //         <Label
  //           color={colors.red[500]}
  //           shape="rounded"
  //         >
  //           4
  //         </Label>
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/calendar',
  //       icon: CalendarTodayIcon,
  //       label: () => <Label color={colors.green[500]}>New</Label>
  //     },
  //     {
  //       title: 'Settings',
  //       href: '/settings',
  //       icon: SettingsIcon,
  //       children: [
  //         {
  //           title: 'General',
  //           href: '/settings/general'
  //         },
  //         {
  //           title: 'Subscription',
  //           href: '/settings/subscription'
  //         },
  //         {
  //           title: 'Notifications',
  //           href: '/settings/notifications'
  //         },
  //         {
  //           title: 'Security',
  //           href: '/settings/security'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Authentication',
  //       href: '/auth',
  //       icon: LockOpenIcon,
  //       children: [
  //         {
  //           title: 'Login',
  //           href: '/auth/login'
  //         },
  //         {
  //           title: 'Register',
  //           href: '/auth/register'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Errors',
  //       href: '/errors',
  //       icon: ErrorIcon,
  //       children: [
  //         {
  //           title: 'Error 401',
  //           href: '/errors/error-401'
  //         },
  //         {
  //           title: 'Error 404',
  //           href: '/errors/error-404'
  //         },
  //         {
  //           title: 'Error 500',
  //           href: '/errors/error-500'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Support',
  //   pages: [
  //     {
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon
  //     },
  //     {
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon
  //     },
  //     {
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ViewModuleIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.2.0</Label>
  //     }
  //   ]
  // }
]