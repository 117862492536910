
export enum FontSize {
  Headline1 = '3.0rem',
  Headline2 = '2.3rem',
  Headline3 = '2.0rem',
  Headline4 = '1.7rem',
  Headline5 = '1.4rem',
  Headline6 = '1.2rem',
  Subtitle1 = '1.6rem',
  Subtitle2 = '1.4rem',
  Body1 = '1.3rem',
  Body2 = '1.2rem',
  Cell = '1.3rem',
  Button = '1.2rem',
  Caption = '1.2rem',
  Overline = '1.2rem',
}

export enum FontWeight {
  UltraLight = 100,
  Thin = 200,
  Light = 300,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  Heavy = 800,
  Black = 900,
}

export enum FontFamily {
  Title = 'Circular, sans-serif',
  Subtitle = 'Circular, sans-serif',
  Body = 'Circular, sans-serif',
}