import React from 'react'
import { Page, PageHeader } from 'components'
import { useLocalization } from 'components'

const Dashboard = () => {
  const { t } = useLocalization()

  return (
    <Page fullScreenLayout title={'Dashboard'}>
    </Page>
  )
}

export default Dashboard
