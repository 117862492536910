import React, { useEffect } from 'react'
import { Input, InputBaseProps, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
  },
  input: {
    flex: 1,
    '& input': {
      textAlign: 'left',
      padding: theme.spacing(0, 1.5),
      height: '45px',
      backgroundColor: Color.Background,
      borderRadius: Value.BorderRadius_Field,
    },
  },
  button: {
    flex: '0 0 100px',
    marginLeft: theme.spacing(1)
  }
}))

interface ButtonFieldProps extends InputBaseProps {
  onClick?: () => void
  onRemove?: () => void
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const ButtonField = ({ ...props }: ButtonFieldProps) => {
  const classes = useStyles()



  //CLICK

  function click() {
    if (props.onClick != null) props.onClick()
  }

  function disabledClick() {
  }

  function remove() {
    if (props.onRemove != null) props.onRemove()
  }



  //RENDER

  return (
    <div className={classes.buttonContainer}>
      <Input {...props} disabled className={classes.input} onClick={disabledClick}></Input>
      <Button className={classes.button} onClick={click}>Modifica</Button>
      <Button className={classes.button} onClick={remove}>Rimuovi</Button>
    </div>
  )
}

export default ButtonField