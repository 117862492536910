import { Card, Chip, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  addTagToPage, AddTagToPageRequestParams, getTags, GetTagsResponse,
  removeTagFromPage, RemoveTagFromPageRequestParams
} from 'app/api';
import { useLocalization } from 'components';
import { Page, Tag } from 'app/entities/types';
import { toast } from 'app/utils';
import clsx from 'clsx';
import { CardContent, CardHeader } from 'components';
import difference from 'lodash/difference';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  tagsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tag: {
    margin: theme.spacing(0.5),
  },
}))

type TagsProps = {
  className?: string,
  object: Page,
}

enum InputType {
  Tag,
}

enum UpdateTagAction {
  None = '',
  Add = 'add',
  Remove = 'remove'
}

const Tags = ({ className, object, ...rest }: TagsProps) => {
  const classes = useStyles()
  const { t } = useLocalization()

  const [isFetchingTags, setIsFetchingTags] = useState(false)
  const [tagsList, setTagsList] = useState<Tag[] | undefined>([])
  const [activeTags, setActiveTags] = useState<Tag[] | undefined>([])


  useEffect(() => {
    fetchTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setActiveTags(object.tags)
  }, [object])

  const fetchTags = () => {
    const decode = (data: GetTagsResponse): Tag[] => {
      return data.tags
    }

    setIsFetchingTags(true)
    getTags({
      response(data) {
        const tags = decode(data)
        setTagsList(tags)
        setIsFetchingTags(false)
      },
      error(error, message) {
        toast.error(message)
        setIsFetchingTags(false)
      }
    })
  }

  const handleInputChange = (inputType: InputType, value: Tag[], event: any) => {
    if (activeTags == null) return

    const initialCount = activeTags.length
    const nextCount = value.length
    let tagsDiff: Tag[] = []
    let action: UpdateTagAction = UpdateTagAction.None

    if (initialCount > nextCount) {
      action = UpdateTagAction.Remove
      tagsDiff = difference(activeTags, value)
    }
    else if (initialCount < nextCount) {
      action = UpdateTagAction.Add
      tagsDiff = difference(value, activeTags)
    }

    if (tagsDiff.length > 1) return
    const tag = tagsDiff[0]

    switch (inputType) {
      case InputType.Tag: {
        setActiveTags(value)
        updateTags(tag, action)
        break
      }
    }
  }

  function updateTags(tag: Tag, action: UpdateTagAction) {
    if (object == null) return

    const addTag = (tag: Tag) => {
      const params: AddTagToPageRequestParams = {
        pageId: object.id,
        tagId: tag.id
      }

      addTagToPage(params, {
        response(data) {
          toast.success(t('screen.pageDetails.alert.success.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    const removeTag = (tag: Tag) => {
      const params: RemoveTagFromPageRequestParams = {
        pageId: object.id,
        tagId: tag.id
      }

      removeTagFromPage(params, {
        response(data) {
          toast.success(t('screen.pageDetails.alert.success.update'))
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    switch (action) {
      case UpdateTagAction.Add:
        addTag(tag)
        break

      case UpdateTagAction.Remove:
        removeTag(tag)
        break

      default:
        break
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t('screen.pageDetails.tags.title')} iconName="tags" isLoading={isFetchingTags} />
      <CardContent isLoading={isFetchingTags}>
        <Autocomplete multiple filterSelectedOptions options={tagsList ?? []} value={activeTags} getOptionLabel={(option) => option.name}
          renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (<Chip label={option.name} {...getTagProps({ index })} />))}
          renderInput={(params) => <TextField {...params} placeholder={t('screen.pageDetails.tags.input.placeholder')} />}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(e, value) => handleInputChange(InputType.Tag, value, e)}
        />
      </CardContent>
    </Card>
  )
}

export default Tags
