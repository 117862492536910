import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { LanguageFlag } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: '20px',
  },
  flag: {
    display: 'flex',
    width: '30px',
    height: '30px',
  },
}));

const LanguageCard = ({language, className, ...rest}) => {

  //PROPS.

  const classes = useStyles();


  //RENDER.

  return (
    <Card {...rest} className={clsx(classes.root, className)} >
      <CardHeader
        avatar={<LanguageFlag className={classes.flag} key={language.locale} locale={language.locale} />}
        className={classes.header}
        title={<Typography variant="h5">{language.name}</Typography>}
      />
    </Card>
  );
};


//TYPES.

LanguageCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default LanguageCard;
