import { FieldTypeValue } from 'app/values'
import { FieldGroup, Field, FieldType } from 'app/entities/types'

export function isFieldGroup(x: any): x is Field {
  return (x as FieldGroup).fields !== undefined;
}

function fieldTypeIdentifier(f: FieldType): string {
  return f.keyword
}

export function fieldIsOfType(f: Field, t: FieldTypeValue): boolean {
  if (f.fieldType == null) return false
  return fieldTypeIdentifier(f.fieldType) === t
}

export function fieldTypeIsOfType(f: FieldType, t: FieldTypeValue): boolean {
  return fieldTypeIdentifier(f) === t
}