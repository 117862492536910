import React from 'react'
import { renderRoutes } from 'react-router-config'
import { AuthGuard } from 'components'
import { userCan } from 'app/entities/methods'
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'
import { UserPermissionValue } from 'app/values'

type AdminProps = {
  route: any
}
const Admin = ({ route }: AdminProps) => {
  const session = useSelector((state: RootState) => state.session)

  // const guards = [
  //   {
  //     check: userCan(session.user, UserPermissionValue.AdminFeatures) === true,
  //     onDeny: '/error/403'
  //   }
  // ]

  return (
    // <AuthGuard guards={guards}>
    <>
      {renderRoutes(route.routes)}
    </>
    // </AuthGuard>
  )
}


export default Admin
