import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllFieldTypes = 'api/fieldTypes',
  GetFieldTypeById = 'api/fieldTypes/getById',
  CreateFieldType = 'api/fieldTypes/create',
  UpdateFieldType = 'api/fieldTypes/update',
  DeleteFieldType = 'api/fieldTypes/delete',
}



export type GetAllFieldTypesResponse = {
  fieldTypes: {
    id: number,
    name: string,
    keyword: string
  }[]
}

enum GetAllFieldTypesResponseError {
}

export async function getFieldTypes(output?: RequestOutput<GetAllFieldTypesResponse, GetAllFieldTypesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllFieldTypes,
    output: output,
  })
}


export type GetFieldTypeRequestParams = {
  id: number
}
export type GetFieldTypeResponse = {
  fieldType: {
    id: number,
    name: string,
    keyword: string,
    itemOrder: number
  }
}

enum GetFieldTypeResponseError {
}

export async function getFieldType(params: GetFieldTypeRequestParams, output?: RequestOutput<GetFieldTypeResponse, GetFieldTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetFieldTypeById,
    params: params,
    output: output,
  })
}



export type CreateFieldTypeRequestParams = {
  name: string
  keyword: string
}

type CreateFieldTypeResponse = {
  fieldType: {
    id: number
  }
}

enum CreateFieldTypeResponseError {
}

export async function createFieldType(params: CreateFieldTypeRequestParams, output?: RequestOutput<CreateFieldTypeResponse, CreateFieldTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateFieldType,
    params: params,
    output: output,
  })
}



export type UpdateFieldTypeRequestParams = {
  id: number
  name: string
  keyword: string
}

type UpdateFieldTypeResponse = {
}

enum UpdateFieldTypeResponseError {
}

export async function updateFieldType(params: UpdateFieldTypeRequestParams, output?: RequestOutput<UpdateFieldTypeResponse, UpdateFieldTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateFieldType,
    params: params,
    output: output,
  })
}



export type DeleteFieldTypeRequestParams = {
  id: number
}

type DeleteFieldTypeResponse = {
}

enum DeleteFieldTypeResponseError {
}

export async function deleteFieldType(params: DeleteFieldTypeRequestParams, output?: RequestOutput<DeleteFieldTypeResponse, DeleteFieldTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteFieldType,
    params: params,
    output: output,
  })
}

