import { Card, Table, TableBody, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core'
import { CardHeader, CardContent } from 'components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import React, { useState, useEffect, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import { TableResultsOption } from 'app/values'

export type ListSortingOld = {
  order?: string
  direction?: ListSortingDirectionOld
}

export type ListSortingDirectionOld = 'asc' | 'desc'

//Types used to config list headers, rows and list datasource.
export type ListHeaderPropsOld = {
  name: string
  key: string
  className?: string
  sortable?: boolean
  sortingFunction?: (item: any) => number
}[]

export type ListRowPropsOld = {
  url?: {
    path: string,
    external?: boolean
  }
  data: {
    key: string,
    value: string | ReactNode
    className?: string
  }[]
}

export type ListDataSourceOld = ListRowPropsOld[]

type CardListProps = {
  //Required attributes.
  title: string
  headers: ListHeaderPropsOld
  data: ListDataSourceOld | null

  //Defines is the card should show a loading layout.
  isLoading?: boolean

  //Defines if the list changes should be handled by API requests instead of proceddes client-side when sorting, paginating and filtering.
  shouldFetch?: boolean

  //Optional sorting parameters.
  sorting?: ListSortingOld
  handleChangeSortingCallback?: (sorting: ListSortingOld) => void

  //Optional parameters used to handle pagination. Should be used when the list is handled by API requests.
  count?: number
  page?: number
  handleChangePageCallback?: (page: number) => void
  rowsPerPage?: number
  handleChangeRowsPerPageCallback?: (rows: number) => void

  //Custom filters added to the list.
  filters?: ReactNode
}

const CardList = ({ isLoading, shouldFetch, title, headers, data, filters, count, page, handleChangePageCallback, rowsPerPage, handleChangeRowsPerPageCallback, sorting, handleChangeSortingCallback }: CardListProps) => {

  const [dataValue, setDataValue] = useState(data)



  //FETCHING

  const defaultShouldFetchValue = false
  const shouldFetchValue = shouldFetch ?? defaultShouldFetchValue



  //PAGINATION

  const defaultPageValue = 0
  const [pageValue, setPageValue] = useState(page ?? defaultPageValue)

  function handleChangePage(page: number) {
    setPageValue(page)
    if (handleChangePageCallback != null) handleChangePageCallback(page)
  }

  const defaultRowsPerPageValue = TableResultsOption.m
  const [rowsPerPageValue, setRowsPerPageValue] = useState(rowsPerPage ?? defaultRowsPerPageValue)

  function handleChangeRowsPerPage(rows: number) {
    setRowsPerPageValue(rows)
    if (handleChangeRowsPerPageCallback != null) handleChangeRowsPerPageCallback(rows)
  }



  //COUNT

  const [countValue, setCountValue] = useState(count ?? 0)

  useEffect(() => {
    //Used only if the list is handled by API requests, since in the opposite case the count value is automatically calculated based on the entire data length.
    if (shouldFetchValue === false) return
    if (count == null) return
    setCountValue(count)
  }, [count])



  //SORTING

  //This means no sorting is handled: the list will be displayed as is.
  const defaultSortingValue = {}
  const [sortingValue, setSortingValue] = useState<ListSortingOld>(sorting ?? defaultSortingValue)

  function handleChangeSorting(key: string) {
    let direction: ListSortingDirectionOld = 'asc'
    if (key === sortingValue.order) direction = sortingValue.direction === 'asc' ? 'desc' : 'asc'
    const newSorting = { order: key, direction: direction }
    setSortingValue(newSorting)
    if (handleChangeSortingCallback != null) handleChangeSortingCallback(newSorting)
  }



  //DATA

  useEffect(() => {
    setDataValue(data)

    //Calculates the data length, only if the list is not handled by API requests.
    if (shouldFetchValue === false) setCountValue(data?.length ?? 0)
  }, [data])

  function processData(): ListDataSourceOld {
    if (dataValue == null || dataValue.length === 0) return []
    if (pageValue == null) return []
    if (rowsPerPageValue == null) return []

    //If the list is  handled by API requests just return the data provided.
    if (shouldFetchValue === true) return dataValue

    //Sorts the list based on sorting options, only if the list is not handled by API requests, since in that case the order is automatically handled server-side ans should not be processed client-side.
    let newData: ListDataSourceOld = dataValue
    newData = sortBy(dataValue, function (o) {
      const elem = o.data.filter(f => f.key === sortingValue.order)
      if (elem.length >= 1) return elem[0].value
      return undefined
    })
    if (sortingValue.direction === 'desc') newData = reverse(newData)

    //Filters the list based on pagination options.
    const startIndex = 0 + (pageValue * rowsPerPageValue)
    const endIndex = startIndex + rowsPerPageValue

    return newData.slice(startIndex, endIndex)
  }



  //RENDERING

  return (
    <Card>
      <CardHeader isLoading={isLoading} title={title} action={filters} />
      <CardContent isLoading={isLoading}>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell key={-1} className={classes.itemIndex}></TableCell> */}
                  {headers.map((item, index) => (
                    <TableCell key={index} className={item.className}>
                      { item.sortable === true ? (
                        <TableSortLabel active={sortingValue?.order === item.key} direction={sortingValue?.direction !== 'asc' ? 'desc' : 'asc'} onClick={e => handleChangeSorting(item.key)}>{item.name}</TableSortLabel>
                      ) : (item.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {processData().map((row, index) => (
                  <TableRow hover key={index}
                    //Internal link.
                    {...((row.url?.path && row.url?.external === false) && {
                      component: RouterLink, to: row.url.path
                    })}
                  //External link.
                  // {...((row.url?.path && row.url?.external === true) && {
                  //   component: Link, to: row.url.path, target: '_blank'
                  // })}
                  >
                    {row.data.map((item, i) => (
                      <TableCell key={index + '_' + i} className={item.className}>
                        {item.value}
                      </TableCell>
                    ))
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {/* <CardPaginatedFooter count={countValue}
        page={pageValue} handleChangePageCallback={handleChangePage}
        rowsPerPage={rowsPerPageValue} handleChangeRowsPerPageCallback={handleChangeRowsPerPage}
      /> */}
    </Card >
  )
}

export default CardList
