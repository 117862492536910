import React, { Suspense, useState } from 'react'
import { makeStyles, LinearProgress } from '@material-ui/core'
import { renderRoutes } from 'react-router-config'
import { Setup, NavBar } from './components'
import { AppStatusBar } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  wrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  appStatusBar: {
    flex: '0 0 auto',
    zIndex: 99999,
  },
  mainContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'hidden',
    zIndex: 1,
  },
  topBar: {
    position: 'relative',
  },
  navBar: {
    zIndex: 2,
    width: 280,
    minWidth: 280,
    overflow: 'hidden',
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default
  },
  loader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
  }
}))

type MainProps = {
  route: any
}
const Main = ({ route }: MainProps) => {
  const classes = useStyles()

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true)
  }

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false)
  }

  return (
    <Setup>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <NavBar className={classes.navBar} onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
          <div className={classes.mainContainer}>
            {/* <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} /> */}
            <main className={classes.content}>
              <Suspense fallback={<LinearProgress className={classes.loader} />}>
                {renderRoutes(route.routes)}
              </Suspense>
            </main>
          </div>
        </div>
        <AppStatusBar className={classes.appStatusBar} />
      </div>
    </Setup>
  )
}


export default Main
