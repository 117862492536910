import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { Language } from 'app/entities/types';
import { Page, PageProps, LanguageSelector } from 'components';
import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux'
import { RootState } from 'app/session/store'
import _ from "lodash"
import { updateSelectedLanguage } from 'app/session/actions'
import { Redirect } from "react-router-dom";
import { useRouter } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  topBar: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
}))


export type PageEntityDetailsRefreshableProps = {
  refreshObject?: () => void
}

export type PageTabProps = {
  key: string
  label: string
}

interface PageEntityDetailsProps extends PageProps {
  tabs?: PageTabProps[]
  selectedTab?: string
  defaultTab?: string
  updateTab?: (tab: PageTabProps) => void
  language?: Language
  updateLanguage?: (language: Language) => void
}

const PageEntityDetails = ({ ...props }: PageEntityDetailsProps) => {
  const classes = useStyles()
  const session = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()
  const store = useStore()
  const router = useRouter()



  //TABS

  function handleTabChange(event: any, value: string) {
    if (props.tabs == null) return

    //Check if the tab is a valid one.
    const tab = props.tabs.find(t => t.key === value)
    if (tab == null) return

    if (props.updateTab != null) props.updateTab(tab)

    //Updates the route.
    router.history.push(tab.key)
  }



  //LANGUAGE

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(props.language ?? session.selectedLanguage)

  function updateLanguage(language: Language) {
    if (language.id === selectedLanguage.id) return
    setSelectedLanguage(language)
  }

  useEffect(() => {
    //Updates the selected language in session.
    dispatch(updateSelectedLanguage(selectedLanguage))
  }, [selectedLanguage])

  useEffect(() => store.subscribe(() => {
    //When the session is updates triggers the callback to notify the "parent" component.
    const lang = _.clone(session.selectedLanguage)
    if (props.updateLanguage != null) props.updateLanguage(lang)
  }), [])



  //RENDER

  //If a default tab is provided and a selected tab is not, automatically redirect to the specific tab.
  if (props.currentRoute != null && props.defaultTab != null && props.selectedTab == null) {
    return <Redirect to={props.currentRoute + '/' + props.defaultTab} />
  }

  //If the selected tab is not a valid one redirect to an error page. 
  if (props.tabs != null && props.tabs?.find(t => t.key === props.selectedTab) == null) {
    return <Redirect to="/errors/404" />
  }


  return (
    <Page {...props}>
      <div className={classes.topBar}>

        {props.tabs != null &&
          <Tabs value={props.selectedTab} onChange={handleTabChange}>
            {props.tabs.map((tab, i) => (
              tab.key !== '' && <Tab key={i} label={tab.label} value={tab.key} />
            ))}
          </Tabs>}

        {props.language != null &&
          <LanguageSelector languages={session.app.languages} selectedLanguage={selectedLanguage} updateLanguageCallback={updateLanguage}></LanguageSelector>
        }

      </div>
      {props.children}
    </Page>
  )
}

export default PageEntityDetails