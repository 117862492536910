import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  getSections, GetSectionsRequestParams, GetSectionsResponse,
  getAllGlobalSections, GetAllGlobalSectionsRequestParams, GetAllGlobalSectionsResponse,
} from 'app/api';
import sortBy from 'lodash/sortBy'
import { Language, Page, Section } from 'app/entities/types';
import { toast } from 'app/utils';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'components';
import { FieldValueDialog, SectionBlock } from './components';
import { EditableItem } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  loading: {
    height: '200px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    marginTop: '10px',
    marginBottom: '20px',
    textAlign: 'center'

  },
  titleSecondary: {
    borderTop: '2px solid #dddddd',
    marginTop: '100px',
    paddingTop: '50px',
    marginBottom: '20px',
    textAlign: 'center'
  }
}))

type PageContentProps = {
  object: Page
  language: Language,
  pageId: number
}

const PageContent = ({ object, pageId, language }: PageContentProps) => {
  const classes = useStyles()

  const [isFetching, setIsFetching] = useState(false)

  const [sections, setSections] = useState<Section[]>([])
  const [globalSections, setGlobalSections] = useState<Section[]>([])

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [selectedSection, setSelectedSection] = useState<Section | null>(null)
  const [selectedItem, setSelectedItem] = useState<EditableItem | null>(null)

  const [sectionsStatus] = useState<{ id: number, isOpen: boolean, trigger: (() => void) | null }[]>([])

  function updateSectionsStatus(section: Section, isOpen: boolean, trigger: () => void) {
    const element = find(sectionsStatus, ['id', section.id])
    if (element == null) return
    element.trigger = trigger
    element.isOpen = isOpen
  }

  useEffect(() => {
    const fetchSections = () => {
      const encode = (): GetSectionsRequestParams => {
        return {
          id: pageId,
          withContent: false,
        }
      }

      const decode = (data: GetSectionsResponse): Section[] => {
        return data.sections
      }

      setIsFetching(true)

      getSections(encode(), {
        response(data) {
          const sections = decode(data)
          setSections(sections)
          setIsFetching(false)
        },
        error(error, message) {
          toast.error(message)
          setIsFetching(false)
        }
      })
    }

    function fetchGlobalSections() {
      const encode = (): GetAllGlobalSectionsRequestParams => {
        return {
          withContent: false,
        }
      }

      const decode = (data: GetAllGlobalSectionsResponse): Section[] => {
        return data.sections
      }

      getAllGlobalSections(encode(), {
        response(data) {
          const sections = decode(data)
          setGlobalSections(sections)
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    fetchSections()
    fetchGlobalSections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sections == null) return
    if (globalSections == null) return

    sections.map((section, i) => {
      const ref = {
        id: section.id,
        isOpen: false,
        trigger: null
      }
      sectionsStatus.push(ref)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections])

  useEffect(() => {
    if (globalSections == null) return

    globalSections.map((section, i) => {
      const ref = {
        id: section.id,
        isOpen: false,
        trigger: null
      }
      sectionsStatus.push(ref)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSections])

  function updateSelectedItem(item: EditableItem, section: Section) {
    setSelectedSection(section)
    setSelectedItem(item)
    setDialogIsOpen(true)
  }

  function closeDialog() {
    setSelectedSection(null)
    setSelectedItem(null)
    setDialogIsOpen(false)
  }

  function onDialogSave() {
    if (selectedSection == null) return

    const sectionRef = find(sectionsStatus, ['id', selectedSection.id])
    if (sectionRef == null) return
    if (sectionRef.trigger == null) return
    sectionRef.trigger()
  }

  if (isFetching === true) {
    return (
      <div className={classes.loading} >
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Typography className={classes.title} component="h2" variant="h1">Sezioni pagina</Typography>
      <Grid container spacing={3}>
        {
          sections.map((section, i) => (
            <Grid item xs={12}>
              <SectionBlock key={i} section={section} language={language} selectedItemCallback={updateSelectedItem} updateSectionsStatusCallback={updateSectionsStatus} />
            </Grid>
          ))
        }
      </Grid>

      <Typography className={classes.titleSecondary} component="h2" variant="h1">Sezioni globali</Typography>
      <Grid container spacing={3}>
        {
          globalSections.map((section, i) => (
            <Grid item xs={12}>
              <SectionBlock key={i} section={section} language={language} selectedItemCallback={updateSelectedItem} updateSectionsStatusCallback={updateSectionsStatus} />
            </Grid>
          ))
        }
      </Grid>
      <FieldValueDialog open={dialogIsOpen} item={selectedItem!} onClose={closeDialog} onSaveCallback={onDialogSave} />
    </>
  )
}

export default PageContent