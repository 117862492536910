
//Defines all the Languages available in the app.
//IMPORTANT: Must be mapped to each specific language code to work properly.
export enum LanguageCodeValue {
  English_UK = 'en',
  English_US = 'us',
  Italiano = 'it',
  French = 'fr',
  Deutsch = 'de',
  Espanol = 'es',
}
