import React, { createContext, useContext, useState, useEffect } from 'react'

enum LocalizationQuantityVariantCharacter {
	Empty = '0',
	Singular = '1',
	Plural = '2',
}

export enum LocalizationGenderVariant {
	Male = 'm',
	Female = 'f',
}

enum LocalizationGenderVariantCharacter {
	Male = 'm',
	Female = 'f',
}


type LocalizationProviderProps = {
	defaultLanguage: string
	useNesting: boolean
	children: React.ReactNode
};
export const LocalizationProvider = ({ defaultLanguage, useNesting, children, ...rest }: LocalizationProviderProps) => {
	const [translations, setTranslations] = useState<{ [key: string]: string }>({})
	const [language, setLanguage] = useState(defaultLanguage)

	useEffect(() => {
		const data = require('localization/' + language + '.json')
		setTranslations(data)
	}, [language])

	return (
		<LocalizationStateContext.Provider value={{ language, translations, useNesting, ...rest }}>
			<LocalizationDispatchContext.Provider value={setLanguage}>
				{children}
			</LocalizationDispatchContext.Provider>
		</LocalizationStateContext.Provider>
	)
}


type State = {
	language: string
	translations: { [key: string]: string }
	useNesting: boolean
}

type Dispatch = (language: string) => void
const LocalizationStateContext = createContext<State | undefined>(undefined)
const LocalizationDispatchContext = createContext<Dispatch | undefined>(undefined)

function useLocalizationState() {
	const context = useContext(LocalizationStateContext)
	if (context === undefined) throw new Error('useLocalizationState must be used within a LocalizationProvider')

	return context
}

function useLocalizationDispatch() {
	const context = useContext(LocalizationDispatchContext)
	if (context === undefined) throw new Error('useLocalizationDispatch must be used within a LocalizationProvider')

	return context
}


type TranslationParams = {
	tags?: { [tag: string]: any }
	quantity?: { [tag: string]: number }
	gender?: { [tag: string]: string }
}

export function useLocalization() {
	const { translations, useNesting } = useLocalizationState();
	const setLanguage = useLocalizationDispatch()

	if (!translations) throw new Error('Translations data not found')

	function t(id: string, parameters?: TranslationParams) {
		let output = getValue(id, useNesting)
		const tagWrapper = '%'

		if (!output) {
			console.warn('Translation not found for key "' + id + '"')
			return ''
		}

		//String does not have any parameters to replace.
		if (!parameters) return output

		//Compose and find any parameter of tags available.
		if (parameters.tags) {
			for (let key in parameters.tags) {
				let value = parameters.tags[key]
				const tag = tagWrapper + key + tagWrapper

				//The string has the parameter, replace it with the corresponding value.
				if (output.includes(tag)) {
					output = output.replace(tag, String(value))
				}
				else console.warn('Translation with key "' + id + '": passed wrong tag parameter "' + key + '"')
			}

		}

		//Compose and find any parameter of quantity available.
		if (parameters.quantity) {
			for (let key in parameters.quantity) {
				let value = parameters.quantity[key]
				const tag = tagWrapper + key + tagWrapper

				//The string has the parameter, replace it with the corresponding value.
				if (output.includes(tag)) {

					//Compose and find the parameter's relative string, if needed.
					const tagString = tagWrapper + key + tagWrapper

					let variant = LocalizationQuantityVariantCharacter.Singular
					if (value === 0) variant = LocalizationQuantityVariantCharacter.Empty
					else if (value > 1) variant = LocalizationQuantityVariantCharacter.Plural

					const tagStringKey = id + tagWrapper + key + tagWrapper + variant
					let newValue = getValue(tagStringKey, useNesting)

					if (!newValue) {
						console.warn('Quantity parameter "' + id + '": variant key "' + tagStringKey + '" not found')
						continue
					}

					if (newValue.includes(tagString)) newValue = newValue.replace(tag, String(value))
					output = output.replace(tag, newValue)
				}
				else console.warn('Translation with key "' + id + '": passed wrong quantity parameter "' + key + '"')
			}
		}

		//Compose and find any parameter of gender available.
		if (parameters.gender) {
			for (let key in parameters.gender) {
				let value = parameters.gender[key]
				const tag = tagWrapper + key + tagWrapper

				//The string has the parameter, replace it with the corresponding value.
				if (output.includes(tag)) {

					//Compose and find the parameter's relative string, if needed.
					const tagString = tagWrapper + key + tagWrapper

					let variant = ''
					if (value === LocalizationGenderVariant.Male) variant = LocalizationGenderVariantCharacter.Male
					else if (value === LocalizationGenderVariant.Female) variant = LocalizationGenderVariantCharacter.Female

					const tagStringKey = id + tagWrapper + key + tagWrapper + variant
					let newValue = getValue(tagStringKey, useNesting)

					if (!newValue) {
						console.warn('Gender parameter "' + id + '": variant key "' + tagStringKey + '" not found')
						continue
					}

					if (newValue.includes(tagString)) newValue = newValue.replace(tag, String(value))
					output = output.replace(tag, newValue)
				}
				else console.warn('Translation with key "' + id + '": passed wrong gender parameter "' + key + '"')
			}
		}

		return output
	}

	function getValue(key: string, nested: boolean): string {
		if (nested) {
			const keys = key.split('.')
			var temp: any = translations

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = temp[key]

				if (!value) return ''

				temp = value
				if (value && typeof value === 'string') return value
			}
		}
		else {
			const value = translations[key]
			return value
		}

		return ''
	}

	return { t, setLanguage };
}
