
export default {
  root: {
    padding: '2rem',
    alignItems: 'center',
  },
  action: {
    marginTop: 0
  },
  avatar: {
  }
};
