import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  CreateField = 'api/fields/create',
  UpdateField = 'api/fields/update',
  DeleteField = 'api/fields/delete',
  UpdateTranslationsTranslation = 'api/fields/updateTranslation',
}



export type CreateFieldRequestParams = {
  name: string
  keyword: string
  description: string | null
  itemOrder: number
  fieldTypeId: number | null
  fieldGroupId: number | null
}

type CreateFieldResponse = {
}

enum CreateFieldResponseError {
}

export async function createField(params: CreateFieldRequestParams, output?: RequestOutput<CreateFieldResponse, CreateFieldResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateField,
    params: params,
    output: output,
  })
}



export type UpdateFieldRequestParams = {
  id: number
  name: string
  keyword: string
  description: string | null
  itemOrder: number
}

type UpdateFieldResponse = {
}

enum UpdateFieldResponseError {
}

export async function updateField(params: UpdateFieldRequestParams, output?: RequestOutput<UpdateFieldResponse, UpdateFieldResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateField,
    params: params,
    output: output,
  })
}



export type DeleteFieldRequestParams = {
  id: number
}

type DeleteFieldResponse = {
}

enum DeleteFieldResponseError {
}

export async function deleteField(params: DeleteFieldRequestParams, output?: RequestOutput<DeleteFieldResponse, DeleteFieldResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteField,
    params: params,
    output: output,
  })
}



export type UpdateFieldTranslationsRequestParams = {
  translations: {
    id: number
    value: string
  }[]
}

type UpdateFieldTranslationsResponse = {
}

enum UpdateFieldTranslationsResponseError {
}

export async function updateFieldTranslations(params: UpdateFieldTranslationsRequestParams, output?: RequestOutput<UpdateFieldTranslationsResponse, UpdateFieldTranslationsResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateTranslationsTranslation,
    params: params,
    output: output,
  })
}

