import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, Paper, Button, Avatar, Typography, makeStyles } from '@material-ui/core';
import { Toolbar, Hidden } from '@material-ui/core';
import useRouter from 'app/utils/useRouter';
import { getInitials } from 'app/utils';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import navigationConfig_admin from './navigationConfig_admin';
import navigationConfig_dev from './navigationConfig_dev';
import { User } from 'app/entities/types'
import { Color } from 'theme/style'
import { useSelector, connect, ConnectedProps } from 'react-redux';
import { RootState } from 'app/session/store'
import { userCan } from 'app/entities/methods'
import { UserPermissionValue } from 'app/values'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: Color.Sidebar,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  mainNav: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: theme.spacing(1),
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '100%',
      left: 0,
      height: '60px',
      width: '100%',
      background: 'linear-gradient(rgba(0,44,109,1) 0%, rgba(0,44,109,0) 100%)',
    },
    '& $a': {
      width: '100%',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      '& $img': {
        width: '100%',
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingTop: '30px',
    width: '100%',
    paddingBottom: 'calc(70px + ' + theme.spacing(1) + ')'
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  userBar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: Color.SidebarDark,
    width: '100%',
    height: '70px',
    zIndex: 999,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-60px',
      left: 0,
      height: '60px',
      width: '100%',
      background: 'linear-gradient(rgba(0,44,109,0) 0%, rgba(0,44,109,1) 100%)',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(2)
  },
  avatarDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.common.white,
    '& $h4': {
      color: 'white',
    },
    '& $a': {
      color: 'rgba(255, 255, 255, 0.4)',
      paddingTop: '5px',
      display: 'block'
    }
  }
}));


type NavBarProps = {
  openMobile: boolean
  onMobileClose: any
  className: any
}
const NavBar = ({ openMobile, onMobileClose, className, ...rest }: NavBarProps) => {
  const session = useSelector((state: RootState) => state.session)
  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div className={clsx(classes.root, className)} {...rest}>
            {2}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper className={clsx(classes.root, className)} elevation={1} square {...rest}>
          <div className={classes.mainNav}>
            <Toolbar className={classes.heading}>
              <RouterLink to="/dashboard">
                <img alt="Logo" src={require('assets/images/common/logo.svg')} />
              </RouterLink>
            </Toolbar>
            <div className={classes.content}>
              <nav className={classes.navigation}>
                {navigationConfig.map(item => (
                  <Navigation key={item.title} component="div" pages={item.pages} title={item.title} />
                ))}
                {
                  userCan(session.user, UserPermissionValue.AdminFeatures) === true && (
                    navigationConfig_admin.map(item => (
                      <Navigation key={item.title} component="div" pages={item.pages} title={item.title} />
                    ))
                  )}
                {
                  userCan(session.user, UserPermissionValue.DeveloperFeatures) === true && (
                    navigationConfig_dev.map(item => (
                      <Navigation key={item.title} component="div" pages={item.pages} title={item.title} />
                    ))
                  )}
              </nav>
            </div>
          </div>
          <Toolbar className={classes.userBar}>
            <div className={classes.avatarContainer}>
              <Avatar className={classes.avatar} src={session.user.avatar}>
                {getInitials(session.user.firstname + ' ' + session.user.lastname)}
              </Avatar>
              <div className={classes.avatarDetails}>
                <Typography variant="h4">{session.user.firstname} {session.user.lastname}</Typography>
                <Typography variant="body2"><RouterLink to="/auth/logout">Logout</RouterLink></Typography>
              </div>
            </div>
          </Toolbar>
        </Paper>
      </Hidden>
    </>
  );
};

export default NavBar
