import React, { ReactNode } from 'react'

type PageContentProps = {
  className?: string
  children?: ReactNode,
}
const PageContent = ({ className, children }: PageContentProps) => {
  return (
    <div className={className} >
      {children}
    </div>
  )
}

export default PageContent
