import React, { ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { InputBaseProps, MenuItem, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip, Spinner, TextField, TextFieldAdornment, BooleanField, SelectField, RichTextField, RichTextFieldToolbarType, ButtonField, ImageField } from 'components'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight } from 'theme/style'
import spacing from 'theme/config/spacing';
import _ from 'lodash';
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
  },

  infoContainer: {
    position: 'relative',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  infoContainerVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& >p': {
      marginBottom: theme.spacing(1),
    }
  },
  infoContainerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.45rem',
  },
  descriptionContainer: {
  },
  description: {
    marginTop: spacing(1),
    color: Color.TextSecondary,
    fontSize: '1.25rem',
  },
  actionContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '45px',
    width: '100%',
    flex: 1,
    '& >div': {
      width: '100%',
      justifyContent: 'flex-start',
    }
  },
  errorIcon: {
    color: Color.Warning,
    width: '2.2rem',
    height: '2.2rem',
    position: 'absolute',
    right: '12px',
    bottom: '12px',
    zIndex: 999
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
  }
}))

export type FieldError = {
  error: boolean
  errorMessage: string
}

export type FieldsListErrors = { [key: string]: FieldError[] }


interface CardFieldProps extends InputBaseProps {
  className?: string
  isLoading?: boolean
  layout?: 'vertical' | 'horizontal'
  type?: 'text' | 'password' | 'email' | 'number' | 'select' | 'boolean' | 'richtext' | 'button' | 'image'
  autosave?: boolean
  name: string
  label: string | ReactNode
  placeholder?: string
  value?: any
  description?: string
  errors?: FieldsListErrors
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void

  suffix?: string

  options?: { key: string, value: number | string, name: string }[]

  richTextToolbarType?: RichTextFieldToolbarType

  onClick?: () => void
  onRemove?: () => void
}

const CardField = ({ ...props }: CardFieldProps) => {
  const classes = useStyles()

  let layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)
  if (props.layout === 'horizontal') layoutClass = clsx(classes.infoContainer, classes.infoContainerHorizontal)
  if (props.layout === 'vertical') layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)


  //AUTOSAVE

  const autosaveDefaultValue = true
  const autosaveValue = props.autosave ?? autosaveDefaultValue

  //Automatically triggers the search confirm after a delay while typing.
  var timer: NodeJS.Timeout
  const data: any = useRef(null)
  data.current = props.onConfirm

  useEffect(() => {
    if (autosaveValue === false) return
    if (props.onConfirm == null) return
    if (props.name == null) return

    timer = setTimeout(() => {
      if (timer) clearTimeout(timer)
      data.current(props.name, props.value)
    }, config.automation.autosave.delay)
    return () => clearTimeout(timer)

  }, [props.value])



  //FIELD UPDATES

  function updateField(name: string, value: any) {
    if (props.onUpdate == null) return
    props.onUpdate(name, value)
  }

  function confirmField(name: string, value: any) {
    if (props.onConfirm == null) return
    props.onConfirm(name, value)
  }



  //RENDER

  function renderField(): ReactNode {
    if (props.isLoading != null && props.isLoading === true) return <div className={classes.loading}><Spinner type={'small'} /></div>
    return render()

    function render(): ReactNode {
      if (props.type == null) return <></>

      //Defines all common props passed to the different field types.
      let allProps: any = {
        name: props.name,
        placeholder: props.placeholder,
        value: props.value,
        onUpdate: updateField,
        onConfirm: confirmField,
      }

      if (props.suffix != null) {
        const adornment = <TextFieldAdornment text={props.suffix} />
        allProps['endAdornment'] = adornment
      }

      //Renders the "Text" type.
      if (props.type === 'text') return <TextField {...allProps} />

      //Renders the "Password" type.
      if (props.type === 'password') return <TextField {...allProps} type={'password'} />

      //Renders the "Number" type.
      if (props.type === 'number') return <TextField {...allProps} type={'number'} />

      //Renders the "Boolean" type.
      if (props.type === 'boolean') return <BooleanField {...allProps} />

      //Renders the "Rich Text" type.
      if (props.type === 'richtext') return <RichTextField {...allProps} toolbarType={props.richTextToolbarType} />

      //Renders the "Button" type.
      if (props.type === 'button') return <ButtonField {...allProps} toolbarType={props.richTextToolbarType} onClick={props.onClick} onRemove={props.onRemove} />

      //Renders the "Image" type.
      if (props.type === 'image') return <ImageField {...allProps} onClick={props.onClick} onRemove={props.onRemove} />

      //Renders the "Select" type.
      if (props.type === 'select') return <SelectField {...allProps}>
        {props.options?.map((o) => (
          <MenuItem key={o.key} value={o.value}>{o.name}</MenuItem>
        ))}
      </SelectField>
    }
  }

  function renderError(): ReactNode {
    if (props.errors == null) return <></>

    const errorList = props.errors[props.name]

    const hasErrors = _.find(errorList, 'error')
    if (hasErrors == null) return <></>

    let errorNodes: ReactNode[] = []
    errorList.forEach(e => {
      if (e.error === true) errorNodes.push(<div>• {e.errorMessage}</div>)
    })

    return <Tooltip title={<>{errorNodes.map(e => e)}</>} placement="top-end"><WarningIcon className={classes.errorIcon} /></Tooltip>
  }

  return (
    <div className={clsx(props.className, classes.container)}>
      <div className={layoutClass}>
        {renderError()}
        <Typography className={classes.label} variant="body1">{props.label}</Typography>
        <div className={clsx(classes.actionContainer)}>
          {renderField()}
        </div>
      </div>
      <div className={clsx(classes.descriptionContainer)}>
        {props.description != null && (<Typography className={classes.description} variant="body2">{props.description}</Typography>)}
      </div>
    </div>
  )
}

export default CardField
