import React, { useState, useEffect } from 'react'
import { makeStyles, Button, ButtonProps } from '@material-ui/core'
import { FieldsListErrors } from 'components/_CardPartials/CardField'
import { isValid } from 'components/_Buttons/BaseButton/methods'

const useStyles = makeStyles((theme) => ({
}))

export interface BaseButtonProps extends ButtonProps {
  mandatoryFields?: string[]
  fieldsConditions?: FieldsListErrors
}


const BaseButton = ({ ...props }: BaseButtonProps) => {
  const classes = useStyles()

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (props.fieldsConditions == null) return
    if (props.mandatoryFields == null) return
    const valid = isValid(props.fieldsConditions, props.mandatoryFields)
    setDisabled(!valid)
  }, [props.fieldsConditions, props.mandatoryFields])


  return (
    <>
      <Button disabled={disabled} {...props}>
        {props.children}
      </Button>
    </>
  )
}

export default BaseButton
