import React, { Suspense, useState } from 'react'
import { makeStyles, LinearProgress } from '@material-ui/core'
import { renderRoutes } from 'react-router-config'
import { AuthGuard } from 'components'
import { userRoleIsDev } from 'app/entities/methods'
import { useSelector } from 'react-redux'
import { RootState } from 'app/session/store'


type WebsiteProps = {
  route: any
}
const Website = ({ route }: WebsiteProps) => {
  const guards = [
    {
      check: true,
    }
  ]

  return (
    <AuthGuard guards={guards}>
      {renderRoutes(route.routes)}
    </AuthGuard>
  )
}


export default Website
