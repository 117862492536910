import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip, Spinner } from 'components'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight } from 'theme/style'
import spacing from 'theme/config/spacing';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
  },

  infoContainer: {
    position: 'relative',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  infoContainerVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& >p': {
      marginBottom: theme.spacing(1),
    }
  },
  infoContainerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: FontWeight.Medium,
    fontSize: '1.4rem',
  },
  descriptionContainer: {
  },
  description: {
    marginTop: spacing(1),
    color: Color.TextSecondary
  },
  actionContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: '45px',
    width: '100%',
    flex: 1,
    '& >div': {
      width: '100%',
      justifyContent: 'flex-start',
    }
  },
  errorIcon: {
    color: Color.Warning,
    width: '2.2rem',
    height: '2.2rem',
    position: 'absolute',
    right: '12px',
    bottom: '12px',
    zIndex: 999
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
  }
}))

type CardContentValueProps = {
  className?: string
  isLoading?: boolean
  layout?: 'vertical' | 'horizontal'
  label: string | ReactNode
  description?: string
  action?: ReactNode
  error?: { status: boolean, message: string }
  rest?: any
}
const CardContentValue = ({ ...props }: CardContentValueProps) => {
  const classes = useStyles()

  let layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)
  if (props.layout === 'horizontal') layoutClass = clsx(classes.infoContainer, classes.infoContainerHorizontal)
  if (props.layout === 'vertical') layoutClass = clsx(classes.infoContainer, classes.infoContainerVertical)

  function renderActions(): ReactNode {
    if (props.isLoading == null) return props.action
    if (props.isLoading === true) return <div className={classes.loading}><Spinner type={'small'} /></div>
    return props.action
  }

  return (
    <div className={clsx(props.className, classes.container)}>
      <div className={layoutClass}>
        {props.error?.status === true ? <Tooltip title={props.error.message ?? ''} placement="top-end"><WarningIcon className={classes.errorIcon} /></Tooltip> : <></>}
        <Typography className={classes.label} variant="body1">{props.label}</Typography>
        {props.action &&
          <div className={clsx(classes.actionContainer)}>
            {renderActions()}
          </div>
        }
      </div>
      <div className={clsx(classes.descriptionContainer)}>
        {props.description != null && (<Typography className={classes.description} variant="body2">{props.description}</Typography>)}
      </div>
    </div>
  )
}

export default CardContentValue
