import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetPageMeta = 'api/pages/getMetas',
  UpdatePageMeta = 'api/pages/updateMetas',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetPageMetaRequestParams = {
  pageId: number
  languageId: number
}

export type GetPageMetaResponse = {
  pageTranslation: {
    id: number,
    name: string | null,
    slug: string | null,
    meta: {
      desktop: {
        metaTitleDesktop: string | null,
        metaDescriptionDesktop: string | null,
      },
      mobile: {
        metaTitleMobile: string | null,
        metaDescriptionMobile: string | null,
      }
    },
    languageId: number
  }
}

enum GetPageMetaResponseError {
}

export async function getPageMeta(params: GetPageMetaRequestParams, output?: RequestOutput<GetPageMetaResponse, GetPageMetaResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetPageMeta,
    params: params,
    output: output,
  })
}



export type UpdatePageMetaRequestParams = {
  id: number,
  metaTitleDesktop: string | null
  metaDescriptionDesktop: string | null,
  metaTitleMobile: string | null,
  metaDescriptionMobile: string | null,
}

type UpdatePageMetaResponse = {
}

enum UpdatePageMetaReponseError {
}

export async function updatePageMeta(params: UpdatePageMetaRequestParams, output?: RequestOutput<UpdatePageMetaResponse, UpdatePageMetaReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdatePageMeta,
    params: params,
    output: output,
  })
}