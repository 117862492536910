import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllFieldGroupTypes = 'api/fieldGroupTypes',
  GetFieldGroupTypeById = 'api/fieldGroupTypes/getById',
  CreateFieldGroupType = 'api/fieldGroupTypes/create',
  UpdateFieldGroupType = 'api/fieldGroupTypes/update',
  DeleteFieldGroupType = 'api/fieldGroupTypes/delete',
  AddFieldType = 'api/fieldGroupTypes/addFieldType',
  UpdateFieldType = 'api/fieldGroupTypes/updateFieldType',
  RemoveFieldType = 'api/fieldGroupTypes/removeFieldType',
}



export type GetAllFieldGroupTypesResponse = {
  fieldGroupTypes: {
    id: number,
    name: string,
    keyword: string
  }[]
}

enum GetAllFieldGroupTypesResponseError {
}

export async function getFieldGroupTypes(output?: RequestOutput<GetAllFieldGroupTypesResponse, GetAllFieldGroupTypesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllFieldGroupTypes,
    output: output,
  })
}


export type GetFieldGroupTypeRequestParams = {
  id: number
}
export type GetFieldGroupTypeResponse = {
  fieldGroupType: {
    id: number,
    name: string,
    keyword: string
    fieldTypes: {
      id: number
      name: string
      keyword: string
      description: string
      itemOrder: number
      fieldTypeId: number
    }[]
  }
}

enum GetFieldGroupTypeResponseError {
}

export async function getFieldGroupType(params: GetFieldGroupTypeRequestParams, output?: RequestOutput<GetFieldGroupTypeResponse, GetFieldGroupTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetFieldGroupTypeById,
    params: params,
    output: output,
  })
}



export type CreateFieldGroupTypeRequestParams = {
  name: string
  keyword: string
}

type CreateFieldGroupTypeResponse = {
  fieldGroupType: {
    id: number
  }
}

enum CreateFieldGroupTypeResponseError {
}

export async function createFieldGroupType(params: CreateFieldGroupTypeRequestParams, output?: RequestOutput<CreateFieldGroupTypeResponse, CreateFieldGroupTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateFieldGroupType,
    params: params,
    output: output,
  })
}



export type UpdateFieldGroupTypeRequestParams = {
  id: number
  name: string
  keyword: string
}

type UpdateFieldGroupTypeResponse = {
}

enum UpdateFieldGroupTypeResponseError {
}

export async function updateFieldGroupType(params: UpdateFieldGroupTypeRequestParams, output?: RequestOutput<UpdateFieldGroupTypeResponse, UpdateFieldGroupTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateFieldGroupType,
    params: params,
    output: output,
  })
}



export type DeleteFieldGroupTypeRequestParams = {
  id: number
}

type DeleteFieldGroupTypeResponse = {
}

enum DeleteFieldGroupTypeResponseError {
}

export async function deleteFieldGroupType(params: DeleteFieldGroupTypeRequestParams, output?: RequestOutput<DeleteFieldGroupTypeResponse, DeleteFieldGroupTypeResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteFieldGroupType,
    params: params,
    output: output,
  })
}




export type AddFieldTypeToFieldGroupTypeRequestParams = {
  name: string
  keyword: string
  description: string
  itemOrder: number
  fieldTypeId: number
  fieldGroupTypeId: number
}

type AddFieldTypeToFieldGroupTypeResponse = {
  tag: {
    id: number
    name: string
  }
}

enum AddFieldTypeToFieldGroupTypeReponseError {
}

export async function addFieldTypeToFieldGroupType(params: AddFieldTypeToFieldGroupTypeRequestParams, output?: RequestOutput<AddFieldTypeToFieldGroupTypeResponse, AddFieldTypeToFieldGroupTypeReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddFieldType,
    params: params,
    output: output,
  })
}




export type UpdateFieldTypeToFieldGroupTypeRequestParams = {
  name: string
  keyword: string
  description: string
  itemOrder: number
  fieldGroupTypeFieldTypeId: number,
}

type UpdateFieldTypeToFieldGroupTypeResponse = {
  fieldType: {
    id: number
    name: string
    keyword: string
    itemOrder: number
  }
}

enum UpdateFieldTypeToFieldGroupTypeReponseError {
}

export async function updateFieldTypeToFieldGroupType(params: UpdateFieldTypeToFieldGroupTypeRequestParams, output?: RequestOutput<UpdateFieldTypeToFieldGroupTypeResponse, UpdateFieldTypeToFieldGroupTypeReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateFieldType,
    params: params,
    output: output,
  })
}




//Retrieve the authentication token.
//Used when logging in and authorizing the client to make future API calls.

export type RemoveFieldTypeFromFieldGroupTypeRequestParams = {
  keyword: string
  fieldGroupTypeId: number
}

type RemoveFieldTypeFromFieldGroupTypeResponse = {
}

enum RemoveFieldTypeFromFieldGroupTypeReponseError {
}

export async function removeFieldTypeFromFieldGroupType(params: RemoveFieldTypeFromFieldGroupTypeRequestParams, output?: RequestOutput<RemoveFieldTypeFromFieldGroupTypeResponse, RemoveFieldTypeFromFieldGroupTypeReponseError>) {
  executeRequest({
    endpoint: APIEndpoint.RemoveFieldType,
    params: params,
    output: output,
  })
}