import typography from 'theme/config/typography';
import spacing from 'theme/config/spacing';

export default {
  root: {
    ...typography.body1,
    borderBottom: 0,
    padding: spacing(1),
  },
  head: {
    fontWeight: 700
  },
  body: {
    ...typography.cell,
  }
};
