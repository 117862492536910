import React, { ReactNode } from 'react'
import { TableRow, TableCell, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip } from 'components'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, FontSize } from 'theme/style'
import spacing from 'theme/config/spacing';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  infoContainer: {
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontWeight: FontWeight.Medium
  },
  description: {
    marginTop: spacing(0.3)
  },
  actionContainer: {
    verticalAlign: 'top'
  },
  errorIcon: {
    color: Color.Warning,
    width: '1.8rem',
    height: '1.8rem',
    position: 'absolute',
    left: '-16px'
  },
}))

type CardKeyValueRowProps = {
  className?: string
  label: string | ReactNode
  description?: string
  action?: ReactNode
  error?: { status: boolean, message: string }
  rest?: any
}
const CardKeyValueRow = ({ className, label, description, action, error, ...rest }: CardKeyValueRowProps) => {
  const classes = useStyles()

  return (
    <>
      <TableRow className={classes.container}>
        <TableCell className={classes.infoContainer}>
          {error?.status === true ? <Tooltip title={error.message ?? ''} placement="top-start"><WarningIcon className={classes.errorIcon} /></Tooltip> : <></>}
          <div className={classes.labelContainer}>
            <Typography className={classes.label} variant="body1">{label}</Typography>
            {description != null && (<Typography className={classes.description} variant="body2">{description}</Typography>)}
          </div>
        </TableCell>
        <TableCell className={classes.actionContainer}>{action}</TableCell>
      </TableRow>
    </>
  )
}

export default CardKeyValueRow
