import { FieldGroupTypeValue } from 'app/values'
import { FieldGroup, FieldGroupType } from 'app/entities/types'

function fieldGroupTypeIdentifier(f: FieldGroupType): string {
  return f.keyword
}

export function fieldGroupIsEditable(f: FieldGroup): boolean {
  if (f.fieldGroupType === null) return false
  if (fieldGroupIsOfType(f, FieldGroupTypeValue.Repeater)) return false
  if (fieldGroupIsOfType(f, FieldGroupTypeValue.RepeaterTemplate)) return false
  return true
}

export function fieldGroupCanCreateChildFields(f: FieldGroup): boolean {
  if (fieldGroupIsOfType(f, FieldGroupTypeValue.RepeaterTemplate)) return true
  if (f.fieldGroupType !== null) return false
  return true
}

export function fieldGroupCanCreateChildFieldGroups(f: FieldGroup): boolean {
  if (fieldGroupIsOfType(f, FieldGroupTypeValue.RepeaterTemplate)) return true
  if (f.fieldGroupType !== null) return false
  return true
}

export function fieldGroupCanCollapse(f: FieldGroup): boolean {
  if (f.fields.length !== 0) return true
  if (f.childs.length === 0) return false
  return true
}

export function fieldGroupIsOfType(f: FieldGroup, t: FieldGroupTypeValue): boolean {
  if (f.fieldGroupType == null) return false
  return fieldGroupTypeIdentifier(f.fieldGroupType) === t
}

export function fieldGroupTypeIsOfType(f: FieldGroupType, t: FieldGroupTypeValue): boolean {
  return fieldGroupTypeIdentifier(f) === t
}