import React from 'react'
import { Helmet } from 'react-helmet-async'
import { config } from 'app/config'

type ParametersProps = {
  description?: string,
  keywords?: string,
}
type PageMetatagsProps = {
  title: string,
  parameters?: ParametersProps,
}
const PageMetatags = ({ title, parameters }: PageMetatagsProps) => {
  return (
    <Helmet>
      <title>{config.app.name} | {title}</title>
      {parameters &&
        <>
          <meta name="description" content={parameters.description} />
          <meta name="keywords" content={parameters.keywords} />
        </>
      }
    </Helmet>
  )
}

export default PageMetatags
