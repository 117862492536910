import { Value } from 'theme/style'

export default {
  root: {
    borderRadius: Value.BorderRadius_Button,
  },
  contained: {
    padding: '10px 15px',
    textTransform: 'uppercase',
    '&:hover': {
    },
  }
};
