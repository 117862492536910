import _ from 'lodash'
import { FieldsListErrors } from 'components/_CardPartials/CardField'
import { fieldHasError, fieldsHaveError } from 'components/_CardPartials/CardField/methods'

type CardContentValidationData<T> = {
  isUpdatingData?: boolean
  fieldName?: string
  fieldParameters?: FieldsListErrors
  data: T
  currentData: T
}

type CardContentPrepareSaveData<T> = {
  fieldName?: string
  data: T
  currentData: T
  updateDataCallback: (data: T) => void
}

export function prepareForSave(data: CardContentPrepareSaveData<any>) {
  //Deep copy the new data and calls the function to assign it to the current data.
  const temp = _.clone(data.data) as any
  data.updateDataCallback(temp)
}

export function shouldSave(data: CardContentValidationData<any>): boolean {
  //Processing validation.

  //If is updating the current data prevents save.
  if (data.isUpdatingData === true) return false


  //Data validation.

  //Deep compares the current data with the new one and prevents save if they are the same.
  if (_.isEqual(data.data, data.currentData) === true) return false


  //Error checks.

  //If errors should not be checked skip the following checks.
  if (data.fieldParameters == null) return true

  //Check if any field has at least on error.
  if (fieldsHaveError(data.fieldParameters) === true) return false

  //DISABLED: all passed fields should be valid.
  //If there is a field name...
  // if (data.fieldName != null) {
  //   //...check if the specific field has errors.
  //   if (fieldHasError(data.fieldName, data.fieldParameters) === true) return false
  // }
  // //...otherwise...
  // else {
  //...check if any field has at least on error.
  // if (fieldsHaveError(data.fieldParameters) === true) return false
  // }

  return true
}
