import { Input, InputProps, makeStyles } from '@material-ui/core';
import { isValidUrl } from 'app/utils/validation';
import clsx from 'clsx';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  useEffect(() => {
    updateValueCallback(value, hasError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = (v?: string) => {
    if (v == null) v = value
    return isValidUrl(v) === false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value

    updateValueCallback(value, hasError(value))
  }

  return (
    <Input disableUnderline className={clsx(className, classes.input)}
      value={value}
      type="text"
      placeholder="Inserisci un url..."
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    />
  )
}

export default FieldInput
