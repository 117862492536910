import React, { useEffect } from 'react'
import { Switch, SwitchProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Color, FontWeight, Value } from 'theme/style'
import { config } from 'app/config'

const useStyles = makeStyles((theme) => ({
  input: {
    '& input': {
      textAlign: 'left',
      padding: theme.spacing(0, 1.5),
      height: '45px',
      backgroundColor: Color.Background,
      borderRadius: Value.BorderRadius_Field,
    },
  },
  adornmentText: {
    color: Color.Primary,
    fontWeight: FontWeight.Medium,
    letterSpacing: -0.5,
    fontSize: '1.2rem',
    position: 'absolute',
    right: '15px'
  }
}))

interface BooleanFieldProps extends SwitchProps {
  onUpdate?: (name: string, value: any) => void
  onConfirm?: (name: string, value: any) => void
}
const BooleanField = ({ ...props }: BooleanFieldProps) => {
  const classes = useStyles()



  //FIELD CHANGES

  function updateField(event: any) {
    const target = event.target as HTMLInputElement
    const name = target.name as string
    const checked = target.checked

    if (props.onUpdate == null) return
    props.onUpdate(name, checked)
  }



  //RENDER

  return (
    <Switch {...props} className={classes.input} checked={Boolean(props.value)} onChange={updateField} ></Switch>
  )
}

export default BooleanField