import React from 'react'
import { makeStyles, Button, ButtonProps, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core'
import { Color } from 'theme/style'
import { VerifyButton } from 'components'

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent' + ' !important',
    boxShadow: 'none' + ' !important',
    color: Color.Error + ' !important',
  },
  ctaDelete: {
    backgroundColor: Color.Error + ' !important',
    color: Color.White + ' !important',
    '&:hover': {
      backgroundColor: Color.Error + ' !important',
    }
  },
}))

interface DeleteButtonProps extends ButtonProps {
  onConfirm?: () => void
}


const DeleteButton = ({ onConfirm, ...props }: DeleteButtonProps) => {
  const classes = useStyles()

  const confirmDelete = () => {
    if (onConfirm != null) onConfirm()
  }

  return (
    <>
      <VerifyButton
        className={classes.button}
        title={'Sei sicuro di voler eliminare questo elemento?'}
        description={'Una volta eliminato, questo elemento verrà completamente rimosso dal sistema e non sarà più possibile recuperarlo.'}
        confirmText={'Conferma eliminazione'}
        confirmButtonClass={classes.ctaDelete}
        onConfirm={confirmDelete}>
        {props.children}
      </VerifyButton>
    </>
  )
}

export default DeleteButton
