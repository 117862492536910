import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  CreateFieldGroup = 'api/fieldGroups/create',
  UpdateFieldGroup = 'api/fieldGroups/update',
  DeleteFieldGroup = 'api/fieldGroups/delete',
  AddFieldGroupRepeaterRow = 'api/fieldGroups/repeaters/addRow',
}



export type CreateFieldGroupRequestParams = {
  name: string
  keyword: string
  description: string | null
  parentId: number | null
  itemOrder: number
  fieldGroupTypeId: number | null
  contentId: number | null
}

type CreateFieldGroupResponse = {
}

enum CreateFieldGroupResponseError {
}

export async function createFieldGroup(params: CreateFieldGroupRequestParams, output?: RequestOutput<CreateFieldGroupResponse, CreateFieldGroupResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.CreateFieldGroup,
    params: params,
    output: output,
  })
}



export type UpdateFieldGroupRequestParams = {
  id: number
  name: string
  keyword: string
  description: string | null
  parentId: number | null
  itemOrder: number
}

type UpdateFieldGroupResponse = {
}

enum UpdateFieldGroupResponseError {
}

export async function updateFieldGroup(params: UpdateFieldGroupRequestParams, output?: RequestOutput<UpdateFieldGroupResponse, UpdateFieldGroupResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateFieldGroup,
    params: params,
    output: output,
  })
}



export type DeleteFieldGroupRequestParams = {
  id: number
}

type DeleteFieldGroupResponse = {
}

enum DeleteFieldGroupResponseError {
}

export async function deleteFieldGroup(params: DeleteFieldGroupRequestParams, output?: RequestOutput<DeleteFieldGroupResponse, DeleteFieldGroupResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.DeleteFieldGroup,
    params: params,
    output: output,
  })
}



export type AddFieldGroupRepeaterRowRequestParams = {
  id: number
}

type AddFieldGroupRepeaterRowResponse = {
}

enum AddFieldGroupRepeaterRowResponseError {
}

export async function addFieldGroupRepeaterRow(params: AddFieldGroupRepeaterRowRequestParams, output?: RequestOutput<AddFieldGroupRepeaterRowResponse, AddFieldGroupRepeaterRowResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.AddFieldGroupRepeaterRow,
    params: params,
    output: output,
  })
}

