import { UserRoleValue, UserPermissionValue } from 'app/values'
import { UserPermission } from 'app/entities/types'

export function userPermissionIdentifier(p: UserPermission | null): string {
  if (p == null) {
    console.warn('UserPermission: inesistente')
    return ''
  }

  if (p.keyword == null) {
    console.warn('UserPermission: keyword esistente')
    return ''
  }

  return p.keyword
}
