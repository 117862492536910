import { Switch, InputProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  useEffect(() => {
    updateValueCallback(value, hasError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.checked
    const formattedValue = value === false ? '0' : '1'
    updateValueCallback(formattedValue, hasError(formattedValue))
  }

  return (
    <Switch
      checked={(value === '0' || value === '') ? false : true}
      onChange={e => handleInputChange(e)}
      onBlur={e => onBlur(e)}
    />
  )
}

export default FieldInput
