
export const getBaseName = (path: string): string => {
  return path.replace(/^.*[\\\/]/, '')
}

export const getFileName = (filename: string): string => {
  return filename.substr(0, filename.lastIndexOf('.'))
}

export const convertFileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}