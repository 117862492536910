import spacing from 'theme/config/spacing'
import typography from 'theme/config/typography'
import { Color } from 'theme/style';

export default {
  root: {
    background: 'linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.7) 100%)'
  },
  titleWrap: {
    padding: spacing(0, 2)
  },
  title: {
    ...typography.h5,
    color: Color.White,
  },
  subtitle: {
    ...typography.body2,
    color: Color.White,
  }
};
