import executeRequest, { RequestOutput } from 'app/api'

enum APIEndpoint {
  GetAllCities = 'api/cities',
}


//Retrieves the languages in the CMS.
//Mainly used on application first loading, the response data is saved in the store for further use.

export type GetAllCitiesRequestParams = {
  languageId: number
  provinceId?: number | null
}

export type GetAllCitiesResponse = {
  cities: {
    id: number
    keyword: string
    published: boolean | null
    parentId: number | null
    pageTypeId: number | null
    pageId: number
    translation: {
      name: string
    }[]
  }[]
}

enum GetAllCitiesResponseError {
}

export async function getAllCities(params: GetAllCitiesRequestParams, output?: RequestOutput<GetAllCitiesResponse, GetAllCitiesResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetAllCities,
    params: params,
    output: output,
  })
}
