import { Button, MenuItem, Input, InputProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { toast } from 'app/utils'
import { getAllPages, GetAllPagesRequestParams, GetAllPagesResponse } from 'app/api'
import { MediaLibraryFile } from 'app/entities/types'
import { PageTypeValue } from 'app/values';
import { FilePicker } from 'components'
import { MediaLibraryFolderValue } from 'app/values';

const useStyles = makeStyles((theme) => ({
  input: {
  },
}))


interface FieldInputProps extends InputProps {
  className?: string
  value: string
  updateValueCallback: (value: string, hasError: boolean) => void
  onBlur: (event: any) => void
}

const FieldInput = ({ className, value, updateValueCallback, onBlur }: FieldInputProps) => {
  const classes = useStyles()

  const [imagePickerIsOpen, setImagePickerIsOpen] = useState(false)
  // const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    // fetchOptions()
    updateValueCallback(value, hasError())

    function fetchOptions() {
      const encode = (): GetAllPagesRequestParams => {
        return {
          languageId: 1,
          pageTypeKeyword: PageTypeValue.Default
        }
      }

      const decode = (data: GetAllPagesResponse): string[] => {
        let object: string[] = []
        data.pages.map((page, i) => {
          object.push(page.translation[0].name ?? '')
        })
        return object
      }

      getAllPages(encode(), {
        response(data) {
          const pages = decode(data)
          // setOptions(pages)
        },
        error(error, message) {
          toast.error(message)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = (v?: string) => {
    return false
  }

  function handleInputChange(event: any) {
    const target = event.target as HTMLInputElement
    let value = target.value

    // const newValue = parseInt(value)

    // updateValueCallback(formattedValue, hasError(formattedValue))
  }


  function openImagePicker() {
    setImagePickerIsOpen(true)
  }

  function closeImagePicker() {
    setImagePickerIsOpen(false)
  }

  function imagePickerSelected(selectedItem: MediaLibraryFile | null) {
    if (selectedItem == null) return
    const value = String(selectedItem.id)
    updateValueCallback(value, hasError(value))
  }

  function imagePickerConfirm(selectedItem: MediaLibraryFile | null) {
    onBlur(null)
  }

  return (
    <>
      <Input disableUnderline className={clsx(className, classes.input)}
        disabled
        value={value}
        type="text"
        placeholder="Inserisci un testo..."
      />
      <Button variant="text" color="secondary" onClick={openImagePicker}>Modifica immagine</Button>
      <FilePicker mediaLibraryFolder={MediaLibraryFolderValue.General}
        enableUpload={true} open={imagePickerIsOpen} onSelectCallback={imagePickerSelected}
        onConfirmCallback={imagePickerConfirm} onClose={closeImagePicker} />
    </>
  )
}

export default FieldInput
