import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core'
import { BaseButton, BaseButtonProps } from 'components'
import { Color } from 'theme/style'

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: Color.Primary,
    color: Color.White
  },
}))

interface VerifyButtonProps extends BaseButtonProps {
  title: string
  description: string
  confirmText: string
  content?: ReactNode
  confirmButtonClass?: string
  onConfirm?: () => void
}


const VerifyButton = ({ ...props }: VerifyButtonProps) => {
  const classes = useStyles()
  const [alertOpen, setAlertOpen] = React.useState(false)

  const handleOpen = () => {
    setAlertOpen(true)
  }

  const handleClose = () => {
    setAlertOpen(false)
  }

  const confirmDelete = () => {
    handleClose()
    if (props.onConfirm != null) props.onConfirm()
  }

  return (
    <>
      <BaseButton variant="contained" color={'primary'} onClick={handleOpen} {...props}>
        {props.children}
      </BaseButton>

      <Dialog open={alertOpen} onClose={handleClose}>
        <DialogTitle>
          <Typography component="h3" variant="h3">{props.title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          {props.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" autoFocus>Annulla</Button>
          <Button className={clsx(props.confirmButtonClass, classes.confirmButton)} onClick={confirmDelete}>{props.confirmText}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VerifyButton
