import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'
import { AppStatusBar } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  wrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  appStatusBar: {
    flex: '0 0 auto',
    zIndex: 99999,
  },
}))

const Auth = props => {
  const { route } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.wrapper}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
      <AppStatusBar className={classes.appStatusBar} />
    </div>
  )
}

Auth.propTypes = {
  route: PropTypes.object
}

export default Auth
