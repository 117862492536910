import React, { useState, useEffect } from 'react'
import { CardPageData, PageEntityDetailsRefreshableProps } from 'components'
import { Page, Language } from 'app/entities/types'
import {
	getAllPages, GetAllPagesRequestParams, GetAllPagesResponse,
} from 'app/api'
import { getLanguageFromLanguageCode } from 'app/entities/methods'
import { getPageTypeFromId } from 'app/entities/methods'
import { config } from 'app/config'
import { PageTypeValue } from 'app/values'
import { RootState } from 'app/session/store'
import { useSelector } from 'react-redux'
import { toast } from 'app/utils'

interface PageDataProps extends PageEntityDetailsRefreshableProps {
	object: Page
	language: Language
	pageId: number
	parents?: Page[] | null
}

const PageData = ({ ...props }: PageDataProps) => {

	return (
		<CardPageData object={props.object} pageId={props.pageId} language={props.language} refreshObject={props.refreshObject} parents={props.parents} />
	)
}

export default PageData